import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Container = styled.div`
	display: flex;
	width: 506px;
	height: 540px;
	flex-direction: column;
	justify-content: center;
	border-radius: 30px;
	background-color: ${Colors.neutral_grey_k_5};
	padding: 0px 32px;
	position: relative;
`;

export const ContainerClose = styled.div`
	cursor: pointer;
	position: absolute;
	right: 32px;
	top: 22px;
`;
export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 80%;
	margin-top: 10%;
	padding: 0px ;
	align-items: center;

`;