import React, { ReactElement, useEffect, useState } from 'react';
import { ContainerDash, Icon, ProfilePicture, Return } from '../../../styles/global';
import { DashBoardActions } from '../../../store/ducks/dashboard';

import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import PdfIcon from "../../../assets/kinologyIcons/svg/pdf.svg";
import DotThree from "../../../assets/kinologyIcons/svg/dotthree.svg";
import {ReactComponent as Warning} from "../../../assets/kinologyIcons/svg/WorningRed.svg";
import {ReactComponent as Refresh} from "../../../assets/kinologyIcons/svg/refresh.svg";
import {ReactComponent as ArrowDrop} from "../../../assets/kinologyIcons/svg/arrow_drop.svg";
import {ReactComponent as ArrowDown} from "../../../assets/kinologyIcons/svg/arrow_downward_alt.svg";
// import {ReactComponent as Underline} from "../../../assets/kinologyIcons/svg/arrow_downward_alt.svg";
import {ReactComponent as Dot} from "../../../assets/kinologyIcons/svg/Dot.svg";
import {ReactComponent as PointScaleWPI} from "../../../assets/kinologyIcons/svg/PointEscaleWPI.svg";
import {ReactComponent as Question} from "../../../assets/kinologyIcons/svg/question.svg";
import {ReactComponent as Edit} from "../../../assets/kinologyIcons/svg/edit.svg";

import {ReactComponent as VeryVeryHappy} from '../../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/very_very_happy.svg';
import {ReactComponent as VeryHappy} from '../../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/veryHappy.svg';
import {ReactComponent as Happy} from '../../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/happy.svg';
import {ReactComponent as Apathetic} from '../../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/apathetic.svg';
import {ReactComponent as Sick} from '../../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/sick.svg';
import {ReactComponent as Sad} from '../../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/sad.svg';
import {ReactComponent as VerySad}  from '../../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/verySad.svg';
import {ReactComponent as Underline}  from '../../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/underline.svg';

import {ReactComponent as CorpoFemininoDorsal} from "../../../assets/kinologyIcons/PainMapImages/corpoFemininoDorsal.svg";
import {ReactComponent as CorpoFemininoFrontal} from "../../../assets/kinologyIcons/PainMapImages/corpoFemininoFrontal.svg";
import {ReactComponent as CorpoMasculinoDorsal} from "../../../assets/kinologyIcons/PainMapImages/corpoMasculinoDorsal.svg";
import {ReactComponent as CorpoMasculinoFrontal} from "../../../assets/kinologyIcons/PainMapImages/corpoMasculinoFrontal.svg";

import { Colors } from '../../../styles/colors';
import { useDispatch, useSelector } from 'react-redux';
import { P3Desk, S1Desk } from '../../../styles/styles_text';
import { TextsProps } from '../../../database/txt.interface';
import { PacientesProps, UserProps } from '../../../interfaces/pacientes.interface';
import { RelatorioActions } from '../../../store/ducks/relatorios';
import { Cel, ContainerImage, ContainerImages, ContainerPainlevelSelect, ContainerSpectroColors, ContainerTable, ContentInfoLegend, ContentPhrase, GridLegend, HeaderTable, InfoLegend, OptionCel, OptionHeader, Popup, Referenc, Section, SectionContent, Separator, Table, TexLegend, VerPerfil, WarningIndicativeFibromyalgia, WhiteContainer } from './styles';
import Text from '../../../components/Texts/Text';
import { colors } from 'material-ui/styles';
import moment from 'moment';
import { dataReportProps, EvaCorpoProps } from '../../../store/ducks/Models';
import { HandlerColors } from '../../../components/BodyPainPoints/functions';
import BodyPainPointsComponent from '../../../components/BodyPainPoints';
import { HenderDataPositionDots } from '../../../components/BodyPainPoints/dotsArrayPosition';
import InputPain from '../../../components/BodyPainPoints/ChildComponents/Dot';
import { ActivityIndicator } from '../../../components/Buttons/FormSubmitButton/style';
import { setOptOut } from '@amplitude/analytics-browser';
import { PacAvalActions } from '../../../store/ducks/manage_pac_aval';
import ModalSelectDotPain from '../../../components/Modais/ModalSelectDotPain';

interface ReportPainMapProps extends Partial<dataReportProps>{
	dataReport?: dataReportProps;
	isHistory?: boolean;
	createdAt?: string;
};
type keyFaceLevel =  {
	[key:number]: (colo:string)=> ReactElement
};

const ReportPainMap:React.FC<ReportPainMapProps> = ({
	dataReport,
	isHistory = false,
	createdAt
})=> {

	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector((state: any) => state.configs.current_language);
	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const dataQuestionnaireEVA = useSelector((state:any)=> state.relatorios.questionnaireEVA);
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const [dropdownLegend, setDropdownLegend] = useState(false);
	const [popup, setPopup] = useState(false);
	const [modalModalSelectDotPain, setModalModalSelectDotPain] = useState(false);

	const Now = moment().format('YYYY-MM-DD HH:mm:ss') 
	const createdAtReport = moment(dataReport?.txDataHora) 
	const currentMoment = moment(Now)
	const difDays = currentMoment.diff(createdAtReport, 'day');



	const ScalePain = Array.from({length: 11}).map((_, index)=> index);

	async function GetDataHistory () {

		const data_request = {
			TOKEN: user_logado.token,
			ID_USUARIO: patient?.ID_USUARIO
		}

		try{

			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(data_request),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/sync/sync_historico_eva.php`,
				requestOptions
			);
			const response: any = await res.json();
			if(response){
				dispatch(RelatorioActions.setDataHistoryReportPainMap(response.historico))
			}
		}catch(error){
			console.log('/sync/sync_historico_eva.php', error)
		}

	}
	
	useEffect(()=>{
		GetDataHistory()
	},[])

	const checkArrays = (itemAtual: EvaCorpoProps , anterior:  EvaCorpoProps[]) => {
		const itemAnterior = anterior?.find((a) => a.MUSCULO === itemAtual.MUSCULO && a.LADO === itemAtual.LADO);
		
		let status;
		if (!itemAnterior) {
			status = '-'
			return status
		}
	
		if (itemAtual.VALOR > itemAnterior.VALOR) {
			status = 'Intensificação';
		} else if (itemAtual.VALOR === itemAnterior.VALOR) {
			status = 'Estabilidade';
		} else {
			status = 'Alivio';
		}

		return status
	};

	const OptionsHeaderTable = [
		{
			title: current_language?.local,
			width: 60.5,
		},
		{
			title: current_language?.nivel,
			width: 29,
		},
		{
			title: current_language?.evolucao_dor,
			width: 11,
		},
	];
	
	const SubHeaderTable = [
		{
			title: current_language?.escala_gravidade,
			width: 88,
		},
		{
			title: current_language?.pontuacao,
			width: 12,
		},
	];

	const CategoryData = [
		{id:0, categoria: current_language?.fadiga_cansaco_atividades, gravidade: dataReport?.gravidade?.fadiga},
		{id:1, categoria: current_language?.sono_nao_reparador, gravidade: dataReport?.gravidade?.sono},
		{id:2, categoria: current_language?.sintomas_cognitivos, gravidade: dataReport?.gravidade?.sintomas_cognitivos},
		{id:3, categoria: current_language?.sintomas_somaticos, gravidade: dataReport?.gravidade?.sintomas_somaticos},
	];

	const FaceLevelPainOptions:keyFaceLevel = {
		0:(color:string)=> <VeryVeryHappy color={color} height={25} width={25} />,
		1:(color:string)=> <VeryVeryHappy color={color} height={25} width={25} />,
		2:(color:string)=> <VeryHappy color={color} height={25} width={25} />,
		3:(color:string)=> <VeryHappy color={color} height={25} width={25}  />,
		4:(color:string)=> <Happy color={color} height={25} width={25} />,
		5:(color:string)=> <Apathetic color={color} height={25} width={25} />,
		6:(color:string)=> <Sad color={color} height={25} width={25} />,
		7:(color:string)=> <Sad color={color} height={25} width={25} />,
		8:(color:string)=> <VerySad color={color} height={25} width={25} />,
		9:(color:string)=> <VerySad color={color} height={25} width={25} />,
		10:(color:string)=> <Sick color={color} height={25} width={25} />,
	};

	const Faceslegend = [
		<VeryVeryHappy color={Colors?.tertiary_green_1_dark} height={25} width={25} />,
	  <VeryVeryHappy color={Colors?.tertiary_green_1_dark} height={25} width={25} />,
	  <VeryHappy color={Colors?.tertiary_green_1_dark} height={25} width={25} />,
	  <VeryHappy color={Colors?.tertiary_green_1_dark} height={25} width={25}  />,
	  <Happy color={Colors.tertiary_orange_1_light} height={25} width={25} />,
	  <Apathetic color={Colors.tertiary_orange_1_light} height={25} width={25} />,
	  <Sad color={Colors.tertiary_orange_1_light} height={25} width={25} />,
	  <Sad color={Colors.tertiary_orange_1_light} height={25} width={25} />,
	  <VerySad color={Colors?.tertirary_red_1_dark} height={25} width={25} />,
	  <VerySad color={Colors?.tertirary_red_1_dark} height={25} width={25} />,
		<Sick color={Colors?.tertirary_red_1_dark} height={25} width={25} />,
	]

	const EvollutionOptions:any = {
		'Intensificação':	<ArrowDown width={23} height={23} color='#D12600' style={{transform: 'rotate(180deg'}}/>,
		'Estabilidade' : 	<Dot style={{marginLeft:6, marginRight:5}} /> ,
		'Alivio': 	<ArrowDown width={23} height={23} color='#0CD100' /> ,
		'-': <Underline width={6} height={6} color='#3D4259' style={{marginTop:10}}/> ,
	};

	const SideBySideImages = { 
		frontal: patient?.USUARIO_SEXO === 'F' 
			? <CorpoFemininoFrontal width={195} height={450} style={{marginTop:-18}}/>
			: <CorpoMasculinoFrontal width={195} height={410} style={{}}/>,
		dorsal: patient?.USUARIO_SEXO === 'F' 
		? <CorpoFemininoDorsal width={195} height={450} style={{marginTop:-18}}/>
		: <CorpoMasculinoDorsal width={195} height={410} style={{}}/>
	};

	function HandletratQuestions() {
		const filterQuestionnaire = dataQuestionnaireEVA?.filter((item:any)=> item?.QUESTIONARIO_LABEL != 'EVA_CORPO_FRONTAL' && item?.QUESTIONARIO_LABEL != 'EVA_CORPO_DORSAL')
		dispatch(PacAvalActions.set_questionario_request(true));
		dispatch(PacAvalActions.set_questionario_array(filterQuestionnaire));
		dispatch(DashBoardActions.set_current_page("questionario_fibromialgia"));
		dispatch(DashBoardActions.set_eva_goBack("relatorio_mapa_dor_eva"));
	};
	
	
	
	return (
		<ContainerDash>
			<Return
				onClick={() => isHistory
					? dispatch(DashBoardActions.set_current_page("historic_pain_map"))
					: dispatch(DashBoardActions.set_current_page("perfil_paciente"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk style={{ color: Colors?.primary_blue_k_main }}>{current_language?.area_paciente}</P3Desk>
			</Return>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginTop: "1rem",
					marginBottom: "1rem",
					width: "60%",
					justifyContent: "space-between",
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<ProfilePicture
						src={patient?.USUARIO_FOTO ? patient?.USUARIO_FOTO : Perfil}
					/>
					<S1Desk style={{ marginLeft: "1rem" }}>
						{patient.USUARIO_NOME}
					</S1Desk>
				</div>
				<Icon
					style={{ width: "2rem", height: "2rem", cursor: 'pointer' }}
					onClick={() => {
						isHistory && difDays < 1
						? setPopup(!popup)
						:
							[
								dispatch(DashBoardActions.set_current_page("emissao_relatorios")),
								dispatch(	RelatorioActions.set_current_selected_reports(["EVA"])),
								dispatch(	RelatorioActions.set_emit_type_report("EVA"))
							]
					}
					}
					src={ isHistory && difDays < 1 ?  DotThree: PdfIcon}
				/>
			</div>
			<div style={{display: 'flex', width: "60%", position: 'relative'}}>
				{
					!isHistory &&
						<>
							<VerPerfil
								onClick={() =>{
									dispatch(DashBoardActions.set_current_page("historic_pain_map"))
								}}
							>
							{current_language?.ver_histórico}
							</VerPerfil>
							{' | '}
						</>
				}
				<VerPerfil
					onClick={() =>
						dispatch(DashBoardActions.set_current_page("informacoes_paciente"))
					}
				>
					{current_language?.ver_perfil}
				</VerPerfil>
				{
					popup &&
					<Popup
						onClick={()=>{setPopup(false), setModalModalSelectDotPain(true)}}
					>
						<Edit  width={15} height={15} color={Colors.primary_blue_k_main} style={{marginRight:5}}/>
						{current_language?.editar}
					</Popup>
				}
			</div>
			<Text 
				title={current_language?.mapa_de_dor_e_indicativos_de_fibromialgia}
				fontFamily="NotoSans-Regular"
				fontSize={1.125}
				textColor={Colors.primary_blue_k_main}
				marginTop={73}
				marginBottom={25}
			/>
			<WhiteContainer>
				<Text 
					title={current_language?.mapa_de_dor_e_indicativos_de_fibromialgia}
					fontFamily="NotoSans-Regular"
					fontSize={1.25}
					fontWeight={700}
					textColor={Colors.primary_blue_k_main}
					marginTop={42}
					marginBottom={48}
				/>
				<WarningIndicativeFibromyalgia style={{display: dataReport?.fibromialgia ? 'flex' : 'none'}}>
					<div style={{display: 'flex',}}>
						<Warning width={22} height={22}  color={Colors.tertirary_red_1_dark}/>
						<Text 
							title={current_language?.indicativo_fibromialgia}
							fontFamily="NotoSans-Regular"
							fontSize={1}
							textColor={Colors.neutral_grey_k_70}
							marginLeft={12}
						/>
					</div>
					<Text 
						title={current_language?.pontuacao_sugestiva_fibromialgia}
						fontFamily="NotoSans-Regular"
						fontSize={0.875}
						textColor={Colors.neutral_grey_k_40}
						textAlign='left'
						marginTop={6}
						marginBottom={6}
					/>
					<div style={{display: 'flex',}}>
						<Text 
							title={current_language?.pontuacao_dor_difusa}
							fontFamily="NotoSans-Regular"
							fontSize={0.875}
							textColor={Colors.neutral_grey_k_60}
				
						/>
						<span className='span'>{dataReport?.pre_relatorio?.fibromialgia?.RESULTADO}/{dataReport?.pre_relatorio?.fibromialgia?.TOTAL} </span>
						|
						<Text 
							title={current_language?.gravidade+':'}
							fontFamily="NotoSans-Regular"
							fontSize={0.875}
							textColor={Colors.neutral_grey_k_60}
							marginLeft={12}
						/>
						<span className='span'> {dataReport?.pre_relatorio?.gravidade?.RESULTADO}/{dataReport?.pre_relatorio?.gravidade?.TOTAL}</span>
					</div>
				</WarningIndicativeFibromyalgia>
				<ContainerImages>
					{dataQuestionnaireEVA?.length > 0
						?
							<>
									<ContainerImage>
										<Text 
											title={current_language?.vista_frontal}
											fontSize={0.8}
											textColor={Colors.neutral_grey_k_40}
										/>
										{SideBySideImages['frontal']}
										{
											HenderDataPositionDots('DotsArrayFrontSideBySide', patient?.USUARIO_SEXO, dataQuestionnaireEVA )
											?.map((item, index)=>(
												<InputPain 
													key={index}
													item={item}
													side={'frontal'}
													sideBySide
													setInputData={(inputData)=> {}}
													dotFocus={[]}
													arrayDataPain={[]}
													setSchemaPain={(schema)=>{}}
													extraData={dataReport?.eva_corpo?.atual}
													isFibromialgyDot={dataReport?.params_fibromialgia}
												/>
											))
										}
									</ContainerImage>
									<ContainerImage >
										<Text 
											title={current_language?.vista_dorsal}
											fontSize={0.8}
											textColor={Colors.neutral_grey_k_40}
										/>
										{SideBySideImages['dorsal']}
										{
											HenderDataPositionDots('DotsArrayDorsSideBySide', patient?.USUARIO_SEXO , dataQuestionnaireEVA)
											?.map((item, index)=>(
												<InputPain 
													key={index}
													item={item}
													side={'dorsal'}
													sideBySide
													setInputData={(inputData)=>{}}
													dotFocus={[]}
													arrayDataPain={[]}
													setSchemaPain={(schema)=>{}}
													extraData={dataReport?.eva_corpo?.atual}
													isFibromialgyDot={dataReport?.params_fibromialgia}
												/>
											))
										}
									</ContainerImage>
							</>
						:<ActivityIndicator style={{ marginTop: '10%', marginBottom: '10%' }} activityIndicatorColor={Colors?.primary_blue_k_main} /> 
							
					}
				</ContainerImages>
				<Table>
					<HeaderTable borderRadius>
						{
							OptionsHeaderTable?.map((item, index)=>(
								<OptionHeader w={item.width} key={index} align={index == 2 ? 'center' : 'flex-start'}>
									{item.title}
								</OptionHeader>
							))
						}
					</HeaderTable>
					{
						dataReport?.eva_corpo?.atual?.map((item, index)=>(
							<Cel key={index} >
								<OptionCel w={60} pd color={Colors?.neutral_grey_k_60} >
									{item?.NOME + (item.LADO === 'D' ? ' ' + current_language?.direito_eva : item.LADO === 'E' ? ' '+ current_language?.esquerdo_eva : '')}
								</OptionCel>
								<OptionCel w={28} pd={false} color={HandlerColors(item?.VALOR)} fw={700} style={{alignItems: 'center'}} >
									Nível {item?.VALOR?.toString()}
									{FaceLevelPainOptions[item?.VALOR](HandlerColors(item?.VALOR).toString())}
								</OptionCel>
								<OptionCel w={10} pd={false} fw={700} align='center' style={{justifyContent: 'center'}}  >
									{
									dataReport?.eva_corpo?.anterior?.length == 0 
										?
										<Text
												title={'-'}
												fontFamily='NotoSans-Regular'
												fontSize={1}
												fontWeight={700}
												textColor={Colors?.neutral_grey_k_60}
											/>
										: EvollutionOptions[checkArrays(item,dataReport?.eva_corpo?.anterior)]
									}
								</OptionCel>
							</Cel>
						))
					}
					<HeaderTable borderRadius={false}>
						{
							SubHeaderTable?.map((item, index)=>(
								<OptionHeader w={item.width} key={index} align={index === 0 ? 'flex-start' : 'center'}>
									{item.title}
								</OptionHeader>
							))
						}
					</HeaderTable>
					{
						CategoryData?.map((item, index)=>(
							
							<Cel key={index} >
								<OptionCel w={66} pd color={Colors.neutral_grey_k_50} >
									{item.categoria}
								</OptionCel>
								<OptionCel w={33} pd={false} 
									color={Colors.neutral_grey_k_50} align={'flex-end' } onClick={()=>item?.gravidade? {} : HandletratQuestions()}
									style={{cursor: item?.gravidade ? 'auto' : 'pointer',textDecoration: 'underline', paddingRight: item?.gravidade ? 0 : difDays >= 14 ? '6%' : '3%'}}
								>
									{
										item?.gravidade 
										? item?.gravidade
										: difDays >= 14 ? '_' : current_language?.avaliar 
									}
								</OptionCel>
								
							</Cel>
						))
					}
					<ContentInfoLegend>
						<Text title={current_language?.interpretacao_somar_pontos}
							fontFamily="NotoSans-Regular"	fontSize={1}textAlign='left'textColor={Colors.neutral_grey_k_60}
						/>
						{
							dataReport?.pre_relatorio?.gravidade?.RESULTADO
							?
								<TexLegend bold fs={1}>
									{current_language?.indicativo_fibromialgia}: <TexLegend bold={false}>{`${dataReport?.pre_relatorio?.fibromialgia?.RESULTADO} ${current_language?.pontos_fibromialgia_mais}`}</TexLegend>
									<TexLegend bold={false} fs={1}>{`${dataReport?.pre_relatorio?.gravidade?.RESULTADO} pontos da escala de gravidade = `}</TexLegend>
									<TexLegend bold={false}  fs={1} color={dataReport?.fibromialgia ? Colors.tertirary_red_1_dark : Colors.neutral_grey_k_60}>
										{
											dataReport?.fibromialgia
											? current_language?.pontuacao_sugestiva
											: current_language?.pontuacao_nao_sugestiva
										}
									</TexLegend>
								</TexLegend>
							: <TexLegend bold={false} fs={1}>{current_language?.clique_em_avaliar_na_tabela}</TexLegend>
						}
						<Separator />
						<div className='dropdownLegend'>
							<Text
								onClick={()=>{setDropdownLegend(!dropdownLegend)}}
								title={current_language?.legendas}
								fontFamily="NotoSans-Regular"
								fontSize={1}
								textAlign='left'
								marginRight={5}
								cursor='pointer'
								textColor={Colors.neutral_grey_k_70}
							/>
							<ArrowDrop style={{transform: dropdownLegend ? 'rotate(0deg)' : 'rotate(-90deg)' }}/>
						</div>
						<InfoLegend dropdownLegend={dropdownLegend}>
							<Text
								title={current_language?.a_evoluvao_da_dor}
								fontFamily="NotoSans-Regular"
								fontSize={1}
								textAlign='left'
								textColor={Colors.neutral_grey_k_60}
							/>
							<GridLegend>
								<Section >
									{current_language?.btn_evolucao}
									<SectionContent>
										<ArrowDown width={23} height={23} color='#D12600' style={{transform: 'rotate(180deg'}}/> 
										{current_language?.Intensificação}
									</SectionContent>
									<SectionContent>
										<Dot style={{marginLeft:6, marginRight:5}} /> 
										{current_language?.Estabilidade}
									</SectionContent>
									<SectionContent>
										<ArrowDown width={23} height={23} color='#0CD100' /> 
										{current_language?.Alivio}
									</SectionContent>
								</Section>
								<Section>
									{current_language?.escala_dor_Eva}
									<ContainerSpectroColors>
										{
											ScalePain?.map((item, index)=>(
												<ContainerPainlevelSelect
													key={index}
													index={index}
													selectedColor={()=> HandlerColors(index)}
													onClick={()=> {}}
												>
													{item}
												</ContainerPainlevelSelect>
											))
										}
									</ContainerSpectroColors>
									<ContentPhrase>
										{
											Faceslegend?.map((item, index)=>(
												<div 	key={index}>
													{item}
												</div>
											))
										}
									</ContentPhrase>
								</Section>
								<Section>
									{current_language?.Fibromialgia}
									<SectionContent>
										<PointScaleWPI /> {current_language?.Pontos_da_Escala_WPI}
									</SectionContent>
								</Section>
							</GridLegend>
							<div className='question'>
								<Question width={15} height={15} color={Colors.neutral_grey_k_70} />
								<Text
									title={current_language?.sao_apresentadas_as_medicoes}
									fontSize={1}
									textColor={Colors.neutral_grey_k_70}
									fontFamily='NotoSans-Regular'
									textAlign='left'
									marginLeft={5}
								/>
							</div>
							<Text
								title={current_language?.a_pontuacao_de_dor_e}
								fontSize={1}
								textColor={Colors.neutral_grey_k_60}
								fontFamily='NotoSans-Regular'
								textAlign='left'
								marginLeft={20}
								marginTop={5}
							/>
						</InfoLegend>
					</ContentInfoLegend>
				</Table>
				<Referenc>
					<Text
						onClick={()=>{window.open("https://base.materials2.kinology.com.br/critérios-diagnósticos-preliminares-do-american-college-of-rheumatology-para-fibromialgia-e-medição-da-gravidade-dos-sintomas-en", '_blank')}}
						title={current_language?.copy_american_fibromialgy}
						fontSize={0.875}
						textColor={Colors.neutral_grey_k_70}
						fontFamily='NotoSans-Regular'
						textAlign='left'
						textDecoration='underline'
						marginTop={24}
						cursor='pointer'
					/>
				</Referenc>
				<ModalSelectDotPain visible={modalModalSelectDotPain} setVisible={(visible)=>setModalModalSelectDotPain(visible)} dataEditing={dataReport?.eva_corpo?.atual}/>
			</WhiteContainer>
		</ContainerDash>
	)
};

export default ReportPainMap;