import { ExerProtoPros } from "../../pages/Execucao/Protocolo/renderExerProps";
import { DataPreReportEVA } from "./Models";

export const Types = {
	SET_EXERCISES: "SET_EXERCISES",
	SET_CURRENT_EXERCISE: "SET_CURRENT_EXERCISE",
	SET_TYPE_EXECUTION: "SET_TYPE_EXECUTION",
	SET_CURRENT_PATIENT: "SET_CURRENT_PATIENT",

	SET_VISIBLE: "SET_VISIBLE",
	SET_VISIBLE_REGRESSIVE: "SET_VISIBLE_REGRESSIVE",
	SET_VALOR: "SET_VALOR",

	SET_START: "SET_START",
	SET_IS_VALID: "SET_IS_VALID",

	SET_CURRENT_REPETICOES: "SET_CURRENT_REPETICOES",

	SET_FORCE_MAX: "SET_FORCE_MAX",

	SET_FORCE_OPTION_MIN: "SET_FORCE_OPTION_MIN",
	SET_FORCE_OPTION_MIN_STR: "SET_FORCE_OPTION_MIN_STR",
	SET_MAX_FORCE: "SET_MAX_FORCE",
	SET_FORCE_OPTION_MAX: "SET_FORCE_OPTION_MAX",
	SET_FORCE_OPTION_MAX_STR: "SET_FORCE_OPTION_MAX_STR",

	SET_TIME: "SET_TIME",
	SET_TIME_FINAL: "SET_TIME_FINAL",

	SET_IS_FORCE_MAX: "SET_IS_FORCE_MAX",

	SET_IN_EXECUTION_FORCE_MAX: "SET_IN_EXECUTION_FORCE_MAX",

	SET_VALOR_BLE: "SET_VALOR_BLE",
	SET_CONNECT_FLAG: "SET_CONNECT_FLAG",
	SET_ALREADY_CONNECTED: "ALREADY_CONNECTED",

	SET_MODE_AVALIATION: "SET_MODE_AVALIATION",

	SET_PICOS: "SET_PICOS",
	RESET: "RESET",
	RESET2: "RESET2",

	CONTROL_TIME_CONTRACAO: "CONTROL_TIME_CONTRACAO",

	ADD_EXECUCAO_REQUEST: "ADD_EXECUCAO_REQUEST",
	CANCEL_EXECUCAO_REQUEST: "CANCEL_EXECUCAO_REQUEST",

	SET_PROGRESS_EXER_PROTO: "SET_PROGRESS_EXER_PROTO",
	RESET_PROGRESS_EXER_PROTO: "RESET_PROGRESS_EXER_PROTO",

	SET_SUCCESS_SERIE_CONCLUIDA: "SET_SUCCESS_SERIE_CONCLUIDA",

	SET_PICO_RESULT: "SET_PICO_RESULT",

	SET_EXER_PROTOCOLO: "SET_EXER_PROTOCOLO",
	SET_EXER_PROTOCOLO_RESET: "SET_EXER_PROTOCOLO_RESET",

	SET_EXECUCAO_EXCLUIR: "SET_EXECUCAO_EXCLUIR",
	SET_EXECUCAO_EXCLUIR_CANCEL: "SET_EXECUCAO_EXCLUIR_CANCEL",
	SET_COMPARTILHAR_ANAMNESE: "SET_COMPARTILHAR_ANAMNESE",
	SET_COMPARTILHAR_ANAMNESE_CANCEL: "SET_COMPARTILHAR_ANAMNESE_CANCEL",
	SET_COMPARTILHAR_ANAMNESE_URL: "SET_COMPARTILHAR_ANAMNESE_URL",
	SET_TOAST_AFTER_COMPLETING: 'SET_TOAST_AFTER_COMPLETING',
	SET_ID_EXECUCAO: 'SET_ID_EXECUCAO',
	LOGS_PROTOCOLS_EXECUTED: 'LOGS_PROTOCOLS_EXECUTED',
	SET_CONNECT_FLAG_HAND_GRIP: 'SET_CONNECT_FLAG_HAND_GRIP',
	SET_VALOR_BLE_HAND_GRIP: 'SET_VALOR_BLE_HAND_GRIP',
	SET_DATA_PRE_REPORT_EVA: 'SET_DATA_PRE_REPORT_EVA',
	SET_DATA_REPORT_EVA: 'SET_DATA_REPORT_EVA',
	SET_DATA_HISTORY_REPORT_EVA: 'SET_DATA_HISTORY_REPORT_EVA',
};
let ping = 0

const INITIAL_STATE = {
	loading_execucao: false,

	valor_ble: {},
	connect_flag: false,
	already_connected: false,

	current_protocol: null,
	current_exercise: null,
	exercises: [],
	current_option_execution: null,
	patient: null,

	in_execution_force_max: false,

	visible: false,
	visible_regressive: false,
	valor: 0,

	start: null,

	force_option_min: 3,
	force_option_min_str: "3 . 00",
	is_force_max: false,
	max_force: false,
	force_option_max: 3.6,
	force_option_max_str: "3 . 60",
	force_max: 0,
	numberRepetition:[],
	result:[],

	is_valid: null,

	current_repeticoes: 0,

	values: [],
	values_result: "",

	time: "00:00",
	time_final: 0,
	time_contra_control: 0,

	picos: [],

	progress_exers_proto: [],

	serie_concluida: null,

	pico_result: [],

	exer_protocolo: [],

	execucao_loading: false,

	loading_shere_anamnese: false,
	shere_anamnese: null,
	shere_anamnese_url: null,
	set_toast_after_completing: false,
	id_execucao: null,
	connect_flag_hand_grip: false,
	valor_ble_hand_grip: {},
	data_pre_report_EVA: {},
	id_resposta_report_EVA: null,
	data_report_EVA: {},
	data_history_report_EVA: {},
};

export default function avaliation(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.SET_CURRENT_EXERCISE:
			return {
				...state,
				current_exercise: action.payload,
			};
		case Types.SET_EXERCISES:
			return {
				...state,
				exercises: action.payload,
			};
		case Types.SET_TYPE_EXECUTION:
			return {
				...state,
				current_option_execution: action.payload,
			};
		case Types.SET_CURRENT_PATIENT:
			return {
				...state,
				patient: action.payload,
			};
		case Types.SET_VISIBLE:
			return {
				...state,
				visible: action.payload,
			};
		case Types.SET_VISIBLE_REGRESSIVE:
			return {
				...state,
				visible_regressive: action.payload,
			};
		case Types.SET_VALOR:
			let aux_value = "";
			const arrayRepetitions:any = [...state.numberRepetition]
			const aux_v: any = [...state.values];
			if (action.payload.time != 0) {
				aux_v.push({
					valor: parseFloat(action.payload.valor),
					time: parseFloat(action.payload.time),
				});
			}

			arrayRepetitions.push(action.payload.repetitions)
			
			const result = []

			let anterior = arrayRepetitions[0]

			for(let i = 1; i < arrayRepetitions.length; i++){
				if(arrayRepetitions[i] != anterior){
					result.push(i)
				}
				anterior = arrayRepetitions[i]
			};

			const max = Math.max(...aux_v.map((e: any) => e.valor));

			aux_v.forEach((x: any, index: number) => {
				aux_value += index == aux_v.length - 1 ? `${x.valor}` : `${x.valor};`;
			});

			return {
				...state,
				valor: action.payload.valor,
				values: aux_v,
				values_result: aux_value,
				force_max: max,
				numberRepetition: arrayRepetitions,
				result: result,
			};
		case Types.RESET:
			return {
				...state,
				valor: 0,
				values_result: "",
				is_valid: null,
				current_repeticoes: 0,
				time: "00:00",
				time_final: 0,
				time_contra_control: 0,
				picos: [],
				start: null,
				pico_result: [],
			};
		case Types.RESET2:
			return {
				...state,
				values: [],
				force_max: 0,
			};
		case Types.SET_START:
			return {
				...state,
				start: action.payload,
			};
		case Types.SET_IS_VALID:
			return {
				...state,
				is_valid: action.payload,
			};
		case Types.SET_CURRENT_REPETICOES:
			return {
				...state,
				current_repeticoes: action.payload,
			};
		case Types.SET_FORCE_MAX:
			return {
				...state,
				force_max: action.payload,
			};
		case Types.SET_MAX_FORCE:
			return {
				...state,
				max_force: action.payload,
			};
		case Types.SET_FORCE_OPTION_MIN:
			return {
				...state,
				force_option_min: action.payload,
			};
		case Types.SET_FORCE_OPTION_MIN_STR:
			return {
				...state,
				force_option_min_str: action.payload,
			};
		case Types.SET_FORCE_OPTION_MAX:
			return {
				...state,
				force_option_max: action.payload,
			};
		case Types.SET_FORCE_OPTION_MAX_STR:
			return {
				...state,
				force_option_max_str: action.payload,
			};
		case Types.SET_TIME:
			return {
				...state,
				time: action.payload,
			};
		case Types.SET_TIME_FINAL:
			return {
				...state,
				time_final: action.payload,
			};
		case Types.SET_IS_FORCE_MAX:
			return {
				...state,
				is_force_max: action.payload,
			};
		case Types.SET_IN_EXECUTION_FORCE_MAX:
			return {
				...state,
				in_execution_force_max: action.payload,
			};
		case Types.SET_VALOR_BLE:
			ping = ping + 1
			return {
				...state,
				valor_ble:{ 
					peso: action.payload,
					ping: ping 
				} 
			};
		case Types.SET_PICOS:
			return {
				...state,
				picos: [...state.picos, action.payload],
			};
		case Types.SET_MODE_AVALIATION:
			return {
				...state,
				modo_avaliation: action.payload,
			};
		case Types.CONTROL_TIME_CONTRACAO:
			return {
				...state,
				time_contra_control: action.payload,
			};
		case Types.ADD_EXECUCAO_REQUEST:
			return {
				...state,
				loading_execucao: true,
			};
		case Types.CANCEL_EXECUCAO_REQUEST:
			return {
				...state,
				loading_execucao: false,
			};
		case Types.SET_PROGRESS_EXER_PROTO:
			return {
				...state,
				progress_exers_proto: [...state.progress_exers_proto, action.payload],
			};
		case Types.RESET_PROGRESS_EXER_PROTO:
			return {
				...state,
				progress_exers_proto: [],
			};
		case Types.SET_SUCCESS_SERIE_CONCLUIDA:
			return {
				...state,
				serie_concluida: action.payload,
			};
		case Types.SET_PICO_RESULT:
			return {
				...state,
				pico_result: action.payload,
			};
		case Types.SET_CONNECT_FLAG:
			return {
				...state,
				connect_flag: action.payload,
			};
		case Types.SET_ALREADY_CONNECTED:
			return {
				...state,
				already_connected: action.payload,
			};
		case Types.SET_EXER_PROTOCOLO:
			return {
				...state,
				exer_protocolo: [...state.exer_protocolo, action.payload],
			};
		case Types.SET_EXER_PROTOCOLO_RESET:
			return {
				...state,
				exer_protocolo: [],
			};
		case Types.SET_EXECUCAO_EXCLUIR:
			return {
				...state,
				execucao_loading: true,
			};
		case Types.SET_EXECUCAO_EXCLUIR_CANCEL:
			return {
				...state,
				execucao_loading: false,
			};
		case Types.SET_COMPARTILHAR_ANAMNESE:
			return {
				...state,
				loading_anamnese: true,
			};
		case Types.SET_COMPARTILHAR_ANAMNESE_CANCEL:
			return {
				...state,
				loading_anamnese: false,
			};
		case Types.SET_COMPARTILHAR_ANAMNESE_URL:
			return {
				...state,
				shere_anamnese_url: action.payload,
			};
		case Types.SET_TOAST_AFTER_COMPLETING:	
			return {
				...state,
				set_toast_after_completing: action.payload,
			};
		case Types.SET_ID_EXECUCAO:
			return {
				...state,
				id_execucao: action.payload,
			};
		case Types.SET_CONNECT_FLAG_HAND_GRIP:
			return {
				...state,
				connect_flag_hand_grip: action.payload,
			};
		case Types.SET_DATA_PRE_REPORT_EVA:
			return {
				...state,
				data_pre_report_EVA: action.payload.data,
				id_resposta_report_EVA: action.payload.idResposta,
			};
		case Types.SET_DATA_REPORT_EVA:
			return {
				...state,
				data_report_EVA: action.payload,
			};
		case Types.SET_DATA_HISTORY_REPORT_EVA:
			return {
				...state,
				data_history_report_EVA: action.payload,
			};
		case Types.SET_VALOR_BLE_HAND_GRIP:
			ping = ping + 1
			return {
				...state,
				valor_ble_hand_grip:{ 
					peso: action.payload,
					ping: ping 
				} 
			};
		default:
			return state;
	}
}

export const AvaliationActions = {
	set_current_exercicise: (exer: ExerProtoPros) => ({
		type: Types.SET_CURRENT_EXERCISE,
		payload: exer,
	}),
	set_exercicises: (exercises: any) => ({
		type: Types.SET_EXERCISES,
		payload: exercises,
	}),
	set_type_execution: (option: any) => ({
		type: Types.SET_TYPE_EXECUTION,
		payload: option,
	}),
	set_current_patient: (patient: any) => ({
		type: Types.SET_CURRENT_PATIENT,
		payload: patient,
	}),
	set_visible: (status: any) => ({
		type: Types.SET_VISIBLE,
		payload: status,
	}),
	set_visible_regressive: (status: any) => ({
		type: Types.SET_VISIBLE_REGRESSIVE,
		payload: status,
	}),
	set_valor: (valor: any, time: any, repetitions?:number) => ({
		type: Types.SET_VALOR,
		payload: { valor, time, repetitions},
	}),
	set_start: (status: any) => ({
		type: Types.SET_START,
		payload: status,
	}),
	set_is_valid: (status: any) => ({
		type: Types.SET_IS_VALID,
		payload: status,
	}),
	set_current_repe: (repe: any) => ({
		type: Types.SET_CURRENT_REPETICOES,
		payload: repe,
	}),

	set_force_max: (force: any) => ({
		type: Types.SET_FORCE_MAX,
		payload: force,
	}),
	set_max_force: (force: any) => ({
		type: Types.SET_MAX_FORCE,
		payload: force,
	}),

	set_force_option_max: (force: any) => ({
		type: Types.SET_FORCE_OPTION_MAX,
		payload: force,
	}),
	set_force_option_max_str: (force: any) => ({
		type: Types.SET_FORCE_OPTION_MAX_STR,
		payload: force,
	}),
	set_force_option_min: (force: any) => ({
		type: Types.SET_FORCE_OPTION_MIN,
		payload: force,
	}),
	set_force_option_min_str: (force: any) => ({
		type: Types.SET_FORCE_OPTION_MIN_STR,
		payload: force,
	}),

	set_timer: (time: any) => ({
		type: Types.SET_TIME,
		payload: time,
	}),
	set_timer_final: (time_final: any) => ({
		type: Types.SET_TIME_FINAL,
		payload: time_final,
	}),
	set_is_force_max_status: (status: any) => ({
		type: Types.SET_IS_FORCE_MAX,
		payload: status,
	}),
	set_in_execution_force_max_status: (status: any) => ({
		type: Types.SET_IN_EXECUTION_FORCE_MAX,
		payload: status,
	}),
	set_valor_ble: (valor: any) => ({
		type: Types.SET_VALOR_BLE,
		payload: valor,
	}),
	set_picos: (pico: any) => ({
		type: Types.SET_PICOS,
		payload: pico,
	}),
	reset: () => ({
		type: Types.RESET,
	}),
	reset2: () => ({
		type: Types.RESET2,
	}),
	set_mode_avaliation: (mode: any) => ({
		type: Types.SET_MODE_AVALIATION,
		payload: mode,
	}),
	set_control_contracao: (time: any) => ({
		type: Types.CONTROL_TIME_CONTRACAO,
		payload: time,
	}),

	add_execucao_request: (obj: any) => ({
		type: Types.ADD_EXECUCAO_REQUEST,
		payload: obj,
	}),
	cancel_execucao_request: () => ({
		type: Types.CANCEL_EXECUCAO_REQUEST,
	}),
	set_progress_exers_proto: (exer: any) => ({
		type: Types.SET_PROGRESS_EXER_PROTO,
		payload: exer,
	}),
	reset_progress_exers_proto: () => ({
		type: Types.RESET_PROGRESS_EXER_PROTO,
	}),
	set_serie_concluida: (type: any) => ({
		type: Types.SET_SUCCESS_SERIE_CONCLUIDA,
		payload: type,
	}),
	set_pico_result: (type: any) => ({
		type: Types.SET_PICO_RESULT,
		payload: type,
	}),
	set_connect_flag: (type: any) => ({
		type: Types.SET_CONNECT_FLAG,
		payload: type,
	}),
	set_already_connected: (type: any) => ({
		type: Types.SET_ALREADY_CONNECTED,
		payload: type,
	}),
	set_exer_protocolos: (exer: any) => ({
		type: Types.SET_EXER_PROTOCOLO,
		payload: exer,
	}),
	set_exer_protocolos_reset: () => ({
		type: Types.SET_EXER_PROTOCOLO_RESET,
	}),
	excluir_execucao: (data: any) => ({
		type: Types.SET_EXECUCAO_EXCLUIR,
		payload: data,
	}),
	excluir_execucao_cancel: () => ({
		type: Types.SET_EXECUCAO_EXCLUIR_CANCEL,
	}),
	set_shere_anamnese_request: (data: any) => ({
		type: Types.SET_COMPARTILHAR_ANAMNESE,
		payload: data,
	}),
	set_shere_anamnese_cancel: () => ({
		type: Types.SET_COMPARTILHAR_ANAMNESE_CANCEL,
	}),
	set_shere_anamnese_url: (data: any) => ({
		type: Types.SET_COMPARTILHAR_ANAMNESE_URL,
		payload: data,
	}),
	set_toast_after_completing: (status: boolean)=>({
		type: Types.SET_TOAST_AFTER_COMPLETING,
		payload: status,
	}),
	set_id_execucao: (id_execucao: number|null)=> ({
		type: Types.SET_ID_EXECUCAO,
		payload: id_execucao
	}),
	set_logs_protocols_executed: (
		txDataHora: string,
		ID_USUARIO: number,
		ID_PROTOCOLO: number,
		ID_EXERCICIO: number,
		IDS_EXECUCOES: number[]
	)=> ({
		type: Types.LOGS_PROTOCOLS_EXECUTED,
		payload: {txDataHora, ID_USUARIO, ID_PROTOCOLO, ID_EXERCICIO, IDS_EXECUCOES}
	}),
	set_connect_flag_hand_grip: (type: any) => ({
		type: Types.SET_CONNECT_FLAG_HAND_GRIP,
		payload: type,
	}),
	set_valor_ble_hand_grip: (valor: any) => ({
		type: Types.SET_VALOR_BLE_HAND_GRIP,
		payload: valor,
	}),
	set_data_pre_report_EVA: (data: DataPreReportEVA, idResposta: number) => ({
		type: Types.SET_DATA_PRE_REPORT_EVA,
		payload: {data, idResposta},
	}),
	set_data_report_EVA: (data: DataPreReportEVA) => ({
		type: Types.SET_DATA_REPORT_EVA,
		payload: data,
	}),
	set_data_history_report_EVA: (data: DataPreReportEVA) => ({
		type: Types.SET_DATA_HISTORY_REPORT_EVA,
		payload: data,
	}),
};
