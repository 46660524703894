import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { TextsProps } from "../../../database/txt.interface";
import history from "../../../routes/history";
import { AppActions } from "../../../store/ducks/app";
import { getPermission } from "../../../utils/funcs";
import { TypesPermission } from "../../../utils/types";
import { Conteiner, Header, ContainerGoBack, ContainerOptions, Option, ContainerIcon, ContainerArrowIcon, ContainerIconName, Content2 } from "./styles";
import { ServicosProps } from "../../../interfaces/config.interface";

import {ReactComponent as ArrowBack} from '../../../assets/kinologyIcons/svg/arrow_back.svg';
import {ReactComponent as Edit} from '../../../assets/kinologyIcons/svg/edit.svg';
import {ReactComponent as MyPlan} from '../../../assets/kinologyIcons/svg/fact_check.svg';
import {ReactComponent as Evaluators } from '../../../assets/kinologyIcons/svg/account_circle.svg';
import {ReactComponent as CreditCard } from '../../../assets/kinologyIcons/svg/credit_card.svg';
import {ReactComponent as PersonalizeReport } from '../../../assets/kinologyIcons/svg/insert_chart.svg';
import {ReactComponent as DownloadIcon } from '../../../assets/kinologyIcons/svg/download.svg';
import {ReactComponent as LogoutIcon } from '../../../assets/kinologyIcons/svg/logout.svg';
import {ReactComponent as Trash } from '../../../assets/kinologyIcons/svg/trash.svg';
import {ReactComponent as Arrow } from '../../../assets/kinologyIcons/svg/arrow_forward_ios.svg';

import Text from '../../../components/Texts/Text';
import { Colors } from "../../../styles/colors";
import { UserProps } from "../../../interfaces/pacientes.interface";
import { AmplitudeLog } from "../../../utils/amplitude";
import { AuthActions } from "../../../store/ducks/auth";
import { DashBoardActions } from "../../../store/ducks/dashboard";


interface MyAccountOptionsProps {
	iconOption: (color: string)=> React.ReactNode;
	arrowIcon: (color: string)=> React.ReactNode;
	title: string;
	action: ()=> void;
	subtitle?: string;
};

const MinhaConta: React.FC = () => {
	const dispatch = useDispatch();

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const dados = config_servicos;

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("minhaConta"));
	}, []);

	const openMenu = useSelector((state: any)=> state.app.openMenu)


	const [focusOptionHover, setFocusHover] = useState<number | null>(null);
	const [focusOptionIcon, setFocusIcon] = useState<number | null>(null);


	const customizeReportPermission = () => {
		const accessCustomizeReport: any = getPermission(
			config_servicos as ServicosProps[],
			TypesPermission.SERVICO_LOGOMARCA,
			dispatch
		);
		if (accessCustomizeReport) {
			dispatch(DashBoardActions.set_current_page("personalizar_relatorio"))
		} else
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true));
	};

	function permissionExportCSV() {
		const exportCSv: any = getPermission(
			config_servicos as ServicosProps[],
			TypesPermission.SERVICO_CSV
		);
		if (exportCSv) {
			dispatch(DashBoardActions.set_current_page("exportarDados"))
			dispatch(AppActions.set_title_export_data(true));
		} else {
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true));
		}
	};
	
	const MyAccountOptions: MyAccountOptionsProps[] = [
		{
			iconOption: (color: string)=> <Edit color={color}/>,
			arrowIcon: (color: string)=> <Arrow color={color}/>,
			title: current_language?.pacientes_arrasta_para_o_lado_editar,
			action: ()=>{
				dispatch(DashBoardActions.set_current_page("perfil"))
				dispatch(AppActions.set_open_editar_perfil(true));
				AmplitudeLog("editar_perfil");
			}
		},
		{
			iconOption: (color: string)=> <MyPlan  color={color}/>,
			arrowIcon: (color: string)=> <Arrow color={color}/>,
			title: current_language?.menu_lateral_meu_plano,
			action: ()=>{
				dispatch(DashBoardActions.set_current_page("meu_plano"))
				AmplitudeLog("plano_usuario")
			}
		},
		{
			iconOption:(color: string)=> <Evaluators color={color} />,
			arrowIcon: (color: string)=> <Arrow color={color}/>,
			title: current_language?.menu_lateral_avaliadores,
			action: ()=>{
				dispatch(DashBoardActions.set_current_page("evaluator"))
				AmplitudeLog("avaliadores");
			}
		},
		...dados?.SERVICO_PLANO_NOME !== "Free" &&
			dados?.SERVICO_PLANO_NOME !== "Básico" 
			? [
				{
					iconOption: (color: string)=> <CreditCard color={color}/>,
					arrowIcon: (color: string)=> <Arrow color={color}/>,
					title: current_language?.pagamentos,
					action: ()=>{
						dispatch(DashBoardActions.set_current_page("pagamentos"))
					}
				},
			  ]
			: [],
		{
			iconOption: (color: string)=> <PersonalizeReport color={color} />,
			arrowIcon: (color: string)=> <Arrow color={color}/>,
			title: current_language?.menu_lateral_personalizar_relatorio,
			action: ()=>{
				customizeReportPermission(),
				AmplitudeLog("personalizar_relatorio");
			}
		},
		{
			iconOption: (color: string)=> <DownloadIcon color={color} />,
			arrowIcon: (color: string)=> <Arrow color={color}/>,
			title: current_language?.menu_lateral_exportar,
			subtitle: current_language?.gerar_csv,
			action: ()=>{
				permissionExportCSV(), 
				AmplitudeLog("exportar_dados");
			},
		},
		{
			iconOption: (color: string)=> <LogoutIcon color={color} />,
			arrowIcon: (color: string)=> <Arrow color={color}/>,
			title: current_language?.menu_lateral_sair_conta,
			action: ()=>{
				dispatch(AuthActions.auth_logout());
				AmplitudeLog("sair_conta");
			}
		},
	];


	return (
		<>
			<Conteiner>
				<Content2>
					<Header>
						<ContainerGoBack 
							onClick={()=> {
								history.push("/dashboard")
								dispatch(DashBoardActions.set_current_page("pacientes"))
								dispatch(AppActions.set_option_menu(false, current_language?.menu_lateral_inicio))
							}}
						>
							<ArrowBack />
							<Text 
								title={current_language.menu_lateral_inicio}
								fontFamily="NotoSans-Regular"
								fontSize={1}
								fontWeight={400}
								textColor={Colors?.primary_blue_k_main}
								marginLeft={6}
								cursor="pointer"
							/>
						</ContainerGoBack>
							<Text 
								title={current_language.menu_lateral_minha_conta}
								fontFamily="NotoSans-Regular"
								fontSize={2}
								fontWeight={700}
								textColor={Colors?.primary_blue_k_main}
								marginTop={15}
							/>
					</Header>
					{MyAccountOptions?.map((item, index)=>(
							<ContainerOptions 
								key={index}
								onMouseEnter={() => setFocusHover(index) }
								onMouseLeave={()=> setFocusHover(null)}
								onMouseDown={()=> {setFocusIcon(index)  ,setFocusHover(null)}}
								onMouseUp={()=> {setFocusIcon(null), setFocusHover(index) }}
								onClick={() => item?.action()}
								user={ user_logado?.ID_USUARIO}
								index={index}
							>
								<Option
									focusOptionHover={focusOptionHover}
									HoverIndex={index}
								>
									<ContainerIconName>
										<ContainerIcon
											focusOptionIcon={focusOptionIcon}
											HoverIndex={index}
										>
											{item?.iconOption && item?.iconOption(
												focusOptionIcon == index 
												?  Colors?.white 
												:  Colors?.primary_blue_k_main
											)}
										</ContainerIcon>
										<Text 
											title={item?.title}
											fontFamily="NotoSans-Medium"
											textColor={Colors?.neutral_grey_k_70}
											fontWeight={500}
											marginLeft={12}
										/>
									</ContainerIconName>
									<ContainerArrowIcon
										focusOptionIcon={focusOptionIcon}
										HoverIndex={index}
									>
										{item?.arrowIcon && item?.arrowIcon(
											focusOptionIcon == index 
											?  Colors?.white 
											:  Colors?.primary_blue_k_main
										)}
									</ContainerArrowIcon>
								</Option>
							</ContainerOptions>
						))
					}
				</Content2>
			</Conteiner>
		</>
	);
};

export default MinhaConta;
