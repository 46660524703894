import moment from "moment";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import {
	PacientesProps,
	UserProps,
} from "../../interfaces/pacientes.interface";
import { TypesErrorRequest } from "../../utils/types";
import { AppActions } from "../ducks/app";
import { ConfigsActions } from "../ducks/configs";
import { RelatorioActions, Types } from "../ducks/relatorios";
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import { DashBoardActions } from "../ducks/dashboard";
import axios from "axios";

function* PersonalizarRelatorio({ payload }: any): any {
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const dat = {
				...payload,
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}configs/configs_relatorios_salvar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);

		let message: any = "";
		if (response.status == TypesErrorRequest.FAIL) {
			if (response.code == 1) {
				message = current_language?.falha_personalizacao;
			}

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			//msg de sucesso
			if (response.code == 0) {
				message = current_language?.personalizacao_concluida;
			}

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast: message,
				})
			);
		}
	} catch (e) {
		Sentry.captureMessage(
			"CAIU NO CATCH: configs_relatorios_salvar.php",
			"error"
		);
	} finally {
		yield put(RelatorioActions.personalizar_relatorio_request_cancel());
		yield put(ConfigsActions.config_request());
	}
}

function* Exportar(): any {
	const language: any = yield select((state) => state.configs.language);
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			// const dat = {
			//   TOKEN: user_logado.token
			// };
			const requestOptions: any = {
				method: "GET",
			};
			const res = await fetch(
				`${
					process.env.REACT_APP_BASE_URL
				}relatorios/exportar_csv.php?l=${language?.toLowerCase()}&t=${
					user_logado.token
				}`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
	} catch (e) {
	} finally {
	}
}

function* Compartilhar({ payload }: any): any {
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);

	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const dat = {
				...payload,
				TOKEN: user_logado?.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}relatorios/relatorios_pdf.php`,
				requestOptions
			);
			const response: any = await res.json();

			if (
				payload.txOutput === "download" &&
				response.status === TypesErrorRequest.SUCCESS
			)
				window.open(response.message);

			return { ...response, ...payload };
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: relatorios_pdf.php", "error");
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		let message: any = "";
		if (response.status == TypesErrorRequest.FAIL) {
			if (response.code == -1) {
				message = `${current_language?.erro_gerar_relatorio} ${response?.txTipo}. ${current_language?.sem_dados_exportar}`;
			}
			if (response.code == 1) {
				message = `${current_language?.erro_gerar_relatorio} ${response?.txTipo}. ${current_language?.falha_smtp}`;
			}

			if (response.code === 2) {
				message = `${current_language?.erro_gerar_relatorio} ${response?.txTipo}. ${response?.message}`;
			}

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			if (response.code == 0) {
				if (response.message == "Email enviado.") {
					message = current_language?.email_enviado;
				} else {
					message = response.message;
				}
				yield put(AppActions.set_modal_compartilhamento(false, 0));
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
			}
		}
	} catch (e) {
		yield put(
			AppActions.set_toast_visible({
				status_toast: true,
				type_toast: TypesErrorRequest.FAIL,
				message_toast: "500 - Não foi possivel emitir o relatório",
			})
		);
	} finally {
		yield put(RelatorioActions.set_atribuir_tags_cancel());
	}
}

function* EmitirRelatorio(): any {
	const language: any = yield select((state) => state.configs.language);
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const patient: PacientesProps = yield select(
		(state: any) => state.app.is_open_paciente
	);

	const current_period = yield select(
		(state: any) => state.relatorios.current_selected_period
	);

	let current_selected_exercises = yield select(
		(state: any) => state.relatorios.current_selected_exercises
	);

	const current_selected_reports = yield select(
		(state: any) => state.relatorios.current_selected_reports
	);

	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	const emit_type_report = yield select(
			(state: any) => state.relatorios.emit_type_report
		);

	current_selected_exercises = current_selected_exercises.map(
		(item: number) => {
			return {
				ID_EXERCICIO: item,
				MODOS: [],
			};
		}
	);

	async function apiCall() {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + user_logado.token,
			},
		};

		const dat = {
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			ID_USUARIO: patient.ID_USUARIO,
			txIdioma: language.toLowerCase(),
			txTipo: "unificado",
			txOutput: "download",
			txEmail: "",
			txPeriodo: current_period,
			txSecoes: emit_type_report == 'EVA' || (current_selected_reports.includes("EVA") && current_selected_reports.length == 1) ? ['EVA'] : current_selected_reports,
			txExercicios: emit_type_report == 'EVA' || (current_selected_reports.includes("EVA") && current_selected_reports.length == 1) ? [] : current_selected_exercises,
		};
		const URL =  window.location.hostname === 'localhost'
			? 'https://homologacao.api7.kinology.com.br/v7/relatorios/relatorio_pdf'
			: 'https://api7.kinology.com.br/v7/relatorios/relatorio_pdf'

		try {
			return await axios.post(
				URL,
				dat,
				config
			);
		} catch (error: any) {
			Sentry.captureMessage(
				"CAIU NO CATCH: relatorios/relatorio_pdf ",
				"error"
			);
			return error;
		}
	}

	try {
		const response = yield call(apiCall);

		let message: any = "";

		if (response.data.status == TypesErrorRequest.SUCCESS) {
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast: "Relatório emitido com sucesso",
				})
			);
			yield put(RelatorioActions.sync_last_report_emission())
			window.open(response.data.message);
			yield put(DashBoardActions.set_current_page("perfil_paciente"));
			yield put(	RelatorioActions.set_emit_type_report(""))
		}

		if (response.status == TypesErrorRequest.FAIL) {
			if (response.code == -1) {
				message = `${current_language?.erro_gerar_relatorio} ${response?.txTipo}. ${current_language?.sem_dados_exportar}`;
			}
			if (response.code == 1) {
				message = `${current_language?.erro_gerar_relatorio} ${response?.txTipo}. ${current_language?.falha_smtp}`;
			}

			if (response.code === 2) {
				message = `${current_language?.erro_gerar_relatorio} ${response?.txTipo}. ${response?.message}`;
			}

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		}
	} catch (e) {
		yield put(
			AppActions.set_toast_visible({
				status_toast: true,
				type_toast: TypesErrorRequest.FAIL,
				message_toast: "Exception - Não foi possivel emitir o relatório",
			})
		);
	} finally {
	}
};

function* getDataLastEmission (){
	
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	
	async function apiCall() {
		try {

			const data = {
				TOKEN: user_logado?.token,
				txTipo: "unificado"
			}
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(data),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/sync/sync_ultima_emissao_pdf.php`,
				requestOptions
			);
			const response: any = await res.json();

			return response
			
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: relatorios_pdf.php", "error");
			throw e;
		}
	}

	try{
		const response:{ultima_emissao: any} = yield call(apiCall);
		if(response.ultima_emissao){
			yield put(RelatorioActions.set_data_last_report_issued(response.ultima_emissao))
		}
	
	}catch(e){
		console.log("/sync/sync/sync_ultima_emissao_pdf.php==>:", e)
		Sentry.captureMessage("CAIU NO CATCH: /sync/sync/sync_ultima_emissao_pdf.php ", "error");
	}

};

export function* PersonalizarRelatorioSaga(): any {
	return yield all([
		takeLatest(Types.REQUEST_PERSONALIZAR_RELATORIO, PersonalizarRelatorio),
		takeLatest(Types.SET_COMPARTILHAR, Compartilhar),
		takeLatest(Types.EXPORTAR, Exportar),
		takeLatest(Types.GET_EMITIR_RELATORIO, EmitirRelatorio),
		takeLatest(Types.SYN_LAST_EMISSION, getDataLastEmission),
	]);
}
