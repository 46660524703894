import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { ContainerDash, Icon } from "../../../styles/global";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import { TextsProps } from "../../../database/txt.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { P2Desk, P3Desk, S1Desk } from "../../../styles/styles_text";
import Padrao from "../../../assets/kinologyIcons/svg/padrao.svg";
import Estetico from "../../../assets/kinologyIcons/svg/estetico.svg";
import Esportivo from "../../../assets/kinologyIcons/svg/esportivo.svg";
import ArrowForwardClosed from "../../../assets/icons/arrow_forward_ios_closed.svg";

import { AppActions } from "../../../store/ducks/app";
import { AnamneseActions } from "../../../store/ducks/anamnese";
import {
	TypeCategoriasAnamnese,
	TypesQuestionnaire,
} from "../../../utils/types";
import ToastAlert from "../../../components/toast";
import { Colors } from "../../../styles/colors";

const TiposAnamnese: React.FC = () => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const historicos_questionarios = useSelector(
		(state: any) => state.pac_aval.perfil_anamnese_array
	);

	const anamnese_category: TypeCategoriasAnamnese = useSelector(
		(state: any) => state.anamnese.anamnese_category
	);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const [open_options, set_open_options] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 560);
	}, []);

	const OptionInit = [
		{
			status: !!loading ? current_language?.loading : !historicos_questionarios?.historico_anamnese_tipo?.length
				? current_language?.botao_iniciar_protocolo 
				: historicos_questionarios?.historico_anamnese_tipo[0]?.QUESTIONARIO_RESPOSTA_CONCLUIDO === 'N' 
					? current_language?.continuar_anamnese
					:' Atualizar',
			Title: current_language?.anamnese,
				
		},
		{
			status: !!loading ? current_language?.loading : !historicos_questionarios?.historico_anamnese_avaliacao?.length
			? current_language?.botao_iniciar_protocolo 
			: historicos_questionarios?.historico_anamnese_avaliacao[0]?.QUESTIONARIO_RESPOSTA_CONCLUIDO === 'N' 
				? current_language?.continuar_anamnese
				: 'Atualizar',
			Title: 'Exame Físico/Clínico',
		},
	];

	const InitAnamnese = () => {
		if (anamnese_category == "ANAMNESE_V7") {
			dispatch(AnamneseActions.set_anamnese_type("ANAMNESE_V7"));
		} else {
			dispatch(AnamneseActions.set_anamnese_type("ANAMNESE_TIPO"));
		}
		historicos_questionarios?.historico_anamnese_tipo[0]
			?.QUESTIONARIO_RESPOSTA_CONCLUIDO === "N"
			? dispatch(
					AppActions.set_modal_continue_or_to_generate_new_anamnese(
						true,
						TypesQuestionnaire.ANAMNESE
					)
			  )
			: dispatch(AppActions.set_open_modal_escolha_modo(true));
	};

	function InitAnamneseAvaliacao() {
		if (anamnese_category == "ANAMNESE_V7") {
			dispatch(AnamneseActions.set_anamnese_type("ANAMNESE_CLINICO_V7"));
		} else {
			dispatch(AnamneseActions.set_anamnese_type("ANAMNESE_AVALIACAO"));
		}

		if (
			historicos_questionarios?.historico_anamnese_avaliacao[0]
				?.QUESTIONARIO_RESPOSTA_CONCLUIDO === "N"
		) {
			dispatch(
				AppActions.set_modal_continue_or_to_generate_new_anamnese(
					true,
					TypesQuestionnaire.ANAMNESE
				)
			);
		} else {
			if (anamnese_category == "ANAMNESE_V7") {
				dispatch(DashBoardActions.set_current_page("questionario_novo"));
			} else {
				dispatch(DashBoardActions.set_current_page("questionarios_antigos"));
			}
		}
	}

	const options = [
		{ key: "PADRAO", title: "Parâmetros gerais", icon: Padrao },
		{ key: "ESPORTIVO", title: "Prática esportiva", icon: Estetico },
		{ key: "ESTETICO", title: "Resultados Estéticos", icon: Esportivo },
	];

	return (
		<ContainerDash>
			{is_open_toast && <ToastAlert />}
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("anamnese"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk style={{ color: Colors?.primary_blue_k_main }}>{current_language?.anamnese}</P3Desk>
			</Return>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginTop: "2.5rem",
					marginBottom: "3rem",
					width: "60%",
				}}
			>
				<ProfilePicture
					src={patient?.USUARIO_FOTO ? patient?.USUARIO_FOTO : Perfil}
				/>
				<S1Desk style={{ marginLeft: "1rem" }}>{patient.USUARIO_NOME}</S1Desk>
			</div>

			<AreaResultadosButton onClick={InitAnamnese}>
				<div style={{ display: "flex", alignItems: "center" }}>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<Inciar>{OptionInit[0].status}</Inciar>
						<P2Desk>
							<strong>{OptionInit[0].Title}</strong>
						</P2Desk>
					</div>
				</div>
				<div style={{ display: "flex", alignItems: "center" }}>
					<Icon src={ArrowForwardClosed} />
				</div>
			</AreaResultadosButton>

			<AreaResultadosButton
				onClick={() => {
					set_open_options(open_options ? false : true);
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<div style={{ display: "flex", flexDirection: "column" }}>
					<Inciar>{OptionInit[1].status}</Inciar>
						<P2Desk>
							<strong>{OptionInit[1].Title}</strong>
						</P2Desk>
					</div>
				</div>
				<div style={{ display: "flex", alignItems: "center" }}>
					<Icon src={ArrowForwardClosed} />
				</div>
			</AreaResultadosButton>

			{open_options &&
				options.map((item: any, index) => (
					<AreaResultadosButton
						key={index}
						style={{ display: "flex" }}
						onClick={() => {
							dispatch(AnamneseActions.set_anamnese_category(item.key));
							InitAnamneseAvaliacao();
						}}
					>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<Icon src={item.icon} />
								<P2Desk style={{ marginLeft: "0.5rem" }}>{item.title}</P2Desk>
							</div>
						</div>
						<div style={{ display: "flex", alignItems: "center" }}>
							<Icon src={ArrowForwardClosed} />
						</div>
					</AreaResultadosButton>
				))}
		</ContainerDash>
	);
};

export default TiposAnamnese;

const Return = styled.div`
	display: flex;
	flex-direction: row;
	width: 60%;
	align-items: center;
	cursor: pointer;
`;

const ProfilePicture = styled.img`
	width: 2rem;
	height: 2rem;
	background-color: #ededed;
	border: 0;
	border-radius: 100%;
	box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
	object-fit: cover;
`;

const AreaResultadosButton = styled.div`
	width: 60%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	border-bottom: 1px solid var(--Neutras-Grey-K-10, #dedfe3);
`;

const Inciar = styled.div`
	color: var(--Neutras-Grey-K-60, #63677a);
	font-family: "NotoSans-Regular";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;
