import React, { memo, useEffect, useState } from "react";
import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { Button, Container, ContainerButtons, ContainerClose, Content } from "./styles";
import Text from "../../Texts/Text";


import {ReactComponent as Close} from '../../../assets/kinologyIcons/svg/close.svg';
import {ReactComponent as Warning} from '../../../assets/kinologyIcons/svg/warningForgot.svg';
import {ReactComponent as WarningRed} from '../../../assets/kinologyIcons/svg/WorningRed.svg';
import { Colors } from "../../../styles/colors";
import { TextsProps } from "../../../database/txt.interface";
import { useDispatch, useSelector } from "react-redux";
import { TypesErrorRequest } from "../../../utils/types";
import { AppActions } from "../../../store/ducks/app";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);
export interface ModalProps {
	visible: boolean;
	modalType: 'clear' | 'finish' | 'leavePage'| 'deletItem'
	setVisible:(visible: boolean, modalType: 'clear' | 'finish' | 'leavePage' | 'deletItem' )=>void
	action: ()=> void;
	cancelAction?: ()=> void;
};

const ModalResetPainMap: React.FC<ModalProps> = ({
	visible = true,
	modalType,
	setVisible,
	action,
	cancelAction
})=> {
	
	const classes = useStyles();
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(	(state: any) => state.configs.current_language	);

	const Title = {
		clear: current_language?.confirmar_limpar_pontos_de_dor,
		finish: current_language?.confirmar_finalizar,
		leavePage: current_language?.confirmar_sair_da_pagina,
		deletItem: current_language?.confirmar_excluir_area_de_dor,
	};
	const SubTitle = {
		clear: current_language?.nao_recuperar_pontos_de_dor,
		finish: current_language?.recomendar_avaliar_gravidade,
		leavePage: current_language?.informacoes_nao_salvas,
		deletItem: '',
	};
	const ButtonName = {
		clear: current_language?.limpar,
		finish: current_language?.execucao_finalizar,
		leavePage: current_language?.sair_modal,
		deletItem: current_language?.excluir,
	}

	return (

		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={visible}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
				<Fade in={visible} >
					<Container>
						<ContainerClose onClick={()=> {
								setVisible(false, 'deletItem'), 
								cancelAction?.()
								
							}}
						>
							<Close />
						</ContainerClose>
						<Content>
							{
								modalType != 'deletItem'
								? 	<Warning width={76} height={76}/>
								:  <WarningRed width={76} height={76} color={Colors.tertirary_red_1_dark}/>
							}
						
							<Text
								title={Title[modalType]}
								fontSize={1.75}
								textColor={Colors.primary_blue_k_main}
								fontFamily='NotoSans-Regular'
								marginTop={36}
							/>
							<Text
								title={SubTitle[modalType]}
								fontSize={1.375}
								textColor={Colors.neutral_grey_k_60}
								fontFamily='NotoSans-Regular'
								marginTop={18}
							/>
							<ContainerButtons>
								<Button type="first" modalType={modalType} onClick={()=> {
										setVisible(false, 'deletItem'), 
										action(),
										modalType == 'deletItem' && dispatch(	AppActions.set_toast_visible({	status_toast: true,	type_toast: TypesErrorRequest.FAIL,	message_toast:current_language?.nivel_de_dor_excluido_com_sucesso ,	})	);
									}}
								>
									{ButtonName[modalType]}
								</Button>
								<Button type="second" onClick={()=>{
										setVisible(false, modalType), 
										cancelAction?.()
									}}
								>
									{
										modalType != 'deletItem'
										? 'Continuar'
										: 'Cancelar'
									}
								</Button>
							</ContainerButtons>
						</Content>
					</Container>
				</Fade>
		</Modal>	
	)
};

export default ModalResetPainMap

	