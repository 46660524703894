import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Return = styled.div`
	display: flex;
	flex-direction: row;
	width: 60%;
	align-items: center;
	cursor: pointer;
`;

export const ProfilePicture = styled.img`
	width: 2rem;
	height: 2rem;
	background-color: #ededed;
	border: 0;
	border-radius: 100%;
	box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
	object-fit: cover;
`;
export const P = styled.p`
	color: ${Colors.neutral_grey_k_80};
	font-size: 1rem;
	text-decoration: underline;
	text-decoration-color: ${Colors.neutral_grey_k_80};
	cursor: pointer;
`;

export const Content = styled.div`
	display: flex;
	width: 70%;
	flex-direction: column;
	overflow-y: auto;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;
export const TextInfo = styled.p<{bold: boolean}>`
  display: flex;
	color: ${Colors.neutral_grey_k_50};
	font-family: 'NotoSans-Regular';
	font-size: 1rem;
	font-weight: ${({bold})=> bold ? 'bold' : 400};
	margin-left:5px;
`;
export const TextInfoComum = styled.p`
  display: flex;
	color: ${Colors.neutral_grey_k_50};
	font-family: 'NotoSans-Regular';
	font-size: 1rem;
	margin-left:5px;
	align-self: center;
`;

export const ContainerImages = styled.div`
	display: flex;
`;
export const ContainerOptions = styled.div<{wd: number}>`
	display: flex;
	width: ${({wd})=>wd}%;
	
	align-items: flex-start;
`;

export const ContainerView = styled.div`
	display: flex;
	width: 100%;
`;

export const Vista = styled.div`
	display: flex;
	width: 100px;
	flex-direction: column;
	border: 1px solid ${Colors.neutral_grey_k_40};
	background-color: ${Colors.white};
	border-radius: 18px;
	align-items: center;
	justify-content: center;
	padding: 4px 10px;
	/* position: absolute; */
	margin-top: 26px;
	&:active {
		border: 1px solid ${Colors.secondary_green_k_medium_1};
	}
`;
export const View = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	width: 40%;
	border: 1px solid ${Colors.neutral_grey_k_40};
	border-radius: 12px;
	margin-top: 10px;
	margin-top: 24px;
	cursor: pointer;
	margin-right: 20px;

	 &:active {
			border: 1px solid ${Colors.secondary_green_k_medium_1};
			.Vista {
				border: 1px solid ${Colors.secondary_green_k_medium_1};
			}
		}
`;

export const VistaText = styled.p`
	color: ${Colors.neutral_grey_k_60};
	font-size: 0.8rem;
	font-family: 'NotoSans-Regular';
`;

export const ButtonGo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 75%;
	/* align-self: center; */
	padding: 5px;
	font-family: 'Kanit-Regular';
	color: ${Colors.neutral_grey_k_5};
	border-radius: 12px;
	font-size: 1.5rem;
	background-color: ${Colors.secondary_green_k_medium_1};
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.22);
	margin-bottom:5px;
	cursor: pointer;
	
`;

export const ContainerInfo = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: 12px;
	padding: 15px 10px 35px 15px;
	border: 1px solid ${Colors.neutral_grey_k_10}
`;