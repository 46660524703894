import React, { useState } from 'react';
import { ContainerDash, Icon, ProfilePicture, Return } from '../../../../styles/global';
import { P3Desk, S1Desk } from '../../../../styles/styles_text';
import { Colors } from '../../../../styles/colors';
import { DashBoardActions } from '../../../../store/ducks/dashboard';

import ReturnIcon from "../../../../assets/icons/back_page.svg";
import { useDispatch, useSelector } from 'react-redux';
import { TextsProps } from '../../../../database/txt.interface';
import Perfil from "../../../../assets/icons/perfil.svg";
import {ReactComponent as ArrowDrop} from "../../../../assets/kinologyIcons/svg/arrow_drop.svg";


import Text from '../../../../components/Texts/Text';
import { ActivityIndicator, CardHistory, ContainerCardHistory, VerPerfil } from '../styles';
import { PacientesProps, UserProps } from '../../../../interfaces/pacientes.interface';
import { RelatorioActions } from '../../../../store/ducks/relatorios';
import { historyPainMapProps } from '../../../../store/ducks/Models';
import moment from 'moment';
import { AvaliationActions } from '../../../../store/ducks/avaliation';


const HistoricoRelatorioEva: React.FC = ()=> {
	const dispatch = useDispatch();
	
	const current_language: TextsProps = useSelector((state: any) => state.configs.current_language);
	const patient: PacientesProps = useSelector((state: any) => state.app.is_open_paciente);
	const dataHistoryPainMap: historyPainMapProps[] = useSelector((state:any)=> state.relatorios.data_history_pain_map);
	const language = useSelector((state: any) => state.configs.language);
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const [load, setLoad] = useState<{load:boolean, index: number|null}>({load:false, index: null});
		
	async function getHistoryData(responseId: number){
	
		const data_request = {
			TOKEN: user_logado.token,
			txIdioma: language.toLowerCase(),
			ID_RESPOSTA: responseId
		};
		try{
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(data_request),
			};


			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/sync/sync_relatorio_eva.php`,
				requestOptions
			);
			const response: any = await res.json();
			if(response){
				setLoad({load: false, index: null})
				dispatch(DashBoardActions.set_current_page("detail_historico_relatorio_pain_map"))
				dispatch(AvaliationActions.set_data_history_report_EVA(response))
			}

		}catch(error){
			setLoad({load: false, index: null})
			console.log(" ~ handleAnswersEVA ~ error:", error)
		}
	};
	
	return (
		<ContainerDash>
			<Return
				onClick={() =>dispatch(DashBoardActions.set_current_page("relatorio_pain_map"))}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk style={{ color: Colors?.primary_blue_k_main }}>{current_language?.area_paciente}</P3Desk>
			</Return>
			<div style={{display: 'flex',width: '60%'}}>
				<Text 
					title={current_language?.mapa_de_dor_e_indicativos_de_fibromialgia}
					fontFamily="NotoSans-Regular"
					fontSize={1.625}
					textAlign='left'
					fontWeight={700}
					textColor={Colors.primary_blue_k_main}
					marginTop={12}
					marginBottom={12}
				/>
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginTop: "1rem",
					marginBottom: "1rem",
					width: "60%",
					justifyContent: "space-between",
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<ProfilePicture
						src={patient?.USUARIO_FOTO ? patient?.USUARIO_FOTO : Perfil}
					/>
					<S1Desk style={{ marginLeft: "1rem" }}>
						{patient.USUARIO_NOME}
					</S1Desk>
				</div>
			
			</div>
			<div style={{display: 'flex', width: "60%"}}>
				<VerPerfil
					onClick={() =>
						dispatch(DashBoardActions.set_current_page("informacoes_paciente"))
					}
				>
					{current_language?.ver_perfil}
				</VerPerfil>
			</div>
			<ContainerCardHistory>
					{
						dataHistoryPainMap?.map((item, index)=>(
							<CardHistory 
								key={index}
								onClick={()=> {
									setLoad({load: true, index:index})
									getHistoryData(item?.ID_RESPOSTA)
								
									dispatch(DashBoardActions.set_id_resposta_history_report_eva(item?.ID_RESPOSTA))
								}}
							>
								<Text 
									title={current_language?.mapa_de_dor_e_indicativos_de_fibromialgia}
									fontFamily="NotoSans-Regular"
									fontSize={1.125}
									textAlign='left'
									cursor='pointer'
									textColor={Colors.primary_blue_k_main}
								/>
								<Text 
									title={`${current_language?.data}: ${moment(item?.RESPOSTA_CRIADO).format('DD/MM/YYYY')}`}
									fontFamily="NotoSans-Regular"
									fontSize={1}
									textAlign='left'
									textColor={Colors.neutral_grey_k_50}
									cursor='pointer'
								/>
								{
									load.load && load.index === index
									?   <ActivityIndicator width={22} heigth={22}  style={{position: 'absolute', right:10 }}/> 
									: 	<ArrowDrop width={25} height={25} color={Colors.neutral_grey_k_20} style={{position: 'absolute', right:10, transform: 'rotate(-90deg)', }}/>
								}
							</CardHistory>
						))
					}
			</ContainerCardHistory>
		</ContainerDash>
	)
};

export default HistoricoRelatorioEva