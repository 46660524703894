import React, { useState } from 'react';
import { ContainerPainlevelSelect, ContainerSchemaSelectDotPain, ContainerSpectroColors, ContentPhrase } from '../styles';
import Text from "../../Texts/Text";
import { Colors } from '../../../styles/colors';
import { HandlerColors } from '../functions';
import { dataSelectedProps, mergedArrayProps } from '../Models';
import { TextsProps } from '../../../database/txt.interface';
import { useSelector } from 'react-redux';

import {ReactComponent as VeryVeryHappy} from '../../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/very_very_happy.svg';
import {ReactComponent as VeryHappy} from '../../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/veryHappy.svg';
import {ReactComponent as Happy} from '../../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/happy.svg';
import {ReactComponent as Apathetic} from '../../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/apathetic.svg';
import {ReactComponent as Sick} from '../../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/sick.svg';
import {ReactComponent as Sad} from '../../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/sad.svg';
import {ReactComponent as VerySad}  from '../../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/verySad.svg';
import {ReactComponent as Underline}  from '../../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/underline.svg';


interface SchemaSelectDotPainProps {
	visible: boolean;
	setVisible: (visible:boolean)=>void;
	selectedDotPainFront: mergedArrayProps[] ;
	selectedDotPainDors: mergedArrayProps[] ;
	side: 'frontal'| 'dorsal' | '';
	sideBySide: boolean;
	setFrontalValuePain: (data: dataSelectedProps)=> void;
	setDorsalValuePain: (data: dataSelectedProps)=> void;
};


type keyAreaPain = {
	[key:string] : string
};

const SchemaSelectDotPain: React.FC<SchemaSelectDotPainProps> = ({
	visible,
	selectedDotPainFront, 
	selectedDotPainDors,
	side,
	sideBySide = false,
	setFrontalValuePain,
	setDorsalValuePain,
	setVisible,
})=> {
	
	const current_language: TextsProps = useSelector((state: any) => state.configs.current_language);
	const [ selectDataFront, setSelectDataFront] = useState<dataSelectedProps>()

	const ScalePain = Array.from({length: 11}).map((_, index)=> index)

	const HandlerSelectDataFront = (valueSelected:number)=> {
		const selectedColor = valueSelected <= 3
		? Colors?.tertiary_green_1_dark
		: valueSelected > 3 && valueSelected < 8 
			? Colors.tertiary_orange_1_light
			: valueSelected > 7 && Colors?.tertirary_red_1_dark
		const dataCurrentDotFront = {...selectedDotPainFront[0], VALOR: valueSelected, PAINCOLOR: selectedColor?.toString(),  }
		setFrontalValuePain(dataCurrentDotFront)
	};
	const HandlerSelectDataDors = (valueSelected:number)=> {
		const selectedColor = valueSelected <= 3
		? Colors?.tertiary_green_1_dark
		: valueSelected > 3 && valueSelected < 8 
			? Colors.tertiary_orange_1_light
			: valueSelected > 7 && Colors?.tertirary_red_1_dark
		const dataCurrentDotDors = {...selectedDotPainDors[0], VALOR: valueSelected, PAINCOLOR: selectedColor?.toString(), }
		setDorsalValuePain(dataCurrentDotDors)
	};

	const RenderSideFunctions =(valueSelected:number)=>{
		if(side === 'frontal'){
			HandlerSelectDataFront(valueSelected)
		}
		if(side === 'dorsal'){
			HandlerSelectDataDors(valueSelected)
		}
	};
	type key = {
		[key: string]: string
	};
	type Sufixkey = {
		[key: string]: (side:string )=>string
	};

	const validadeSufix: Sufixkey = {
		frontal:(side: string)=> side === 'D' ?  selectedDotPainFront[0]?.NOME.split('')[selectedDotPainFront[0]?.NOME.split('').length -1 ] === 'o' ? 'Direito' : 'Direita' : selectedDotPainFront[0]?.NOME.split('')[selectedDotPainFront[0]?.NOME.split('').length -1 ] === 'o' ? 'Esquerdo' : 'Esquerda',
		dorsal:(side: string)=> side === 'D' ?  selectedDotPainDors[0]?.NOME.split('')[selectedDotPainDors[0]?.NOME.split('').length -1 ] === 'o' ? 'Direito' : 'Direita' : selectedDotPainDors[0]?.NOME.split('')[selectedDotPainDors[0]?.NOME.split('').length -1 ] === 'o' ? 'Esquerdo' : 'Esquerda',
	};

	const MuscName: key = {
		frontal: selectedDotPainFront[0]?.NOME + ' ' + (selectedDotPainFront[0]?.SIDE === 'D' ?  validadeSufix[side]('D') : selectedDotPainFront[0]?.SIDE === 'E' ? validadeSufix[side]('E') : ''),
		dorsal: selectedDotPainDors[0]?.NOME + ' ' + (selectedDotPainDors[0]?.SIDE === 'D' ?  validadeSufix[side]('D') : selectedDotPainDors[0]?.SIDE === 'E' ? validadeSufix[side]('E') : ''),
	};
	const Faceslegend = [
		<VeryVeryHappy color={Colors?.tertiary_green_1_dark} height={25} width={25} />,
	  <VeryVeryHappy color={Colors?.tertiary_green_1_dark} height={25} width={25} />,
	  <VeryHappy color={Colors?.tertiary_green_1_dark} height={25} width={25} />,
	  <VeryHappy color={Colors?.tertiary_green_1_dark} height={25} width={25}  />,
	  <Happy color={Colors.tertiary_orange_1_light} height={25} width={25} />,
	  <Apathetic color={Colors.tertiary_orange_1_light} height={25} width={25} />,
	  <Sad color={Colors.tertiary_orange_1_light} height={25} width={25} />,
	  <Sad color={Colors.tertiary_orange_1_light} height={25} width={25} />,
	  <VerySad color={Colors?.tertirary_red_1_dark} height={25} width={25} />,
	  <VerySad color={Colors?.tertirary_red_1_dark} height={25} width={25} />,
		<Sick color={Colors?.tertirary_red_1_dark} height={25} width={25} />,
	]

	const PainArea: keyAreaPain = {
		mandibula_D: current_language?.mandibula_eva,
		mandibula_E: current_language?.mandibula_eva,
		ombro_D: current_language?.ombro_eva,
		ombro_E: current_language?.ombro_eva,
		braco_D: current_language?.braco_eva,
		braco_E: current_language?.braco_eva,
		antebraco_D: current_language?.antebraco_eva,
		antebraco_E: current_language?.antebraco_eva,
		torax_S: current_language?.torax_eva,
		abdome_S: current_language?.abdome_eva,
		coxa_D: current_language?.coxa_eva,
		coxa_E: current_language?.coxa_eva,
		joelho_D: current_language?.joelho_eva,
		joelho_E: current_language?.joelho_eva,
		perna_D: current_language?.perna_eva,
		perna_E: current_language?.perna_eva,
		pe_D: current_language?.pe_eva,
		pe_E: current_language?.pe_eva,

		pescoco_D: current_language?.pescoco_eva,
		pescoco_E: current_language?.pescoco_eva,
		ombro_posterior_D: current_language?.ombro_posterior_eva,
		ombro_posterior_E: current_language?.ombro_posterior_eva,
		coluna_superior_S: current_language?.coluna_superior_eva,
		coluna_central_S: current_language?.coluna_central_eva,
		braco_posterior_D: current_language?.braco_posterior_eva,
		braco_posterior_E: current_language?.braco_posterior_eva,
		punho_D: current_language?.punho_eva, 
		punho_E: current_language?.punho_eva, 
		lombar_D: current_language?.lombar_eva,
		lombar_E: current_language?.lombar_eva,
		quadril_D: current_language?.quadril_eva,
		quadril_E: current_language?.quadril_eva,
		coxa_posterior_D: current_language?.coxa_posterior_eva,
		coxa_posterior_E: current_language?.coxa_posterior_eva,
		perna_posterior_D: current_language?.perna_posterior_eva,
		perna_posterior_E: current_language?.perna_posterior_eva,
		tornozelo_D: current_language?.tornozelo_eva, 
		tornozelo_E: current_language?.tornozelo_eva, 
	};

	

	return (
		<ContainerSchemaSelectDotPain visible={visible}>
			<Text
				title={MuscName[side]}
				fontSize={1.125}
				textColor={Colors.neutral_grey_k_60}
				fontFamily='NotoSans-Regular'
				textAlign='left'
			/>
			<Text
				title={current_language?.area_dor +  ' : ' + PainArea[
					side === 'frontal'
					? selectedDotPainFront[0]?.ID!
					: selectedDotPainDors[0]?.ID!
				]}
				fontSize={1}
				textColor={Colors.neutral_grey_k_60}
				fontFamily='NotoSans-Regular'
				textAlign='left'
				marginTop={6}
			/>
			<Text
				title={current_language?.marque_nivel_dor+':'}
				fontSize={0.875}
				textColor={Colors.neutral_grey_k_40}
				fontFamily='NotoSans-Regular'
				textAlign='left'
				marginTop={6}
				marginBottom={12}
			/>
			<ContainerSpectroColors>
				{
					ScalePain?.map((item, index)=>(
						<ContainerPainlevelSelect
							key={index}
							index={index}
							selectedColor={()=> HandlerColors(index)}
							onClick={()=> {RenderSideFunctions(index), setVisible(false)}}
						>
							{item}
						</ContainerPainlevelSelect>
					))
				}
			</ContainerSpectroColors>
			<ContentPhrase>
				{
					Faceslegend?.map((item, index)=>(
						<div 	key={index}>
							{item}
						</div>
					))
				}
			</ContentPhrase>
		</ContainerSchemaSelectDotPain>
	)
};


export default SchemaSelectDotPain