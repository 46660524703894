import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';
import { ModalProps } from '.';


export const Container = styled.div`
	display: flex;
	width: 506px;
	height: 508px;
	flex-direction: column;
	justify-content: center;
	border-radius: 30px;
	background-color: ${Colors.neutral_grey_k_5};
	padding: 0px 32px;
	position: relative;

`;

export const ContainerClose = styled.div`
	cursor: pointer;
	position: absolute;
	right: 32px;
	top: 22px;
`;

export const Content = styled.div`
	display: flex;
	width: 100%;
	height: 80%;
	flex-direction: column;
	align-items: center;
	margin-top: 100px;
	justify-content: center;
`;
export const ContainerButtons = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;
const first = css`
	background-color: transparent;
	color: ${Colors.primary_blue_k_main};
`;
const second = css`
	background-color: ${ Colors.primary_blue_k_main};
	color:  ${ Colors.neutral_grey_k_5};
	
`;

export const Button = styled.div<{type: 'first' | 'second', modalType?:ModalProps['modalType'] }>`
	display: flex;
	width: 40%;
	cursor: pointer;
	margin-top:36px;
	font-size: 1.375rem;
	padding: 8px;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	font-family: 'Kanit-Regular';
	border: 2px solid ${({modalType})=>modalType === 'deletItem' ? Colors.tertirary_red_1_dark  : Colors.primary_blue_k_main };
	${({type})=> type === 'first' ? first : second }
`;