import React, { ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { ContainerDash, Icon } from "../../../styles/global";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import { TextsProps } from "../../../database/txt.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { P2Desk, P3Desk, S1Desk } from "../../../styles/styles_text";
import ArrowForward from "../../../assets/icons/arrow_forward_ios.svg";
import ArrowForwardClosed from "../../../assets/icons/arrow_forward_ios_closed.svg";
import Paragrafo from "../../../assets/kinologyIcons/svg/paragrafo.svg";
import Saude from "../../../assets/kinologyIcons/svg/saude.svg";
import Reabilitacao from "../../../assets/kinologyIcons/svg/reabilitacao.svg";
import Esportiva from "../../../assets/kinologyIcons/svg/esportiva.svg";
import Estetica from "../../../assets/kinologyIcons/svg/estetico.svg";
import {
	TypeCategoriasAnamnese,
	TypesQuestionnaire,
	TypeTypeAnamnese,
} from "../../../utils/types";
import { AnamneseActions } from "../../../store/ducks/anamnese";
import Questionario_progress from "../Questionario_progress";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import { Colors } from "../../../styles/colors";

const QuestionarioFibromialgia: React.FC = () => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const anamnese_category: TypeCategoriasAnamnese = useSelector(
		(state: any) => state.anamnese.anamnese_category
	);

	const anamnese_type: TypeTypeAnamnese = useSelector(
		(state: any) => state.anamnese.anamnese_type
	);
	const typeGoback = useSelector(	(state: any) => state.dashboard.eva_goback);

	//Prepara a requisição da anamnese antiga
	dispatch(PacAvalActions.set_tipo_anamnese(anamnese_category));
	dispatch(PacAvalActions.set_questionario(anamnese_type));

	


	return (
		<ContainerDash>
			<Return
				onClick={() =>{
					if(typeGoback == 'pre_relatorio_eva'){
						dispatch(DashBoardActions.set_current_page("pre_report_eva"))
					}else{
						dispatch(DashBoardActions.set_current_page("relatorio_pain_map"))
					}
				}}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk style={{color: Colors?.primary_blue_k_main}}>{current_language?.resumo}</P3Desk>
			</Return>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginTop: "1rem",
					marginBottom: "3rem",
					width: "60%",
				}}
			>
				<ProfilePicture
					src={patient?.USUARIO_FOTO ? patient?.USUARIO_FOTO : Perfil}
				/>
				<S1Desk style={{ marginLeft: "1rem" }}>{patient.USUARIO_NOME}</S1Desk>
			</div>
			<Questionario_progress Type="EVA" />
		</ContainerDash>
	);
};

export default QuestionarioFibromialgia;

const Return = styled.div`
	display: flex;
	flex-direction: row;
	width: 60%;
	align-items: center;
	cursor: pointer;
`;

const ProfilePicture = styled.img`
	width: 2rem;
	height: 2rem;
	background-color: #ededed;
	border: 0;
	border-radius: 100%;
	box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
	object-fit: cover;
`;

