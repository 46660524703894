import styled from "styled-components";
import { Colors } from "../../../styles/colors";
import { dividerClasses } from "@mui/material";

export const Content = styled.div`
	display: flex;
	width: 70%;
	padding-bottom: 50px;
	flex-direction: column;
	overflow-y: auto;
	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}


	
`;

export const Header = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
`;
export const ContainerGoBack = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-top: 20px;
`;
export const ContainerInfoUser = styled.div`
	display: flex;
	width: 100%;
	margin-top: 12px;
`;
export const ContainerImage = styled.div`
	display: flex;
	width: 2rem;
	height: 2rem;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: ${Colors?.primary_blue_k_main};
`;
export const Image = styled.img`
	object-fit: cover;
	display: flex;
	width:100%;
	height:100%;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
`;
export const EvaluatedFibromialgyWarning = styled.div<{fibromialgy:boolean}>`
	display: flex;
	width: 99.2%;
	align-items: center;
	border: 1px solid ${({fibromialgy})=> fibromialgy ? Colors.tertirary_red_1_dark : Colors.neutral_grey_k_10};
	background-color: ${({fibromialgy})=> fibromialgy ? Colors.tertirary_red_1_dark+20 : Colors.neutral_grey_k_10};
	margin-top: 18px;
	border-radius: 6px;
	padding: 8px ;
	color: ${Colors.neutral_grey_k_60};
	font-size: 1.125rem;
	font-family: 'NotoSans-Regular';

	${({fibromialgy})=> 
		!fibromialgy && `
		.Warning {
			transform: rotate(180deg);
		}`
	}
`;

export const ContainerPointFibromialgy = styled.div<{mt:number}>`
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-direction: row;
	margin-top: ${({mt})=>mt ? mt : 0 }px;
	/* background-color: bisque; */
`;
export const ContainerPoints = styled.div`
	display: flex;
	/* width: 20%; */
	border:  1px solid ${Colors.neutral_grey_k_10};
	border-radius: 21px;
	align-items: center;
	justify-content: center;
	padding: 1px 12px;
	font-size: 1rem;
	font-family: 'NotoSans-Regular';
	color: ${Colors.neutral_grey_k_60};
	margin-right:5px;
`;
export const ContainerGraphic = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
`;

export const ContainerSemiCircle = styled.div`
	display: flex;
	justify-Content: center;
	align-items: center;
	width: 200px;
	height: 180px;
`;
export const ContainerNumbers = styled.div`
	display: flex;
	width: 100%;
	background-color: red;
`;
export const TextSemiCircle = styled.p<{ $before: number; $after: number}>`
	position: absolute;
	top: 75%;
	left: 50%;
	transform: translate(-50%, -50%);
	&::before {
    content: "${({ $before }) => $before}";
    font-size: 1.563rem; /* Maior tamanho */
  }

  &::after {
    content: "/${({ $after }) => $after}";
    font-size: 1rem; /* Menor tamanho */
  }
	font-weight: 500;
	color: ${Colors.neutral_grey_k_70};
`;

export const ContainerFibromialgyBar = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	margin-top: 22px;
	min-height: 59px;

	.Number{
		font-size: 0.954rem;
		font-family: 'NotoSans-Regular';
		color: ${Colors.neutral_grey_k_50};
	}
`;
export const FibromialgyBar = styled.div`
	display: flex;
	width: 99.5%;
	height: 32px;
	border-radius: 6px;
	background-color: ${Colors.neutral_grey_k_10};
	overflow: visible;
	position: relative;
`;
export const BackgroundBar = styled.div<{value:number, max:number}>`
	width: ${({value, max})=> (value / max) * 100}%;
	height: 100%;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	border-top-right-radius: ${({value, max})=> value === max ? 6 : 0}px;
	border-bottom-right-radius: ${({value, max})=> value === max ? 6 : 0}px;
	position: absolute;
	background-color: ${({value, max})=>value > 2 ? Colors.tertirary_red_1_dark : '#AAFD9C' };
`;
export const VerticalLine = styled.div<{value:number, max:number}>`
	width: 2px;
	height: 39px;
	position: absolute;
	background-color: ${({value, max})=>value > 2 ? Colors.tertirary_red_2_dark : Colors.secondary_green_k_medium_1 };
	margin-left:  ${({value, max})=> ((value / max) * 100) + 0.01}%;
`;
export const CurrentPainValue = styled.p<{value:number, max:number}>`
	font-size: 0.954rem;
	font-family: 'NotoSans-Regular';
	color: ${Colors.neutral_grey_k_50};
	margin-top: 5px;
	margin-left:  ${({value, max, children})=> 
		Number(children) < 19
		? Number(children) < 10 ? ((value / max) * 100) -0.5  : ((value / max) * 100) -1
		: ((value / max) * 100) -2
		
	}%;
`;
export const Separator = styled.div`
	width: 100%;
	height: 1px;
	margin-top: 9px;
	margin-bottom: 18px;
	background-color: ${Colors.neutral_grey_k_10};
`;
export const TextLegend = styled.div`
	color: ${Colors.neutral_grey_k_60};
	font-size: 1.125rem;
	font-weight: 600;
	font-family: 'NotoSans-Regular';
	margin-top: 42px;
	cursor: pointer;
`;
export const ContainerLegend = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-right: 5px;

	.row {
		display: flex;
		width: 100%;
		flex-direction: row;
	}
`;
export const ContentLegend = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
`;
export const TextPointPain = styled.p`
	width: 90%;
	margin-top: -35px;
	color: ${Colors.neutral_grey_k_60};
	font-family: 'NotoSans-Regular';
	font-size: 1rem;
`;
export const Table = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: 6px;
	margin-top: 35px;
	border: 1px solid ${Colors.neutral_grey_k_10};
`;
export const Cel = styled.div<{borderNone?: boolean}>`
	width: 100%;
	display: flex;
	padding: 5px;
	border-bottom:  ${({borderNone})=> borderNone ? '0px solid transparent' : `1px solid ${Colors.neutral_grey_k_10}` };
	
`;
export const ItemCel = styled.p<{bold: boolean, color:string, width:number}>`
	color: ${({color})=> color};
	font-weight: ${({bold})=> bold ? '600' : '400' };
	font-size: 0.875rem;
	font-family: 'NotoSans-Regular';
	padding: 8px;
	width: ${({width})=> width}%;
`;
export const InfoGravity = styled.div`
	display: flex;
	font-size: 1rem;
	color: ${Colors.neutral_grey_k_70};
	font-family: 'NotoSans-Regular';
	cursor: pointer;
	margin-top: 18px;
`;
export const ContainerButtons = styled.div`
	display: flex;
	width: 100%;
	padding-right: 5px;
	margin-top: 30px;
`;
export const ButtonFinish = styled.div<{fibro: boolean}>`
	display: flex;
	align-items: center;
	justify-content:center;
	width: 100%;
	border-radius: 12px;
	padding: 15px;
	margin-top: 10px;
	cursor: ${({fibro})=> fibro ? 'auto' : 'pointer'};
	background-color: ${({fibro})=> fibro ? 'transparent' : Colors?.primary_blue_k_main };
	.Finish{
		color: ${({fibro})=> fibro ? Colors.primary_blue_k_main : Colors.neutral_grey_k_5};
		cursor: pointer;
	}
`;
export const ButtonAvaliar = styled.div<{fibro: boolean}>`
	display: flex;
	align-items:center;
	justify-content: center;
	width: 100%;
	border-radius: 12px;
	padding: 15∂px;
	margin-top: 10px;
	cursor: ${({fibro})=> fibro ? 'pointer' : 'auto'};
	background-color: ${({fibro})=> fibro ?  Colors?.primary_blue_k_main : 'transparent'};

	.Avaliar {
		color: ${({fibro})=> fibro ? Colors.neutral_grey_k_5 : Colors.primary_blue_k_main};
		cursor: pointer;
	}
`;
export const TextOptionBold = styled.span<{bold:boolean, fs?:number, mt?: number}>`
	display: inline; 
	font-family: 'NotoSans-Regular';
	font-size:${({fs})=> fs ? `${fs}rem` :  '1.125rem'};
	font-weight: ${({bold})=> bold ? 'bold' : '400'};
	color: ${Colors.neutral_grey_k_60};
	margin-top: ${({mt})=> mt ? mt : 28}px;
`;
export const ContainerOptions = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
`;
export const ContentTitleQuestion = styled.div`
	display: flex;
	width: 100%;
	padding: 15px 0px;
	border-bottom: 2px solid ${Colors.neutral_grey_k_10};
	cursor: pointer;
	position: relative;
	align-items: center;
	font-family: 'NotoSans-Regular';
	font-size: 1.125rem;
	color: ${Colors.neutral_grey_k_60};
	font-weight: 600;
	margin-top: 22px;
`;
export const QuestionDescription = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	margin-top: 22px;
`;
