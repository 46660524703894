import React, { ReactElement, useEffect, useState } from 'react';
import { ContainerIcon, ContainerSumary, ContainerText, Content, ContentEditOptions, ContentLevel, Header, MusculoName, OptionEdit, PhraseDotPain, SeparatorVertical, SumaryOptions } from './styles';

import {ReactComponent as DoubleArrow} from '../../assets/kinologyIcons/svg/DoubleArrow.svg'
import Text from '../Texts/Text';
import { Colors } from '../../styles/colors';
import { BodyPainPointProps, dataSelectedProps, mergedArrayProps } from '../BodyPainPoints/Models';

import {ReactComponent as VeryVeryHappy} from '../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/very_very_happy.svg';
import {ReactComponent as VeryHappy} from '../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/veryHappy.svg';
import {ReactComponent as Happy} from '../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/happy.svg';
import {ReactComponent as Apathetic} from '../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/apathetic.svg';
import {ReactComponent as Sick} from '../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/sick.svg';
import {ReactComponent as Sad} from '../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/sad.svg';
import {ReactComponent as VerySad}  from '../../assets/kinologyIcons/PainMapImages/FaceLevelsPain/verySad.svg';

import {ReactComponent as Arrow}  from '../../assets/kinologyIcons/svg/arrow_drop.svg';
import {ReactComponent as Edit}  from '../../assets/kinologyIcons/svg/edit.svg';
import {ReactComponent as Delete}  from '../../assets/kinologyIcons/svg/delete.svg';
import { HandlerColors } from '../BodyPainPoints/functions';
import ModalResetPainMap from '../Modais/ModalResetPainMap';
import { TextsProps } from '../../database/txt.interface';
import { useDispatch, useSelector } from 'react-redux';
import { AppActions } from '../../store/ducks/app';
import { TypesErrorRequest } from '../../utils/types';

type keyFaceLevel =  {
	[key:number]: (colo:string)=> ReactElement
};
interface SumaryProps {
	sumaryData: dataSelectedProps[] | [] ;
	side: 'frontal'| 'dorsal' | '';
	sideByside:boolean;
	SchemaSelectDotPainVisible: boolean;
	setFrontalValuePain: (data: dataSelectedProps)=> void;
	setDorsalValuePain: (data: dataSelectedProps)=> void;
	reset: (reset:'frontal'| 'dorsal' | 'all', id:string|null )=> void;
	setChangeSide:(changedSide: 'frontal'| 'dorsal')=>void;
	
};

const SumaryDotPain: React.FC<SumaryProps> = ({
	sumaryData = [],
	side,
	sideByside,
	SchemaSelectDotPainVisible,
	setFrontalValuePain,
	setDorsalValuePain,
	setChangeSide,
	reset,
	
})=> {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector((state: any) => state.configs.current_language);

	const [ modalClear, setModalClear] = useState(false);
	const [ dropdownOptions, setDropdownOptions] = useState<number|null>(null);
	const [ optionSelected, setOptionSelected] = useState<string|null>(null);

	useEffect(()=>{
		
		if(!SchemaSelectDotPainVisible) {setOptionSelected(null)}

	},[SchemaSelectDotPainVisible])

	const FaceLevelpainOptions:keyFaceLevel = {
		0:(color:string)=> <VeryVeryHappy color={color} height={25} width={25} />,
		1:(color:string)=> <VeryVeryHappy color={color} height={25} width={25} />,
		2:(color:string)=> <VeryHappy color={color} height={25} width={25} />,
		3:(color:string)=> <VeryHappy color={color} height={25} width={25}  />,
		4:(color:string)=> <Happy color={color} height={25} width={25} />,
		5:(color:string)=> <Apathetic color={color} height={25} width={25} />,
		6:(color:string)=> <Sad color={color} height={25} width={25} />,
		7:(color:string)=> <Sad color={color} height={25} width={25} />,
		8:(color:string)=> <VerySad color={color} height={25} width={25} />,
		9:(color:string)=> <VerySad color={color} height={25} width={25} />,
		10:(color:string)=> <Sick color={color} height={25} width={25} />,
	};

	const RenderSufix = (item:dataSelectedProps)=> {
		type Sufixkey = {
			[key: string]:(side:string )=>string
		};
		type key = {
			[key: string]: string
		};
	
		const validadeSufix: Sufixkey = {
			frontal:(side: string)=> side === 'D' 
				?  item?.NOME.split('')[item?.NOME.split('')?.length -1 ] === 'o' 
					? 'Direito' 
					: 'Direita' 
				: item?.NOME.split('')[item?.NOME.split('')?.length -1 ] === 'o' 
					? 'Esquerdo' 
					: 'Esquerda',
			dorsal:(side: string)=> side === 'D' 
				?  item?.NOME.split('')[item?.NOME.split('')?.length -1 ] === 'o' 
					? 'Direito' 
					: 'Direita' 
				: item?.NOME.split('')[item?.NOME.split('')?.length -1 ] === 'o' 
					? 'Esquerdo' 
					: 'Esquerda',
		};
		const MuscName: key = {
			frontal: (item?.SIDE === 'D' ?  validadeSufix[side]('D') : item?.SIDE === 'E' ? validadeSufix[side]('E') : ''),
			dorsal: (item?.SIDE === 'D' ?  validadeSufix[side]('D') : item?.SIDE === 'E' ? validadeSufix[side]('E') : ''),
		};
		return MuscName[side]
	};
	
	const HendlerEditDataSumary = (value:dataSelectedProps)=> {

		if(value.CURRENTSIDE === 'FRONTAL'){
			setFrontalValuePain(value)
		}
		if(value.CURRENTSIDE === 'DORSAL'){
			setDorsalValuePain(value)
		}

	};

	const ResetData = ()=> {
		if(side === 'frontal' && !sideByside){
			reset('frontal', optionSelected)
		}
		if(side === 'dorsal' && !sideByside){
			reset('dorsal', optionSelected)
		}
		if(sideByside){
			if(side === 'frontal' ){
				reset('frontal', optionSelected)
			}else{
				reset('dorsal', optionSelected)
			}
		}

	};

	return (
		<ContainerSumary>
			<Header>
				<PhraseDotPain> {current_language?.areas_de_dor}<DoubleArrow style={{marginLeft: 10}} /> </PhraseDotPain>
			</Header>
			<ContainerText>
				{
					sumaryData?.length == 0 &&
					<Text
						title={current_language?.aponte_pontos_de_dor}
						textColor={Colors?.neutral_grey_k_30}
						fontSize={1}
						marginTop={25}
						marginBottom={23}
						fontFamily='NotoSans-Regular'
						
					/>
				}
				{
					sumaryData?.map((item, index)=>(
						<SumaryOptions 
							key={index} 
							sumaryData={sumaryData} 
							index={index} 
							item={item}
							optionSelcted={optionSelected}
							onClick={()=>{ {
								setChangeSide(item?.CURRENTSIDE === 'FRONTAL' ? 'frontal' : 'dorsal'),
								setDropdownOptions((prevState)=>	prevState === index 	? null : index	)	}
								optionSelected === item?.ID && setOptionSelected(null)
							}}
						>
							<Content>
								<MusculoName>{item?.NOME + ' ' + RenderSufix(item)}</MusculoName>
								<ContentLevel value={item?.VALOR!}> 
									{current_language?.nivel} {item?.VALOR}
									<ContainerIcon>
										{FaceLevelpainOptions[item?.VALOR!](HandlerColors(item?.VALOR!))}
									</ContainerIcon>
								</ContentLevel>
								<Arrow width={14} height={15} style={{position: 'absolute', right:10, transform:	dropdownOptions === index ? 'rotate(0deg)' : 'rotate(-90deg)'}}/>
							</Content>
							{
								dropdownOptions === index &&
								<ContentEditOptions>
									<OptionEdit onClick={()=>{
											HendlerEditDataSumary(item),
											setOptionSelected(item?.ID!)
										
										}} 
									>
										{current_language?.editar}
										<Edit width={17} height={17} style={{marginLeft:10}} color={Colors.neutral_grey_k_60} className='hoverIcon'/>
									</OptionEdit>
									<SeparatorVertical />
									<OptionEdit onClick={()=>{
											setOptionSelected(item?.ID!), 
											setModalClear(true)
											
										}}
									>
										<div style={{marginLeft:25}}>
											{current_language?.excluir}
											<Delete  width={17} height={17} style={{marginLeft:10}} color={Colors.tertirary_red_1_dark}/>
										</div>
									</OptionEdit>
								</ContentEditOptions>
							}
						</SumaryOptions>
					))
				}
			</ContainerText>
			<ModalResetPainMap 
				visible={modalClear}
				action={()=>{ResetData()}}
				cancelAction={()=>setOptionSelected(null)}
				modalType={'deletItem'}
				setVisible={(visible)=>{setModalClear(visible)}}
			/>
		</ContainerSumary>
	)
};
export default SumaryDotPain;