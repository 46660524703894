import styled from "styled-components";
import { Colors } from "../../../styles/colors";
import { colors } from "material-ui/styles";
import { ButtonActionModalProps } from "../../../components/Buttons/ButtonActionModal/Models";

export const VerPerfil = styled.text`
	color: var(--Neutras-Grey-K-80, #2a3048);
	text-align: center;
	font-family: "NotoSans-Regular";
	font-size: 0.8rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 1.5rem */
	text-decoration-line: underline;
	margin-right: 5px;
	margin-left: 5px;
	cursor: pointer;
`;

export const WhiteContainer = styled.div`
	background-color: white;
	width: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 3rem;
	position: relative;
	z-index: 2;
	border-top: 4px solid ${Colors.secondary_green_k_main};
	overflow-y: scroll;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

export const WarningIndicativeFibromyalgia = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	border-radius: 10px;
	border: 1px solid ${Colors.tertirary_red_2_dark};
	padding: 12px 24px;
	margin-bottom: 50px;

	.span {
		font-family: 'NotoSans-Regular';
		font-size: 0.875rem;
		color: ${Colors.neutral_grey_k_40};
		margin-left: 5px;
		margin-right: 5px;
	}
`;

export const ContainerImages = styled.div`
	display: flex;
	width: 410px;
	justify-content: center;
	position: relative;
`;
export const ContainerImage = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;
export const ContainerTable = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	padding: 0px 59px 59px 59px;
`;
export const Table = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 14px;
	width: 100%;
	border: 1.36px solid ${Colors.neutral_grey_k_10};
`;
export const HeaderTable = styled.div<{borderRadius: boolean}>`
	display: flex;
	padding: 15px 12px;
	width: 100%;
	border-top-left-radius: ${({borderRadius})=> borderRadius ? 14 : 0}px;
	border-top-right-radius: ${({borderRadius})=> borderRadius ? 14 : 0}px;
	background-color:${Colors.neutral_grey_k_10+80} ;
`;
export const OptionHeader = styled.p<{w:number,align?: string}>`
	display: flex;
	width: ${({w})=>w}%;
	justify-content: ${({align})=> align ? align : 'flex-start'};
	font-family: 'NotoSans-Regular';
	font-size: 0.875rem;
	color: ${Colors.neutral_grey_k_70};
	font-weight: 600;
	text-align: center;
`;
export const Cel = styled.div`
	display: flex;
	width: 100%;
`;
export const OptionCel= styled.div<{w:number, color?: string, fw?:number, align?: string, pd?:boolean}>`
	display: flex;
	justify-content: ${({align})=> align ? align : 'flex-start'};
	border-bottom:1.37px solid ${Colors.neutral_grey_k_10+80} ;
	width: ${({w})=>w}%;
	font-family: 'NotoSans-Regular';
	font-size: 0.875rem;
	color: ${({color})=>color};
	font-weight: ${({fw})=> fw ? fw : 400};
	padding: ${({pd})=> pd ? '5.5px 12px' : '5.5px 0px'};
`;

export const ContentInfoLegend = styled.div`
	display: flex;
	width: 100%;
	padding: 16px;
	flex-direction: column;
	background-color:${Colors.neutral_grey_k_10+80} ;
	border-bottom-left-radius: 14px;
	border-bottom-right-radius: 14px;

	.dropdownLegend {
		display: flex;
		align-items: center;
		width: 100%;

	}
`;
export const Separator = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${Colors.primary_blue_k_main+20};
	margin-top: 24px;
	margin-bottom: 24px;
`;
export const InfoLegend = styled.div<{dropdownLegend: boolean}>`
	display: ${({dropdownLegend})=> dropdownLegend ? 'flex' :' none'};
	width: 100%;
	flex-direction: column;
	margin-top: 24px;

	.question {
		display: flex;
		width: 100%;
		margin-top: 32px;
		align-items: center;
	}
`;
export const  GridLegend = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 1.5rem;
  align-items: start;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
	font-family: 'NotoSans-Regular';
	font-size: 1rem;
	color: ${Colors.neutral_grey_k_60};
	margin-top: 26px;
`;
export const SectionContent = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	margin-top: 16px;
`;

export const ContainerSpectroColors = styled.div`
	width: 300px;
	display: flex;
	justify-content: space-between;
`;
export const ContainerPainlevelSelect = styled.div<{ index:number, selectedColor?: ()=> string|false}>`
	display: flex;
	padding: 3px 6px;
	border-top-left-radius: ${({index})=> index == 0 ? `${8}px` : `${0}px`};
	border-bottom-left-radius: ${({index})=> index == 0 ? `${8}px` : `${0}px`};
	border-top-right-radius:  ${({index})=> index == 10 ? `${8}px` : `${0}px`};
	border-bottom-right-radius:  ${({index})=> index == 10 ? `${8}px` : `${0}px`};
	border: 2px solid ${({selectedColor})=> selectedColor};
	color: ${({selectedColor})=> selectedColor};
	margin-top: 16px;
	background-color: white;
`;
export const ContentPhrase = styled.div`
	display: flex;
	width: 300px;

	justify-content: space-between;
`;
export const ContainerCardHistory = styled.div`
	display: flex;
	width: 60%;
	height: 70%;
	flex-direction: column;
	cursor: pointer;
	overflow: auto;
	/* background-color: blue; */
	padding-top: 40px;
	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;
export const CardHistory = styled.div`
	display: flex;
	width: 100%;
	cursor: pointer;
	padding: 15px;
	border-bottom: 1px solid ${Colors.neutral_grey_k_10};
	position: relative;
justify-content: center;
	flex-direction: column;
`;
export const Popup = styled.div`
	display: flex;
	padding:12px 30px;
	border-radius: 12px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: white;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
	font-size: 1.25rem;
	font-family: 'NotoSans-Regular';
	color: ${Colors.neutral_grey_k_70};
	position: absolute;
	right: 10px;
`;
export const TexLegend = styled.span<{bold:boolean, color?:string; fs?:number}>`
	display: inline; 
	font-family: 'NotoSans-Regular';
	font-size:${({fs})=> fs ? `${fs}rem` :  '1.125rem'};
	font-weight: ${({bold})=> bold ? 'bold' : '400'};
	color: ${({color})=> color ? color : Colors.neutral_grey_k_60};
	margin-top: 28px;
`;
export const Referenc = styled.div`
	width: 100%;
`;

export const ActivityIndicator = styled.div<Pick<ButtonActionModalProps, 'activityIndicatorColor'>&{width?: number , heigth?:number}>`
  width: ${({width})=> width ? width : 33}px;
  height: ${({heigth})=> heigth ? heigth : 33}px;
  border: 4px solid ${({activityIndicatorColor})=> activityIndicatorColor ? activityIndicatorColor : Colors?.neutral_light_gray_3};
  border-left-color: ${({activityIndicatorColor})=> activityIndicatorColor ? activityIndicatorColor + 40 : Colors?.neutral_light_gray_3 + 40};
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;