import styled, { css } from 'styled-components';
import { Colors } from '../../styles/colors';
import { ContainerInputProps } from './Models';

export const Container = styled.div`
	display: flex;
	width: 100%;
	height: 80%;
	justify-content: center;
	/* background-color: brown; */
`;
export const Content = styled.div<{sideBySide: boolean}>`
	display: flex;
	flex-direction: ${({sideBySide})=> sideBySide ? 'row' : 'column'};
	align-items: center;
	justify-content: space-around;
	width: 100%;
	height: 100%;
	position: relative;
`;

export const ContainerImage = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

export const ContainerInputPain = styled.div<Partial<ContainerInputProps>>`
  display:  flex;
	pointer-events: ${({arrayDataPain, item})=>arrayDataPain?.find((dataPain)=> dataPain?.ID === (item?.LABEL + '_' + item?.SIDE))  && 'none' };
  width: ${({ sideBySide, isFibromialgyDot }) => (sideBySide ? ( isFibromialgyDot ? 11 :  12 ) : 14)}px;
  height: ${({ sideBySide, isFibromialgyDot }) => (sideBySide ? ( isFibromialgyDot ? 11 :  12 ) : 14)}px;
	border-radius: ${({sideBySide, isFibromialgyDot, item})=> 
		isFibromialgyDot
		? isFibromialgyDot?.find((value)=> value === item?.LABEL)  
			? 0 
			: 10
		:	sideBySide ? 12 : 14
	}px;
	transform: ${({isFibromialgyDot})=> isFibromialgyDot?.length! > 0 ? 'rotate(45deg)': 'rotate(0deg)' };
  border: 1.46px solid ${({item,side, dotFocus, arrayDataPain,  handlerColors }) => 
		arrayDataPain?.find((dataPain)=> dataPain?.ID === (item?.LABEL + '_' + item?.SIDE))
		?	Colors.neutral_grey_k_50
		: dotFocus?.find((itemFocus)=> itemFocus.ID === (item?.LABEL + '_' + item?.SIDE)) && item?.CURRENTSIDE?.toLowerCase() === side ? Colors.primary_blue_k_light_1 : Colors.neutral_grey_k_50
	};
  background-color: ${({item,side, dotFocus, arrayDataPain, extraData, handlerColors }) =>  
		extraData?.length! > 0
		? extraData?.find((extra)=> extra.MUSCULO === item?.LABEL && extra.LADO === item?.SIDE ) 
			? handlerColors?.(extraData?.find((extra)=> extra?.MUSCULO === item?.LABEL && extra?.LADO === item?.SIDE )?.VALOR!)!
			: Colors.neutral_grey_k_30
		
		: arrayDataPain?.find((dataPain)=> dataPain?.ID === (item?.LABEL + '_' + item?.SIDE))
			? arrayDataPain?.find((dataPain)=> dataPain?.ID === (item?.LABEL + '_' + item?.SIDE))?.PAINCOLOR
			: dotFocus?.find((itemFocus)=> itemFocus.ID === (item?.LABEL + '_' + item?.SIDE)) && item?.CURRENTSIDE?.toLowerCase() === side ? Colors.neutral_grey_k_5 : Colors.neutral_grey_k_30
	};
  position: absolute;
  cursor: pointer;
  top: ${({item})=> item?.TOP};
  left: ${({item})=> item?.LEFT};
`;

export const ContainerSchemaSelectDotPain = styled.div<{visible:boolean}>`
	display: ${({visible})=> visible ? 'flex' : 'none'};
	flex-direction: column;
	width: 100%;
	height: 186px;
	border-radius: 12px;
	border: 1px solid ${Colors.neutral_grey_k_10};
	padding: 14px 30px;

`;
export const ContainerSpectroColors = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;
export const ContainerPainlevelSelect = styled.div<{ index:number, selectedColor: ()=> string|false}>`
	display: flex;
	padding: 3px 6px;
	border-top-left-radius: ${({index})=> index == 0 ? `${8}px` : `${0}px`};
	border-bottom-left-radius: ${({index})=> index == 0 ? `${8}px` : `${0}px`};
	border-top-right-radius:  ${({index})=> index == 10 ? `${8}px` : `${0}px`};
	border-bottom-right-radius:  ${({index})=> index == 10 ? `${8}px` : `${0}px`};
	border: 2px solid ${({selectedColor})=> selectedColor};
	color: ${({selectedColor})=> selectedColor};
	cursor:pointer;
	background-color: white;
`;
export const ContentPhrase = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;
