import React, { useState } from 'react';
import { ContainerDash } from '../../../styles/global';
import { 
	BackgroundBar, 
	ButtonAvaliar, 
	ButtonFinish, 
	Cel, 
	ContainerButtons, 
	ContainerFibromialgyBar, 
	ContainerGoBack, 
	ContainerGraphic, 
	ContainerImage, 
	ContainerInfoUser, 
	ContainerLegend, 
	ContainerOptions, 
	ContainerPointFibromialgy, 
	ContainerPoints, 
	ContainerSemiCircle, 
	Content, 
	ContentLegend, 
	ContentTitleQuestion, 
	CurrentPainValue, 
	EvaluatedFibromialgyWarning, 
	FibromialgyBar, 
	Header, 
	Image, 
	InfoGravity, 
	ItemCel, 
	QuestionDescription, 
	Separator, 
	Table, 
	TextLegend, 
	TextOptionBold, 
	TextPointPain, 
	TextSemiCircle, 
	VerticalLine 
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { DataPreReportEVA } from '../../../store/ducks/Models';

import {ReactComponent as ArrowBack} from '../../../assets/kinologyIcons/svg/arrow_back.svg';
import {ReactComponent as UserWithoutPhoto } from "../../../assets/kinologyIcons/svg/patient.svg";
import {ReactComponent as Warning } from "../../../assets/kinologyIcons/svg/WorningRed.svg";
import {ReactComponent as Arrow } from "../../../assets/kinologyIcons/svg/arrow_drop.svg";

import Text from '../../../components/Texts/Text';
import { DashBoardActions } from '../../../store/ducks/dashboard';
import { Colors } from '../../../styles/colors';
import { PacientesProps, UserProps } from '../../../interfaces/pacientes.interface';
import ModalResetPainMap from '../../../components/Modais/ModalResetPainMap';
import { AppActions } from '../../../store/ducks/app';
import { TypesErrorRequest, TypesRelatorio } from '../../../utils/types';
import ModalInfoGravity from '../../../components/Modais/ModalInformacaoGravidade';
import { AvaliationActions } from '../../../store/ducks/avaliation';
import moment from 'moment';
import { RelatorioActions } from '../../../store/ducks/relatorios';
import { PacAvalActions } from '../../../store/ducks/manage_pac_aval';
import { ActivityIndicator } from '../../Relatorios/RelatorioMapaDorEva/styles';
import { TextsProps } from '../../../database/txt.interface';

const PreReportEva: React.FC = () => {
	const dispatch = useDispatch();
		
	const dataPreReport: DataPreReportEVA = useSelector((state:any)=> state.avaliation.data_pre_report_EVA );
	const IdRespostaQuestionarioDor: DataPreReportEVA = useSelector((state:any)=> state.avaliation.id_resposta_report_EVA );

	const patient: PacientesProps = useSelector((state: any) => state.app.is_open_paciente);
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);
	const language = useSelector((state: any) => state.configs.language);
	const dataQuestionnaireEVA = useSelector((state:any)=> state.relatorios.questionnaireEVA);
	const current_language: TextsProps = useSelector(	(state: any) => state.configs.current_language	);

	const [ dropDown, setDropDown] = useState<number[]|[]>([]);
	const [ modalInfo, setModalInfo] = useState(false);
	const [ confirm, setModalConfirm] = useState<{visible:boolean, type: 'finish' | 'clear'}>({visible: false, type: 'clear'});
	const [ load, setLoad] = useState(false);
	const [ loadAvaliar, setLoadAvaliar] = useState(false);

	async function getReportEVA (){
		const data_request = {
			TOKEN: user_logado.token,
			txIdioma: language.toLowerCase(),
			ID_USUARIO: patient.ID_USUARIO
		};
		try{
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(data_request),
			};

			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/sync/sync_relatorio_eva.php`,
				requestOptions
			);
			const response: any = await res.json();

			if(response?.pre_relatorio){
				setLoad(false)
				dispatch(AvaliationActions.set_data_report_EVA(response))
				dispatch(	DashBoardActions.set_current_page("relatorio_pain_map"));
			}
		}catch(error){
			setLoad(false)
			console.log(" ~ handleAnswersEVA ~ error:", error)
		}
	}

async function FinishPainMap() {
	setLoad(true)
	const data_request = {
		TOKEN: user_logado.token,
		txDataHora: moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString(),
		ID_USUARIO: patient?.ID_USUARIO,
		txRespostas: [],
		txTipo: "EVA",
		EVA_CONCLUIDO: "S",
		ID_RESPOSTA: IdRespostaQuestionarioDor
	}

	try{
		const requestOptions: any = {
			method: "POST",
			body: JSON.stringify(data_request),
		};

		const res = await fetch(
			`${process.env.REACT_APP_BASE_URL}/questionarios/questionario_salvar_eva.php`,
			requestOptions
		);

		
		const response: any = await res.json();
		
		if(response.status === 'success'){
			getReportEVA()
			
		}
	}catch(error){
		setLoad(false)
		console.log(" ~ handleAnswersEVA ~ error:", error)
	}
};

	function HandletratQuestions() {
		const filterQuestionnaire = dataQuestionnaireEVA?.filter((item:any)=> item?.QUESTIONARIO_LABEL != 'EVA_CORPO_FRONTAL' && item?.QUESTIONARIO_LABEL != 'EVA_CORPO_DORSAL')
		dispatch(PacAvalActions.set_questionario_request(true));
		dispatch(PacAvalActions.set_questionario_array(filterQuestionnaire));
		dispatch(DashBoardActions.set_current_page("questionario_fibromialgia"));
		dispatch(DashBoardActions.set_eva_goBack("pre_relatorio_eva"));
		setLoadAvaliar(false)
	};

	const FibroQuestions = [
		current_language?.fadiga_e_cansaco,
		current_language?.sono_nao_reparador,
		current_language?.sintomas_cognitivos,
		current_language?.sintomas_somaticos,
	];

	const QuestionOptions=[
		{
			title: current_language?.o_que_e_fibromialgia,
			component: (
				<Text 
					title={current_language?.o_que_e_fibromialgia_descricao}
					fontFamily="NotoSans-Regular"
					fontSize={1}
					textAlign='left'
					textColor={Colors?.neutral_grey_k_60}
				/>
			)
		},
		{
			title: current_language?.como_funciona_pontuacao_fibromialgia,
			component: (
				<>
					<Text 
						title={current_language?.pontos_fibromialgia}
						fontFamily="NotoSans-Regular"
						fontSize={1}
						fontWeight={600}
						textAlign='left'
						textColor={Colors?.neutral_grey_k_60}
					/>
					<Text 
						title={current_language?.pontos_fibromialgia_selecionados}
						fontFamily="NotoSans-Regular"
						fontSize={1}
						textAlign='left'
						marginTop={22}
						textColor={Colors?.neutral_grey_k_60}
					/>

					<ContainerFibromialgyBar>
						<FibromialgyBar style={{backgroundColor: Colors.tertirary_red_1_dark}}>
							<BackgroundBar value={3} max={19} style={{backgroundColor: '#AAFD9C'}} />
							<VerticalLine value={2} max={19} style={{backgroundColor: 'transparent'}} />
						</FibromialgyBar>
						<div style={{display: 'flex', width: '100%', }}>
							<p style={{display: 'flex', width:'15.5%', }} className='Number'>
								0
							</p>
							<p style={{display: 'flex', width:'14%', }} className='Number'>
								2
							</p>
							<p style={{display: 'flex',justifyContent: 'flex-end', width:'70%', }} className='Number'>
								19
							</p>
						</div>
					</ContainerFibromialgyBar>
					<Table>	
						<Cel style={{backgroundColor: Colors.neutral_grey_k_10+80}}>
							<ItemCel bold color='#414D4A' width={30}>
								{current_language?.pontos_fibromialgia_2}
							</ItemCel>
							<ItemCel bold color='#414D4A' width={70}>
								{current_language?.interpretacao}
							</ItemCel>
						</Cel>

						<Cel>
							<ItemCel bold={false} color={Colors.neutral_grey_k_60} width={30}>
								{current_language?.menos_que_tres_pontos}
							</ItemCel>
							<ItemCel bold={false} color={Colors.neutral_grey_k_60} width={70}>
								{current_language?.sem_indicativos_fibromialgia}
							</ItemCel>
							
						</Cel>
						<Cel borderNone>
							<ItemCel bold={false} color={Colors.neutral_grey_k_60} width={30}>
								{current_language?.maior_ou_igual_tres_pontos}
							</ItemCel>
							<ItemCel bold={false} color={Colors.neutral_grey_k_60} width={70}>
								{current_language?.avaliar_gravidade}
							</ItemCel>
						</Cel>
					</Table> 
				</>
			)
		},
		{
			title: current_language?.quando_realizar_questionario,
			component: (
				<>
					<Text 
						title={current_language?.indicacao_questionario}
						fontFamily="NotoSans-Regular"
						fontSize={1}
						textAlign='left'
						textColor={Colors?.neutral_grey_k_60}
					/>
					<Text 
						title={current_language?.avaliar_gravidade_fibromialgia}
						fontFamily="NotoSans-Regular"
						fontSize={1}
						textAlign='left'
						textColor={Colors?.neutral_grey_k_60}
					/>
				</>
			)
		},
		{
			title: current_language?.como_funciona_escala_gravidade,
			component: (
				<>
					<TextOptionBold bold={false} fs={1}>
						{current_language?.indicar_risco_fibromialgia+' '}<TextOptionBold bold>{current_language?.quatro_perguntas+' '} </TextOptionBold>{current_language?.gravidade_dores_categoria}
					</TextOptionBold>
					{
						FibroQuestions?.map((item, index)=>(
							<TextOptionBold bold fs={1} key={index}  mt={10} style={{marginLeft:10}}>
								·  <TextOptionBold bold={false} fs={1}>{item}</TextOptionBold>
							</TextOptionBold>
							
						))
					}
					<Text 
						title={current_language?.escala_gravidade_fibromialgia}
						fontFamily="NotoSans-Regular"
						fontSize={1}
						textAlign='left'
						marginTop={10}
						textColor={Colors?.neutral_grey_k_60}
					/>
				</>
			)
		},
	]


	
	
	return(
		<ContainerDash>
			<Content>
					<Header>
						<ContainerGoBack 
							onClick={()=> {
								dispatch(	AppActions.set_toast_visible({	
										status_toast: false,	
										type_toast: TypesErrorRequest.SUCCESS,
										message_toast: current_language?.pontos_dor_salvos_acessar_area_resultados,
									})
								);
								dispatch(DashBoardActions.set_current_page("perfil_paciente"))
							}}
						>
							<ArrowBack />
							<Text 
								title={current_language?.area_paciente}
								fontFamily="NotoSans-Regular"
								fontSize={0.7}
								fontWeight={400}
								textColor={Colors?.primary_blue_k_main}
								marginLeft={6}
								cursor="pointer"
							/>
						</ContainerGoBack>
						<Text 
							title={current_language?.eva_indicativo_fibromialgia}
							fontFamily="NotoSans-Regular"
							fontSize={1.625}
							fontWeight={700}
							textColor={Colors?.primary_blue_k_main}
							textAlign="left"
							marginTop={10}
						/>
						<ContainerInfoUser>
							<ContainerImage>
								{patient?.USUARIO_FOTO != "" &&
								patient?.USUARIO_FOTO != null &&
								patient?.USUARIO_FOTO != "img/photo.png" ? (
									<Image src={patient?.USUARIO_FOTO} />
								) : (
									<UserWithoutPhoto color={Colors?.white} width={16} height={16} />
								)}
							</ContainerImage>
							<Text 
								title={patient?.USUARIO_NOME}
								fontFamily="NotoSans-Regular"
								fontSize={1.25}
								fontWeight={400}
								textColor={'#323D6E'}
								textAlign="left"
								marginLeft={10}
							/>
						</ContainerInfoUser>
					</Header>
					
					<EvaluatedFibromialgyWarning fibromialgy={dataPreReport?.pre_relatorio?.necessario_avaliar_gravidade}>
						<Warning 
							width={19} 
							className='Warning'
							height={19} 
							color={dataPreReport?.pre_relatorio?.necessario_avaliar_gravidade ? Colors.tertirary_red_2_dark : Colors.neutral_grey_k_60 }
							style={{marginRight:10}}
						/> 
						{dataPreReport?.pre_relatorio?.necessario_avaliar_gravidade ? 	current_language?.avaliar_gravidade :  current_language?.nao_existe_indicativos_fibromialgia}
					</EvaluatedFibromialgyWarning>
					{
						dataPreReport?.pre_relatorio?.necessario_avaliar_gravidade
						?
							<TextOptionBold bold={false}>
								{current_language?.cliente_reportou_total}{' '}
								<TextOptionBold bold={true}>{`${dataPreReport.pre_relatorio.pontos_dor.RESULTADO} ${current_language?.pontos_de_dor}`}</TextOptionBold>, {current_language?.dentre_eles}{' '}
								<TextOptionBold bold={true}>{`${dataPreReport.pre_relatorio.fibromialgia.RESULTADO} ${current_language?.ponto_dor} `}</TextOptionBold>{current_language?.selecionado_pontos_fibromialgia}
							</TextOptionBold>
						:
							<TextOptionBold bold={false}>
							{current_language?.cliente_reportou_total}{' '}
								<TextOptionBold bold={true}>{`${dataPreReport.pre_relatorio.pontos_dor.RESULTADO} ${current_language?.pontos_de_dor}`}</TextOptionBold>, {current_language?.dentre_eles}{' '}
								<TextOptionBold bold={true}>{`${dataPreReport.pre_relatorio.fibromialgia.RESULTADO} ${current_language?.ponto_dor} `}</TextOptionBold>{current_language?.selecionado_pontos_fibromialgia}
							</TextOptionBold>
					}

					
					<ContainerPointFibromialgy mt={18}>
						<Text 
							title={current_language?.pontos_fibromialgia_2}
							fontFamily="NotoSans-Regular"
							fontSize={1.125}
							textColor={Colors.neutral_grey_k_60}
							textAlign="left"
						/>
						<ContainerPoints>
							{dataPreReport?.pre_relatorio?.fibromialgia?.RESULTADO} {current_language?.pontos}
						</ContainerPoints>
					</ContainerPointFibromialgy>
				
			

					<ContainerFibromialgyBar>
						<FibromialgyBar>
							<BackgroundBar value={dataPreReport?.pre_relatorio?.fibromialgia?.RESULTADO} max={dataPreReport?.pre_relatorio?.fibromialgia?.TOTAL} />
							<VerticalLine value={dataPreReport?.pre_relatorio?.fibromialgia?.RESULTADO} max={dataPreReport?.pre_relatorio?.fibromialgia?.TOTAL} />
						</FibromialgyBar>
						<CurrentPainValue value={dataPreReport?.pre_relatorio?.fibromialgia?.RESULTADO} max={dataPreReport?.pre_relatorio?.fibromialgia?.TOTAL}>
							{dataPreReport?.pre_relatorio?.fibromialgia?.RESULTADO}
						</CurrentPainValue>
					</ContainerFibromialgyBar>

					{
						dataPreReport?.pre_relatorio?.necessario_avaliar_gravidade
						?	
							<TextOptionBold bold>
							{current_language?.indicativos_fibromialgia},{' '}
								<TextOptionBold bold={false}>{current_language?.questionario_escala_gravidade}</TextOptionBold>
							</TextOptionBold>
						:
							<TextOptionBold bold>
								{current_language?.nao_existindo_indicativos_fibromialgia}{' '}
								<TextOptionBold bold={false}>{current_language?.continuar_encerrar_avaliacao}</TextOptionBold>
							</TextOptionBold>
					}

					<TextLegend >
						{current_language?.duvidas} 
					</TextLegend>

					<ContainerLegend>
						{
							QuestionOptions?.map((item, index)=>(
								<ContainerOptions>
									<ContentTitleQuestion 
										onClick={()=> 
											setDropDown((prevState)=>
												prevState[0] == index
												? []
												: [index]
											)
										}
									>
										{item?.title}
										<Arrow 
											width={15} 
											height={15} 
											style={{position: 'absolute', right:0, transform: dropDown[0] == index ? 'rotate(0deg)' : 'rotate(-90deg)'}} 
										/>
									</ContentTitleQuestion>
									{
										dropDown[0] == index &&
									<QuestionDescription>
										{item.component}
									</QuestionDescription>
									}
								</ContainerOptions>
							))
						}
					</ContainerLegend>

					<ContainerButtons>
						<ButtonFinish fibro={dataPreReport?.pre_relatorio?.necessario_avaliar_gravidade}>
							<p className='Finish' onClick={()=>{
									dataPreReport?.pre_relatorio?.necessario_avaliar_gravidade
									 ? setModalConfirm({visible:true, type: 'finish'}) 
									 : FinishPainMap()
							}}>
								{
									load
									? <ActivityIndicator width={22} heigth={22}  />
									: current_language?.execucao_finalizar
								}
								
							</p>
						</ButtonFinish>
						<ButtonAvaliar fibro={dataPreReport?.pre_relatorio?.necessario_avaliar_gravidade}>
							<p className='Avaliar' onClick={()=>{setLoadAvaliar(true),  HandletratQuestions()}}>
								{
									loadAvaliar
									? <ActivityIndicator width={22} heigth={22}  />
									: current_language?.avaliar_gravidade2
								}
							
							</p>
						</ButtonAvaliar>
					</ContainerButtons>
					<ModalResetPainMap 
						visible={confirm.visible}
						action={()=>{FinishPainMap()}}
						modalType={confirm.type}
						setVisible={(visible)=>{setModalConfirm({visible: visible, type: 'finish'})}}
					/>
					<ModalInfoGravity
						visible={modalInfo}
						setVisible={((visible)=>setModalInfo(visible))}
					/>
			</Content>
		</ContainerDash>
	)
};

export default PreReportEva;