import { QuestionarioEVAProps } from "../../store/ducks/Models"
import { mergedArrayProps } from "./Models"

interface HenderDataPositionDotsProps {
	top: number
	left: number 
	side?: string
	label?: string
	name?: string
};

type DataOptionsType = {
	[key: string]: Array<mergedArrayProps>
}

export const HenderDataPositionDots = (
	TypeData: 'DotsArrayDors' | 'DotsArrayFront' | 'DotsArrayDorsSideBySide' | 'DotsArrayFrontSideBySide',
	genderPatient: string,
	questionnaireData?: QuestionarioEVAProps[],
	from?: string
) => {
	
	const EVA_CORPO_FRONTAL = questionnaireData?.find((item)=> item.QUESTIONARIO_LABEL === 'EVA_CORPO_FRONTAL');
	const EVA_CORPO_DORSAL = questionnaireData?.find((item)=> item.QUESTIONARIO_LABEL === 'EVA_CORPO_DORSAL')

	const DotsArrayFront = [
		{
			top:  genderPatient == 'F' ? '12%'   : '12.5%' , //Mandibola  direito
			left:  genderPatient == 'F' ? '44.5%'  : '43%',
			side: 'D',
			currentSide: 'FRONTAL'
		},
		{
			top:  genderPatient == 'F' ? '12%'   : '12.5%' ,  //Mandibola esquerdo
			left: genderPatient == 'F' ? '52%' : '52%',
			side: 'E',
			currentSide: 'FRONTAL'
		},
		{
			top:  genderPatient == 'F' ? '18%'   : '20.5%'  , // ombro esquerdo
			left:  genderPatient == 'F' ? '38.5%' : '37%',
			side: 'E',
			currentSide: 'FRONTAL'
		},
		{
			// ombro direito
			top:  genderPatient == 'F' ? '18%'   : '20.5%'  ,
			left:  genderPatient == 'F' ? '57%': '58%' ,
			side: 'D',
			currentSide: 'FRONTAL'
		},
		{
			top:  genderPatient == 'F' ? '27%'  : '30%'  , // Braço esquerdo
			left: genderPatient == 'F' ? '36%'   :'34%',
			side: 'E',
			currentSide: 'FRONTAL'
		},
		{
			top:  genderPatient == 'F' ?'27%'  :'30%'  , // Braço Direito
			left: genderPatient == 'F' ?'60%' : '62%' ,
			side: 'D',
			currentSide: 'FRONTAL'
		},
		{
			top:  genderPatient == 'F' ? '38%': '41%'  , // Antebraço esquedo
			left: genderPatient == 'F' ? '31%' : '30.5%',
			side: 'E',
			currentSide: 'FRONTAL'
		},
		{
			top:  genderPatient == 'F' ? '38%': '41%'  , // Antebraço direito
			left: genderPatient == 'F' ? '65%' : '65.5%' ,
			side: 'D',
			currentSide: 'FRONTAL'
		},
		{
			top: genderPatient == 'F' ? '53%' : '58%'  , // Coxa esquerdo
			left: genderPatient == 'F' ? '43%'  :'42%',
			side: 'E',
			currentSide: 'FRONTAL'
		},
		{
			top: genderPatient == 'F' ? '53%' : '58%'  , // Coxa direito
			left:  genderPatient == 'F' ? '53%' : '53%',
			side: 'D',
			currentSide: 'FRONTAL'
		},
		{
			top: genderPatient == 'F' ?'67%' :'68%'  , // Joelho Esquerdo
			left: genderPatient == 'F' ? '44%'  :'43%',
			side: 'E',
			currentSide: 'FRONTAL'
		},
		{
			top: genderPatient == 'F' ?'67%' :'68%'  , // Joelho direito
			left:  genderPatient == 'F' ? '52.5%' : '52%',
			side: 'D',
			currentSide: 'FRONTAL'
		},
		{
			top: genderPatient == 'F' ? '81%' : '79%'  , // Perna Esquerda
			left: genderPatient == 'F' ? '44%' :'43%',
			side: 'E',
			currentSide: 'FRONTAL'
		},
		{
			top: genderPatient == 'F' ? '81%' : '79%'  , // Perna Direito
			left:  genderPatient == 'F' ? '52.5%' : '52%',
			side: 'D',
			currentSide: 'FRONTAL'
		},
		{
			top: genderPatient == 'F' ? '94%' : '92%', // Pé Esquerdo
			left:genderPatient == 'F' ? '44%'  : '44.5%',
			side: 'E',
			currentSide: 'FRONTAL'
		},
		{
			top: genderPatient == 'F' ? '94%' : '92%', // Pé Direito
			left: genderPatient == 'F' ? '52.5%' : '52%',
			side: 'D',
			currentSide: 'FRONTAL'
		},
		{
			top: genderPatient == 'F' ? '30%'   : '33%' , // Abdome
			left: genderPatient == 'F' ? '48%': '48%',
			side: 'S',
			currentSide: 'FRONTAL'
		},
		{
		  
			top:  genderPatient == 'F' ? '43%' : '45%', // Torax
			left:  genderPatient == 'F' ? '48%' : '48%',
			side: 'S',
			currentSide: 'FRONTAL'
		},
	];
	const DotsArrayDors = [
		{
			top: genderPatient  == 'F' ? '11.5%'  :'12.5%', //Pescoco Direito
			left:genderPatient  == 'F' ?'44.8%'  :'50.5%',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ? '11.5%'  :'12.5%', //Pescoco Esquerdo
			left: genderPatient  == 'F' ? '51%'  :'45%',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ? '16.5%' : '19%',  // ombro direito
			left: genderPatient  == 'F' ? '60%' :'60%',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ? '16.5%'     : '19%', // ombro esquerdo
			left: genderPatient  == 'F' ? '36%' :'36%',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ? '28%' : '32%', // Braço Posterior Direito
			left: genderPatient  == 'F' ?  '60%': '61.5%',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient  == 'F' ? '28%' : '32%' , // Braço Posterior esquerdo
			left:  genderPatient  == 'F' ? '36%' : '33.5%',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ? '34%'    : '36%', // Lombar direito
			left:   genderPatient  == 'F' ? '51%' : '51%' ,
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ? '34%'    : '36%', // Lombar esquerdo
			left:  genderPatient  == 'F' ? '45%' :'45%',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ?  '43%': '45%',  // Pulso direito
			left:  genderPatient  == 'F' ? '68.5%' : '69%',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ?  '43%': '45%', // Pulso esquerdo
			left:genderPatient  == 'F' ?  '27.5%' :  '27.7%',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ?'39%'    :'42%', // Quadril direito
			left: genderPatient  == 'F' ? '57%'  : '57%',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ?'39%'    :'42%', // Quadril esquerdo
			left: genderPatient  == 'F' ? '39%' :'38%',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ? '58%' : '58%', // Coxa Posterior direito
			left:  genderPatient  == 'F' ? '53%' : '53%',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ? '58%' : '58%', // Coxa Posterior esquerdo
			left: genderPatient  == 'F' ? '43%' : '43%',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ? '73%' : '78%' , // Perna Posterior direito
			left:  genderPatient  == 'F' ? '52.5%' : '52%',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ? '73%' : '78%' , // Perna Posterior esquerdo
			left: genderPatient  == 'F' ? '44%' : '43%',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient  == 'F' ? '96%' : '95%', // Tornozelo Direito
			left: genderPatient  == 'F' ? '52.5%' : '51%',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ? '96%' : '95%', // Tornozelo Esquerdo
			left:  genderPatient  == 'F' ? '44%' : '45%',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ? '18.5%' : '17%', // Coluna superior
			left: genderPatient  == 'F' ? '48%'  :  '48%',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient  == 'F' ? '26%' : '27%', // Coluna central
			left: genderPatient  == 'F' ? '48%' : '48%',
			currentSide: 'DORSAL',
		},

	];
	const DotsArrayFrontSideBySide = [
		{
			top:  genderPatient == 'F' ? '13.5%' : '18%'    , //Mandibola esquerdo
			left: genderPatient == 'F' ? '26.5%' : '20.5%',
			MUSCULO: 'mandibula',
			LADO: 'E',
			currentSide: 'FRONTAL',
		},
		{
			top:  genderPatient == 'F' ? '13.5%' : '18%'    , //Mandibola  direito
			left: genderPatient == 'F' ?  '20.5%' : '26.5%',
			MUSCULO: 'mandibula',
			LADO: 'D',
			currentSide: 'FRONTAL',
		},
		{
			top:  genderPatient == 'F' ? '18%' :  '25%' , // ombro esquerdo
			left: genderPatient == 'F' ? '30%':  '14%',
			MUSCULO: 'ombro',
			LADO: 'E',
			currentSide: 'FRONTAL',
		},
		{
			top:  genderPatient == 'F' ? '18%' :  '25%' , // ombro direito
			left:  genderPatient == 'F' ? '16.5%' :  '33%',
			MUSCULO: 'ombro',
			LADO: 'D',
			currentSide: 'FRONTAL',
		},
		{
			top: genderPatient == 'F' ? '28%'  :  '35%'  , // Braço esquerdo
			left:  genderPatient == 'F' ? '33%' :  '12%'  ,
			MUSCULO: 'braco',
			LADO: 'E',
			currentSide: 'FRONTAL',
		},
		{
			top:  genderPatient == 'F' ? '28%'  :  '35%'  , // Braço Direito
			left: genderPatient == 'F' ? '13.5%' :  '35%' ,
			MUSCULO: 'braco',
			LADO: 'D',
			currentSide: 'FRONTAL',
		},
		{
			top:  genderPatient == 'F' ? '36%': '43%' , // Antebraço esquerdo
			left:  genderPatient == 'F' ? '37%': '9%'   ,
			MUSCULO: 'antebraco',
			LADO: 'E',
			currentSide: 'FRONTAL',
		},
		{
			top:  genderPatient == 'F' ? '36%': '43%' , // Antebraço direito
			left:  genderPatient == 'F' ? '10%' : '38%'  ,
			MUSCULO: 'antebraco',
			LADO: 'D',
			currentSide: 'FRONTAL',
		},
		{
			top:  genderPatient == 'F' ? '52%': '56%' , // Coxa esquerdo
			left: genderPatient == 'F' ? '28%' : '20%',
			MUSCULO: 'coxa',
			LADO: 'E',
			currentSide: 'FRONTAL',
		},
		{
			top:  genderPatient == 'F' ? '52%': '56%' , // Coxa direito
			left: genderPatient == 'F' ? '19%' : '27%',
			MUSCULO: 'coxa',
			LADO: 'D',
			currentSide: 'FRONTAL',
		},
		{
			top:  genderPatient == 'F' ? '63%': '66%' , // Joelho esquerdo
			left:  genderPatient == 'F' ? '28%' : '20%',
			MUSCULO: 'joelho',
			LADO: 'E',
			currentSide: 'FRONTAL',
		},
		{
			top:  genderPatient == 'F' ? '63%': '66%' , // Joelho direito
			left: genderPatient == 'F' ? '19%' : '27.5%',
			MUSCULO: 'joelho',
			LADO: 'D',
			currentSide: 'FRONTAL',
		},
		{
			top:  genderPatient == 'F' ? '74.5%'  : '77%'   , // perna Esquerda
			left: genderPatient == 'F' ? '27.7%': '20.5%',
			MUSCULO: 'perna',
			LADO: 'E',
			currentSide: 'FRONTAL',
		},
		{
			top:  genderPatient == 'F' ? '74.5%'  : '77%' , // perna Esquerda
			left:  genderPatient == 'F' ? '19%' : '26.5%'  ,
			MUSCULO: 'perna',
			LADO: 'D',
			currentSide: 'FRONTAL',
		},
		{
			top:  genderPatient == 'F' ? '86%': '86%', // Pé Esquerdo
			left: genderPatient == 'F' ? '26.7%' : '21%',
			MUSCULO: 'pe',
			LADO: 'E',
			currentSide: 'FRONTAL',
		},
		{
			top:  genderPatient == 'F' ? '86%': '86%' , // Pé Direito
			left: genderPatient == 'F' ? '20.2%' : '26%',
			MUSCULO: 'pe',
			LADO: 'D',
			currentSide: 'FRONTAL',
		},
		{
			top:  genderPatient == 'F' ? '29.5%': '35%' , // Torax
			left:  genderPatient == 'F' ? '23.5%' : '23.5%',
			MUSCULO: 'torax',
			LADO: 'S',
			currentSide: 'FRONTAL',
		},
		{
			top:  genderPatient == 'F' ? '42%'  : '46%'   , // abdome
			left: '23.5%',
			MUSCULO: 'abdome',
			LADO: 'S',
			currentSide: 'FRONTAL',
		},

	];
	const DotsArrayDorsSideBySide = [
		
		{
			top: genderPatient == 'F'? '13.5%' :  '18%', //Pescoco direito
			left: '76%',
			MUSCULO: 'pescoco',
			LADO: 'D',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient == 'F' ? '13.5%' :  '18%', //Pescoco esquerdo
			left: '71%',
			MUSCULO: 'pescoco',
			LADO: 'E',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient == 'F' ? '17.5%'   :  '24%'  , // ombro direito
			left: genderPatient == 'F' ? '83%'  : '82%',
			MUSCULO: 'ombro_posterior',
			LADO: 'D',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient == 'F' ? '17.5%' :  '24%'  , // ombro esquerdo
			left:  genderPatient == 'F' ? '65%'  : '64%',
			MUSCULO: 'ombro_posterior',
			LADO: 'E',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient == 'F' ? '28%':  '34.5%' , // Braço Posterior Direito
			left: genderPatient == 'F' ? '84%':  '85%',
			MUSCULO: 'braco_posterior',
			LADO: 'D',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient == 'F' ? '28%':  '34.5%' , // Braço Posterior esquerdo
			left: genderPatient == 'F' ? '63%' :  '62%',
			MUSCULO: 'braco_posterior',
			LADO: 'E',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient == 'F' ? '32.5%'   :  '38%'  , // Lombar direita
			left:  genderPatient == 'F' ? '75.5%' : '76%',
			MUSCULO: 'lombar',
			LADO: 'D',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient == 'F' ? '32.5%'  :  '38%' , // Lombar esquerda
			left: genderPatient == 'F' ? '71%' :  '71%' ,
			MUSCULO: 'lombar',
			LADO: 'E',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient == 'F' ? '40%':  '46%', // Pulso direito
			left: genderPatient == 'F' ? '90%' : '89.5%' ,
			MUSCULO: 'pulso',
			LADO: 'D',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient == 'F' ? '40%' :  '46%' , // Pulso esquerdo
			left: genderPatient == 'F' ? '57%' : '57%',
			MUSCULO: 'pulso',
			LADO: 'E',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient == 'F' ? '38%'  :  '43%' , // Quadril sup direito
			left:  genderPatient == 'F' ? '81%' :  '81%'  ,
			MUSCULO: 'quadril',
			LADO: 'D',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient == 'F' ? '38%'   :  '43%' , // Quadril esquerdo
			left: genderPatient == 'F' ? '65.5%' :  '66%' ,
			MUSCULO: 'quadril',
			LADO: 'E',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient == 'F' ? '55%' : '56%', // Coxa Posterior direito
			left: genderPatient == 'F' ? '77%' : '78%',
			MUSCULO: 'coxa_posterior',
			LADO: 'D',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient == 'F' ?'55%' : '56%', // Coxa Posterior esquerdo
			left:genderPatient == 'F'  ?'69.5%' :'69%',
			MUSCULO: 'coxa_posterior',
			LADO: 'E',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient == 'F' ?'69%':'73%'   , // Perna Posterior direito
			left: genderPatient == 'F' ?'77.5%' :'77%',
			MUSCULO: 'perna_posterior',
			LADO: 'D',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient == 'F' ?'69%': '73%'  , // Perna Posterior esquerda
			left:  genderPatient == 'F'? '69.5%' :'69.5%',
			MUSCULO: 'perna_posterior',
			LADO: 'E',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient == 'F' ? '89%'   : '88%'   , // Tornozelo Direito
			left:  genderPatient == 'F' ? '77%': '76%',
			MUSCULO: 'tornozelo',
			LADO: 'D',
			currentSide: 'DORSAL',
		},
		{
			top: genderPatient == 'F' ? '89%'   : '88%'   , // Tornozelo Esquerdo
			left:  genderPatient == 'F' ? '70%'  : '71%',
			MUSCULO: 'tornozelo',
			LADO: 'E',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient == 'F' ? '19%'  :  '25%' , // Coluna superior
			left: genderPatient == 'F' ? '73.5%'  : '73.5%' ,
			MUSCULO: 'coluna_superior',
			LADO: 'S',
			currentSide: 'DORSAL',
		},
		{
			top:  genderPatient == 'F' ? '26%'  :  '31%' , // Meio coluna
			left: genderPatient == 'F' ? '73.5%'  :  '73.5%' ,
			MUSCULO: 'coluna_central',
			LADO: 'S',
			currentSide: 'DORSAL',
		},
	];

	const mergedArrayFront: mergedArrayProps[] = [];
	const mergedArrayDors: mergedArrayProps[] = [];
	const mergedArrayDorsSide: mergedArrayProps[] = [];
	const mergedArrayFrontSide: mergedArrayProps[] = [];
	let posicaoIndex = 0; 
	let posicaoIndexDors = 0; 
	let posicaoIndexDorsSide = 0; 
	let posicaoIndexFrontSide = 0; 

	EVA_CORPO_FRONTAL?.QUESTIONARIO_PERGUNTA_OPCOES.forEach((opcao) => {
		opcao.LADOS.forEach((lado) => {
			const posicao = DotsArrayFront[posicaoIndex];
			mergedArrayFront.push({
				NOME: opcao?.NOME,
				SIDE: lado,
				LABEL: opcao?.LABEL,
				TOP: posicao?.top,
				LEFT: posicao?.left,
				CURRENTSIDE: posicao?.currentSide,
			});
			posicaoIndex++; 
		});
	});
	EVA_CORPO_DORSAL?.QUESTIONARIO_PERGUNTA_OPCOES.forEach((opcao) => {
		opcao.LADOS.forEach((lado) => {
			const posicao = DotsArrayDors[posicaoIndexDors];
			mergedArrayDors.push({
				NOME: opcao?.NOME,
				SIDE: lado,
				LABEL: opcao?.LABEL,
				TOP: posicao?.top,
				LEFT: posicao?.left,
				CURRENTSIDE: posicao?.currentSide,
			});
			posicaoIndexDors++; 
		});
	});
	EVA_CORPO_FRONTAL?.QUESTIONARIO_PERGUNTA_OPCOES.forEach((opcao) => {
		opcao.LADOS.forEach((lado) => {
			const posicao = DotsArrayFrontSideBySide[posicaoIndexFrontSide];
			mergedArrayFrontSide.push({
				NOME: opcao?.NOME,
				SIDE: lado,
				LABEL: opcao?.LABEL,
				TOP: posicao?.top,
				LEFT: posicao?.left,
				CURRENTSIDE: posicao?.currentSide,
			});
			posicaoIndexFrontSide++; 
		});
	});
	EVA_CORPO_DORSAL?.QUESTIONARIO_PERGUNTA_OPCOES.forEach((opcao) => {
		opcao.LADOS.forEach((lado) => {
			const posicao = DotsArrayDorsSideBySide[posicaoIndexDorsSide];
			mergedArrayDorsSide.push({
				NOME: opcao?.NOME,
				SIDE: lado,
				LABEL: opcao?.LABEL,
				TOP: posicao?.top,
				LEFT: posicao?.left,
				CURRENTSIDE: posicao?.currentSide,
			});
			posicaoIndexDorsSide++; 
		});
	});


	const DataOptions: DataOptionsType = {
		DotsArrayFront: mergedArrayFront,
		DotsArrayDors: mergedArrayDors,
		DotsArrayFrontSideBySide: mergedArrayFrontSide,
		DotsArrayDorsSideBySide: mergedArrayDorsSide,
	};

	return DataOptions[TypeData] 
};
