import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReportPainMap from '..';

const DetailHistoricPainMap: React.FC = ()=> {
	const dispatch = useDispatch();

	const dataHistoryReport = useSelector((state:any)=> state.avaliation.data_history_report_EVA)

	return ( <ReportPainMap isHistory dataReport={dataHistoryReport}/> )

};
export default DetailHistoricPainMap;