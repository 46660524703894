import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as Sentry from "@sentry/react";

import { TextsProps } from "../../../database/txt.interface";
import { DataPreReportEVA, dataReportProps, EvaCorpoProps } from "../../../store/ducks/Models";
import { ActivityIndicator, Container, ContainerChangeViewButton, ContainerClose, ContainerImage, ContainerSchemaDotPain, ContainerTitle, Content, ContentInfo, FinishButton, TextOption,  } from "./styles";

import {ReactComponent as Close} from '../../../assets/kinologyIcons/svg/close.svg';
import {ReactComponent as ChangedViewDorsFocus} from '../../../assets/kinologyIcons/svg/ChangedView.svg';
import {ReactComponent as ChangedViewDorsWithFocus} from '../../../assets/kinologyIcons/svg/ChangedViewDorsWithFocus.svg';
import {ReactComponent as ChangedViewFrontWithFocus} from '../../../assets/kinologyIcons/svg/ChangedViewFrontWithFocus.svg';
import {ReactComponent as ChangedViewFrontFocus} from '../../../assets/kinologyIcons/svg/changedViewFrontFocus.svg';
import {ReactComponent as Touch} from '../../../assets/kinologyIcons/svg/Touch.svg';


import Text from "../../Texts/Text";
import { Colors } from "../../../styles/colors";
import BodyPainPointsComponent from "../../BodyPainPoints";
import SumaryDotPain from "../../SumaryDotpain";
import SchemaSelectDotPain from "../../BodyPainPoints/ChildComponents/SchemaSelectDotPain";
import { dataSelectedProps, mergedArrayProps } from "../../BodyPainPoints/Models";
import ModalResetPainMap from "../ModalResetPainMap";
import moment from "moment";
import { PacientesProps, UserProps } from "../../../interfaces/pacientes.interface";
import { AvaliationActions } from "../../../store/ducks/avaliation";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { HandlerColors } from "../../BodyPainPoints/functions";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import ToastAlert from "../../toast";
import { AppActions } from "../../../store/ducks/app";
import { TypesErrorRequest } from "../../../utils/types";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);
interface datadEditingProps {
	MUSCULO: string;
	NOME: string;
	LADO: string;
	VALOR: number;
}
interface ModalSelectDotPainProps {
	visible: boolean;
	prevSide?: 'frontal'| 'dorsal';
	dataEditing?: dataReportProps['eva_corpo']['atual'];
	idResposta?: dataReportProps;
	setVisible: (status: boolean)=> void;
	setChangeSide?: (side: 'frontal' | 'dorsal')=> void;
};
type keySumaryData ={
	[key:string]: dataSelectedProps[]
};

const ModalSelectDotPain: React.FC<ModalSelectDotPainProps> = ({
	visible = true,
	prevSide = 'frontal',
	dataEditing,
	idResposta,
	setVisible,
	setChangeSide
}) => {
	
	const classes = useStyles();
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector((state: any) => state.configs.current_language);
	const patient: PacientesProps = useSelector(	(state: any) => state.app.is_open_paciente);
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);
	const language = useSelector((state: any) => state.configs.language);
	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const idRespostaHistoricReportEva: number = useSelector((state:any)=> state.dashboard.id_resposta_hitory_report_eva );
	const dataQuestionnaireEVA = useSelector((state:any)=> state.relatorios.questionnaireEVA);

	const [ sideBySide, setSidebySide] = useState(false);
	const [ dotSelected, setDotSelected] = useState<mergedArrayProps[]|any[]>();

	const [ dotDorsSelected, setDotDorsSelected] = useState<mergedArrayProps[]|any[]>();
	const [ frontalValuePain, setFrontalValuePain] = useState<dataSelectedProps[]>([]);
	const [ dorsalValuePain, setDorsalValuePain] = useState<dataSelectedProps[]>([]);
	const [ SchemaSelectDotPainVisible, setSchemaSelectDotPainVisible] = useState(false);

	const [ reset, setReset] = useState<'frontal'| 'dorsal' | 'all' | null>(null);
	const [ side, setSide] = useState<'frontal'| 'dorsal' | ''>(prevSide);
	const [ modalResetPainMap, setModalResetPainMap] =  useState<{visible: boolean, type:'clear' | 'finish' | 'leavePage' | 'deletItem' }>({visible: false, type: 'clear'});
	const [ load, setLoad] = useState(false);

	const RenderDataSumary:keySumaryData = {
		frontal: frontalValuePain,
		dorsal: dorsalValuePain
	}; 

	useEffect(()=>{
			if(dataEditing){
				const dataEditingFront: dataSelectedProps[] = [];
				const dataEditingDors: dataSelectedProps[] = [];

				dataQuestionnaireEVA?.find((item:any)=> item?.QUESTIONARIO_LABEL === 'EVA_CORPO_FRONTAL')
				?.QUESTIONARIO_PERGUNTA_OPCOES
				?.forEach((data:any)=>{
					const { LABEL, LADOS } = data;
				
						dataEditing?.forEach(dataEdit => {
							if (dataEdit?.MUSCULO === LABEL && LADOS?.includes(dataEdit?.LADO)) {
								dataEditingFront.push({
									CURRENTSIDE: 'FRONTAL',
									NOME: dataEdit?.NOME,
									SIDE: dataEdit?.LADO,
									LABEL: dataEdit?.MUSCULO,
									VALOR: dataEdit?.VALOR,
									ID: dataEdit?.MUSCULO + '_' + dataEdit?.LADO,
									PAINCOLOR: HandlerColors?.(dataEdit?.VALOR)
								});
							}
						});
				})

				dataQuestionnaireEVA?.find((item:any)=> item?.QUESTIONARIO_LABEL === 'EVA_CORPO_DORSAL')
				?.QUESTIONARIO_PERGUNTA_OPCOES
				?.forEach((data:any)=>{
					const { LABEL, LADOS } = data;
				
						dataEditing?.forEach(dataEdit => {
							if (dataEdit?.MUSCULO === LABEL && LADOS?.includes(dataEdit?.LADO)) {
								dataEditingDors.push({
									CURRENTSIDE: 'DORSAL',
									NOME: dataEdit?.NOME,
									SIDE: dataEdit?.LADO,
									LABEL: dataEdit?.MUSCULO,
									VALOR: dataEdit?.VALOR,
									ID: dataEdit?.MUSCULO + '_' + dataEdit?.LADO,
									PAINCOLOR: HandlerColors?.(dataEdit?.VALOR)
								});
							}
						});
				})

				if(dataEditingFront){
					setFrontalValuePain(dataEditingFront!)
				}
				if(dataEditingDors){
					setDorsalValuePain(dataEditingDors)
				}
			}	

		},[dataEditing]);

	useEffect(()=>{
		if(dataEditing){
			setSidebySide(true)
		}

	},[dataEditing])

	useEffect(()=>{setSide(prevSide!)},[prevSide])

	useEffect(()=>{
		if(frontalValuePain?.length === 0 ){
			setReset('frontal')
		}
	},[frontalValuePain]);

	useEffect(()=>{
		if(dorsalValuePain?.length === 0 ){
			setReset('dorsal')
		}
	},[dorsalValuePain])
	
	useEffect(()=>{
		if(reset === 'all'){ 
			setSidebySide(false)
			setFrontalValuePain([]), 
			setDotSelected([]), 
			setDorsalValuePain([]), 
			setDotDorsSelected([]),
			setSchemaSelectDotPainVisible(false)
		}
	},[reset])

	async function getPreReportEVA (responseId: number){
		const data_request = {
			TOKEN: user_logado.token,
			txIdioma: language.toLowerCase(),
			ID_RESPOSTA: responseId
		};
		try{

			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(data_request),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/sync/sync_relatorio_eva.php`,
				requestOptions
			);
			const response: any = await res.json();
			
			if(response?.pre_relatorio){
				dispatch(AvaliationActions.set_data_pre_report_EVA(response,responseId))
				dispatch(DashBoardActions.set_current_page("pre_report_eva"))
			}
		}catch(error){
			console.log(" ~ handleAnswersEVA ~ error:", error)
			Sentry.captureMessage("CAIU NO CATCH: sync_questionarios.php ", "error");
		}finally{
			setLoad(false)
		}
	}

	async function handleAnswersEVA(){
		setLoad(true)

		const AnswersFront = frontalValuePain?.map((item)=>{return {MUSCULO:item?.LABEL!, LADO:item?.SIDE, VALOR:item?.VALOR!}})
		const AnswersDors = dorsalValuePain?.map((item)=>{return {MUSCULO:item?.LABEL!, LADO:item?.SIDE, VALOR:item?.VALOR!}})


		const data_request = {
			TOKEN: user_logado.token,
			txDataHora: moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString(),
			ID_USUARIO: patient?.ID_USUARIO,
			txRespostas: [
				{
					QUESTIONARIO_LABEL:"EVA_CORPO_FRONTAL", 
					QUESTIONARIO_RESPOSTA: AnswersFront
				},
				{
					QUESTIONARIO_LABEL:"EVA_CORPO_DORSAL", 
					QUESTIONARIO_RESPOSTA: AnswersDors
				}
			],
			txTipo: "EVA",
			EVA_CONCLUIDO: "N"
		}

		try{
			
				const requestOptions: any = {
					method: "POST",
					body: JSON.stringify(data_request),
				};
				const res = await fetch(
					`${process.env.REACT_APP_BASE_URL}/questionarios/questionario_salvar_eva.php`,
					requestOptions
				);
				const response: any = await res.json();
			
			if(response?.status === 'success'){
				dispatch(	AppActions.set_toast_visible({	
						status_toast: true,	
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: current_language?.pontos_dor_salvos_acessar_area_resultados,
					})
				);
				getPreReportEVA(response?.ID_RESPOSTA);
			}
		
			
		}catch(error){
			console.log(" ~ handleAnswersEVA ~ error:", error)
			Sentry.captureMessage("CAIU NO CATCH: sync_questionarios.php ", "error");
		}
	};

	async function GetDataHistory () {
	
			const data_request = {
				TOKEN: user_logado.token,
				ID_USUARIO: patient?.ID_USUARIO
			}
	
			try{
	
				const requestOptions: any = {
					method: "POST",
					body: JSON.stringify(data_request),
				};
				const res = await fetch(
					`${process.env.REACT_APP_BASE_URL}/sync/sync_historico_eva.php`,
					requestOptions
				);
				const response: any = await res.json();

				if(response){
					setLoad(false)
					dispatch(RelatorioActions.setDataHistoryReportPainMap(response.historico))
					dispatch(DashBoardActions.set_current_page('historic_pain_map'))
				}
			}catch(error){
				console.log('/sync/sync_historico_eva.php', error)
			}
	
		}

	async function SaveDataEdit (){
		setLoad(true)

		const AnswersFront = frontalValuePain?.map((item)=>{return {MUSCULO:item?.LABEL!, LADO:item?.SIDE, VALOR:item?.VALOR!}})
		const AnswersDors = dorsalValuePain?.map((item)=>{return {MUSCULO:item?.LABEL!, LADO:item?.SIDE, VALOR:item?.VALOR!}})

		const data_request = {
			TOKEN: user_logado.token,
			txDataHora: moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString(),
			ID_USUARIO: patient?.ID_USUARIO,
			txRespostas: [
				{
					QUESTIONARIO_LABEL:"EVA_CORPO_FRONTAL", 
					QUESTIONARIO_RESPOSTA: AnswersFront
				},
				{
					QUESTIONARIO_LABEL:"EVA_CORPO_DORSAL", 
					QUESTIONARIO_RESPOSTA: AnswersDors
				}
			],
			txTipo: "EVA",
			ID_RESPOSTA: idRespostaHistoricReportEva,
			EVA_CONCLUIDO: "N"
		}


		try{

			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(data_request),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/questionarios/questionario_salvar_eva.php`,
				requestOptions
			);
			const response: any = await res.json();
			
			if(response){
				GetDataHistory()
			}
			
		}catch(error){
			console.log(" ~ handleAnswersEVA ~ error:", error)
			Sentry.captureMessage("CAIU NO CATCH: sync_questionarios.php ", "error");
			setLoad(false)
		}
	}

	

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={visible}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{	timeout: 500	}}
			>
				<Fade in={visible} >
					<Container>
						{ is_open_toast && <ToastAlert />}
						<ContainerClose
							onClick={()=> { setModalResetPainMap({visible: true, type: 'leavePage'})}}	
						>
							<Close />
						</ContainerClose>
							<ContainerTitle>
								{
									dataEditing
									? current_language?.resumo
									: current_language?.mapa_de_dor
								}
							</ContainerTitle>
						<Content>
							<ContainerImage>
							
								<div style={{ display: 'flex', width: 290, marginTop: 18, marginBottom: 20}}>
									{
										dataEditing 
										? <TextOption bold={false} style={{marginBottom:30}}>{current_language?.editar_ou_excluir_dor}</TextOption>
										:
											<>
												<Touch width={30} height={30} />
												<TextOption bold={false}>
													{current_language?.clique_para_indicar + ' '}	
													<TextOption bold>{current_language?.areas_de_dor?.toLowerCase() + ' '}</TextOption> 
													<TextOption bold={false}>{current_language?.do_cliente_na + ' '}</TextOption> 
													<TextOption bold>	
														{	`${current_language?.vista_dorsal.toLowerCase().split(' ')[0]} 
															${side === 'dorsal' 
																? current_language?.vista_frontal.toLowerCase().split(' ')[1] 
																: current_language?.vista_dorsal.toLowerCase().split(' ')[1]
															}`
														}
													</TextOption>
												</TextOption>
											</>
									}
								</div>
								<BodyPainPointsComponent 
									side={side!}
									sideBySide={sideBySide}
									setChangeSide={(changedSide)=>setSide(changedSide)}
									selectedDotPainFront={(dotValue)=> setDotSelected(dotValue)}
									selectedDotPainDors={(dotValue)=> setDotDorsSelected(dotValue)}
									dataPainFront={frontalValuePain}
									dataPainDors={dorsalValuePain}
									dataEditing={dataEditing}
									setSchemaPain={(schemaPain)=>(setSchemaSelectDotPainVisible(schemaPain))}
									reset={reset!}
								/>
								<ContainerChangeViewButton left={0}  onClick={()=> side === 'dorsal' ? {} :  setSide('dorsal' )} sideBySide={sideBySide}>
									{
										side === 'dorsal'
										? <ChangedViewDorsWithFocus 	width={26.54} height={29.17} style={{alignSelf: 'center', display: sideBySide ? 'none' : 'flex', cursor: 'pointer'}}  />
										: <ChangedViewDorsFocus 	width={26.54} height={29.17} style={{alignSelf: 'center', display: sideBySide ? 'none' : 'flex', cursor: 'pointer'}}/>
									}
									<TextOption bold={false} color={	side === 'dorsal' ? Colors.secondary_green_k_medium_1+50 : Colors.secondary_green_k_medium_1}>
										{current_language?.trocar_para} <TextOption bold color={	side === 'dorsal' ? Colors.secondary_green_k_medium_1+50 : Colors.secondary_green_k_medium_1}> {current_language?.vista_dorsal} </TextOption>
									</TextOption>
								</ContainerChangeViewButton>
								<ContainerChangeViewButton right={0} onClick={()=> side === 'frontal' ? {} : 	setSide('frontal' )}  sideBySide={sideBySide}>
									{
										side === 'frontal' 
										? <ChangedViewFrontWithFocus 	width={26.54} height={29.17} style={{alignSelf: 'center', display: sideBySide ? 'none' : 'flex', cursor: 'pointer'}}  />
										: <ChangedViewFrontFocus width={26.54} height={29.17} style={{alignSelf: 'center', display: sideBySide ? 'none' : 'flex', cursor: 'pointer'}} 	/>
									}
									<TextOption bold={false} color={side === 'frontal' ? Colors.secondary_green_k_medium_1+50 : Colors.secondary_green_k_medium_1}>
										{current_language?.trocar_para} <TextOption bold color={side === 'frontal' ? Colors.secondary_green_k_medium_1+50 : Colors.secondary_green_k_medium_1}>{current_language?.vista_frontal} </TextOption>
									</TextOption>
								</ContainerChangeViewButton>
							
							</ContainerImage>
							<ContentInfo>
								<SumaryDotPain
									sumaryData={ sideBySide ? [...RenderDataSumary['frontal'], ...RenderDataSumary['dorsal']] : RenderDataSumary[side!]}
									side={side!}
									sideByside={sideBySide}
									SchemaSelectDotPainVisible={SchemaSelectDotPainVisible}
									setChangeSide={(changedSide)=>setSide(changedSide)}
									setFrontalValuePain={(data)=> {setDotSelected([data]) , setSchemaSelectDotPainVisible(true)}}
									setDorsalValuePain={(data)=> {setDotDorsSelected([data]) , setSchemaSelectDotPainVisible(true)}}
									reset={(resetSide, resetValue )=>{
										resetSide === 'frontal'
										?
											[ setFrontalValuePain(
													(prevState)=>
														prevState?.find((item)=> item?.ID == resetValue )
														?  [...prevState?.filter((item)=> item?.ID != resetValue)]
														:  [...prevState]
												), 
												setReset('frontal')
											]
										:	
											[
												setDorsalValuePain(
												(prevState)=>
													prevState?.find((item)=> item?.ID == resetValue )
														?  [...prevState?.filter((item)=> item?.ID != resetValue)]
														:  [...prevState]
												),
												setReset('dorsal')
											]
									}}
								/>
								
								<ContainerSchemaDotPain>
									<FinishButton 
										visible={ frontalValuePain?.length != 0 && !SchemaSelectDotPainVisible ||  dorsalValuePain?.length != 0 && !SchemaSelectDotPainVisible  ? true : false }
										onClick={()=>{
											!sideBySide 
												? setSidebySide(true)
												: dataEditing
													?	SaveDataEdit()
													: handleAnswersEVA()
										}}
									>
										{
											!sideBySide
												? current_language?.ver_resumo
												: load 
													? <ActivityIndicator width={22} heigth={22}/> 
													: dataEditing
														?	current_language?.salvar_edicao
														: current_language?.primeiro_acesso_concluir
										}
								
									</FinishButton>
									<SchemaSelectDotPain 
										visible={SchemaSelectDotPainVisible}
										setVisible={(visible)=>setSchemaSelectDotPainVisible(visible)}
										selectedDotPainFront={dotSelected || []} 
										selectedDotPainDors={dotDorsSelected || []} 
										side={side!}
										sideBySide={sideBySide}
										setFrontalValuePain={(data)=> {
											setFrontalValuePain((prevState) => {
												const itemExists = prevState?.find((item) => item?.ID == data?.ID);
										
												if (itemExists) {
														dispatch(
																AppActions.set_toast_visible({
																		status_toast: true,
																		type_toast: TypesErrorRequest.SUCCESS,
																		message_toast: current_language?.nivel_de_dor_editado_com_sucesso,
																})
														);
										
														return [...prevState?.filter((item) => item?.ID != data?.ID), data];
												} else {
														return [...prevState, data];
												}
										});
										}}
										setDorsalValuePain={(data)=> {
											setDorsalValuePain(
												(prevState)=>{
													const itemExists = 	prevState?.find((item)=> item?.ID == data?.ID )

													if (itemExists) {
														dispatch(
																AppActions.set_toast_visible({
																		status_toast: true,
																		type_toast: TypesErrorRequest.SUCCESS,
																		message_toast:current_language?.nivel_de_dor_editado_com_sucesso,
																})
														);
										
														return   [...prevState?.filter((item)=> item?.ID != data?.ID), data]
													}else{
														return [...prevState, data]
													}
												}
											)
										}}
									
									/>
								</ContainerSchemaDotPain>
							</ContentInfo>
						</Content>
						<ModalResetPainMap 
							visible={modalResetPainMap.visible}
							action={()=>{ 
								dataEditing ? setVisible(false) :  setReset('all'),
								setVisible(false)
							}}
							modalType={modalResetPainMap.type}
							setVisible={(visible, modalType)=>{setModalResetPainMap({visible: visible, type: modalType})}}
						/>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalSelectDotPain);

