import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';
import { ButtonActionModalProps } from '../../Buttons/ButtonActionModal/Models';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 900px;
	min-height: 650px;
	max-height: 650px;
	border-radius: 30px;
	background-color: ${Colors.neutral_grey_k_5};
	padding: 0px 32px;
	position: relative;

`;
export const ContainerClose = styled.div`
	cursor: pointer;
	position: absolute;
	right: 32px;
	top: 40px;
`;
export const ContainerTitle = styled.div`
	display: flex;
	font-size:1.75rem;
	font-family: 'NotoSans-Regular';
	color: ${Colors.primary_blue_k_main};
	font-weight: 700;
	align-self: center;
	margin-top: 40px;
`;
export const TextOption = styled.p<{bold: boolean, color?:string}>`
	display: inline;
	text-align: center;
	font-size:1rem;
	font-family: 'NotoSans-Regular';
	color: ${({color})=> color ? color : Colors.neutral_grey_k_50};
	font-weight: ${({bold})=> bold ? 600 : 400};
`;
export const Content = styled.div`
	display: flex;
	width: 100%;
	padding: 10px 0px;
	position: relative;
`;

export const ContainerImage = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	width: 45%;
	/* background-color: beige; */
`;
export const ContentInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 55%;
	max-height: 500px;
	align-items: flex-end;
	padding-top:10px;
`;
export const ContainerSchemaDotPain = styled.div`
	width: 90%;
	min-height: 130px;
`;
export const FinishButton = styled.div<{visible:boolean}>`
	width: 100%;
	padding: 16px 32px;
	display: ${({visible})=> visible ? 'flex' : 'none'};
	justify-content: center;
	align-items: center;
	background-color: ${Colors.primary_blue_k_main};
	font-family: 'Kanit-Regular';
	color: ${Colors.neutral_grey_k_5};
	box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
	cursor: pointer;
	border-radius: 12px;
	margin-bottom: 50px;
`;

export const ActivityIndicator = styled.div<Pick<ButtonActionModalProps, 'activityIndicatorColor'>&{width?: number , heigth?:number}>`
  width: ${({width})=> width ? width : 33}px;
  height: ${({heigth})=> heigth ? heigth : 33}px;
  border: 4px solid ${({activityIndicatorColor})=> activityIndicatorColor ? activityIndicatorColor : Colors?.neutral_light_gray_3};
  border-left-color: ${({activityIndicatorColor})=> activityIndicatorColor ? activityIndicatorColor + 40 : Colors?.neutral_light_gray_3 + 40};
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
export const ContainerChangeViewButton = styled.div<{left?: number, right?:number, sideBySide?:boolean}>`
	display: ${({sideBySide})=> sideBySide ? 'none' : 'flex'};
	flex-direction: column;
	cursor: pointer;
	position: absolute;
	left: ${({left})=>left && left};
	right: ${({right})=>right && right};
	width: 100px;
	height: 100px;
	bottom: 110px;
	justify-content: center;
	align-items: center;
`;

