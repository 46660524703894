import styled, { css } from 'styled-components';
import { Colors } from '../../styles/colors';
import { HandlerColors } from '../BodyPainPoints/functions';
import { dataSelectedProps } from '../BodyPainPoints/Models';

export const ContainerSumary = styled.div`
	display: flex;
	flex-direction: column;
	width: 90%;
	max-height: 60%;
	border-radius: 12px;
	border: 1px solid ${Colors.neutral_grey_k_10};
	overflow-y: auto;
	
	::-webkit-scrollbar {
		width: 5px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
		
	}
	::-webkit-scrollbar-track {
		background: 'transparent';
		border-radius: 10px;
		
	}

`;

export const Header = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0px 28px;
`;
export const ContainerText = styled.div`
	width: 100%;
`;

export const PhraseDotPain = styled.div`
	width: 50%;
	margin-top: 15px;
	justify-content: center;
	color: ${Colors.neutral_grey_k_50};
	font-size:1.125rem ;
	font-family: "NotoSans-Regular";
`;
export const SumaryOptions = styled.div<{sumaryData:dataSelectedProps[], index:number, item: any, optionSelcted:string|null}>`
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: ${({item, optionSelcted})=> optionSelcted === item?.ID ? Colors.neutral_grey_k_10 : 'transparent'};
	position: relative;
	/* padding: 18px 0px ; */
	cursor: pointer;
	justify-content: center;
	border-bottom: ${({sumaryData, index})=> ( index != (sumaryData.length -1)) ? `1px solid ${Colors.neutral_grey_k_10}` :  'none' };
`;
export const MusculoName = styled.p`
	color: ${Colors.neutral_grey_k_50};
	display: flex;
	font-weight: 500;
	font-size: 18px;
	font-family: 'NotoSans-Regular';
`;
export const ContentLevel = styled.div<{value: number}>`
	width: 30%;
	display: flex;
	padding: 0px 8px;
	align-items: center;
	font-weight: 700;
	justify-content: space-between;
	color: ${({value})=>HandlerColors(value)} ;
`;
export const ContainerIcon = styled.div`
	width: 30%;
	display: flex;
`;
export const Content = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom:10px;
	position: relative;
	padding: 18px 28px 5px 28px;
`;
export const ContentEditOptions = styled.div`
	display: flex;
	width: 100%;
	margin-bottom: 10px;
	justify-content: space-between;
`;
export const SeparatorVertical = styled.div`
	align-self:center;
	height: 30px;
	width: 2px;
	background-color: ${Colors.neutral_grey_k_10};
`;
export const OptionEdit = styled.div`
	display: flex;
	width: 49.5%;
	padding: 9px 20px;
	align-items: center;
	justify-content: center;
	color: ${Colors.neutral_grey_k_60};
	font-size: 1.125rem;
	font-family: 'NotoSans-Regular';
	&:hover{
		background-color: ${Colors.neutral_grey_k_10};
		color: ${Colors.neutral_grey_k_80};
		.hoverIcon {
			color: ${Colors.neutral_grey_k_80};
		}

	}
`;
