import { AvaliationActions } from "../../store/ducks/avaliation";
import { ConfigsActions } from "../../store/ducks/configs";
import store from "../../store/index";

const tara = 1;
let nomeDevice = "";
var services = '';
var characterist = '';
let previousBatteryLevel = null;
let collectedData = [];

export function ConexaoBluetooth() {
	store.dispatch(ConfigsActions.set_bluetooth_result(false));
	store.dispatch(ConfigsActions.set_dino_battery(null));

	navigator.bluetooth
		.requestDevice({
			filters: [
				{ services: [0xffe0] },
				{ services: ["6e400001-b5a3-f393-e0a9-e50e24dcca9e"] },
				{ services: ["0000180f-0000-1000-8000-00805f9b34fb"] },
				{ services: ["00000000-0415-479b-9dfe-a1d9a6a2dc3c"] },
			],
			optionalServices: [
				'00000100-0415-479b-9dfe-a1d9a6a2dc3c',
				'00000103-0415-479b-9dfe-a1d9a6a2dc3c',
				'00000104-0415-479b-9dfe-a1d9a6a2dc3c',
				'00000105-0415-479b-9dfe-a1d9a6a2dc3c',
				'00000101-0415-479b-9dfe-a1d9a6a2dc3c',
			],
		})
		.then((device) => {
			nomeDevice = device;
			if (
				device.name.substring(0, 8) === "E-lastic" ||
				device.name.substring(0, 6) === "HMSoft" ||
				device.name.substring(0, 11) === "E-lastic_v3"
			) {
				device.addEventListener("gattserverdisconnected", () => {
					store.dispatch(AvaliationActions.set_connect_flag(false));
					ConexaoBluetooth();
				});
				return device.gatt.connect();
			}
			if (device.name.includes('KFORCEGrip')) {
				store.dispatch(AvaliationActions.set_connect_flag_hand_grip(false));
				startSensorMonitoring(device);
				return;
			}
		})
		.then((server) => {
			if (nomeDevice.name.substring(0, 11) === "E-lastic_v3") {
				monitorBatteryLevelWithNotifications(server);
				return server.getPrimaryService("6e400001-b5a3-f393-e0a9-e50e24dcca9e");
			}
			return server.getPrimaryService(0xffe0);
		})
		.then((service) => {
			services = service
			if (nomeDevice.name.substring(0, 11) === "E-lastic_v3") {
				return service.getCharacteristic(
					"6e400003-b5a3-f393-e0a9-e50e24dcca9e"
				);
			}
			return service.getCharacteristic(0xffe1);
		})
		.then((characteristic) => {
			characterist = characteristic
			const dinamo_uuid = characteristic.service.uuid
			store.dispatch(AvaliationActions.set_connect_flag(true));

			function send_data_bluetooth (data) {
				let encoder = new TextEncoder('utf-8');

				if (nomeDevice.name.substring(0, 11) === "E-lastic_v3") {
					try {
						services.getCharacteristic("6e400002-b5a3-f393-e0a9-e50e24dcca9e")
							.then(function (characte) {
								characte.writeValueWithoutResponse(encoder.encode(data))
						})
					} catch (e) {
						console.error(e, 'catch')
					}
				} else {
					characterist.writeValueWithoutResponse(encoder.encode(data))
				}
			}
			
			if (nomeDevice.name.substring(0, 11) === "E-lastic_v3") {
					send_data_bluetooth("EL_MAC[]")
			}

			return characteristic.startNotifications().then(() => {
				characteristic.addEventListener(
					"characteristicvaluechanged",
					(event) => {
						const data_received = new Uint8Array(event.target.value.buffer);
						if(nomeDevice.name.substring(0, 11) === "E-lastic_v3"){
							if(data_received.length == 19){
								var DINAMO_MAC_ADDRESS = ''
								for (var i = 0; i < data_received.length; i++) {
									DINAMO_MAC_ADDRESS += '' + String.fromCharCode(data_received[i]);
								}
								store.dispatch(ConfigsActions.set_identification_dinamo(DINAMO_MAC_ADDRESS.slice(1, -1),dinamo_uuid, nomeDevice.name ));
							}
						}
						var peso = 0
						if (data_received.length == 1) {
							peso = data_received[0] * 0.2 - tara;
						}
						if (data_received.length == 2) {
							peso =
								data_received[0] * 0.2 * 256 + data_received[1] * 0.2 - tara;
						}
						if (data_received.length == 4) {
							peso =
								data_received[2] * 0.2 * 256 + data_received[1] * 0.2 - tara;
						}
						peso = peso.toFixed(1);
						store.dispatch(AvaliationActions.set_valor_ble(peso));
						store.dispatch(ConfigsActions.set_bluetooth_result(true));
					}
				);
			});
		})
		.catch((error) => {
			if (error == "DOMException: Bluetooth adapter not available.") {
				store.dispatch(ConfigsActions.set_bluetooth_result(true));
			}
			return error;
		});
}

// Function to monitor battery level
const monitorBatteryLevelWithNotifications = async (server) => {
	const batteryServiceUUID = '0000180f-0000-1000-8000-00805f9b34fb';
	const batteryLevelUUID = '00002a19-0000-1000-8000-00805f9b34fb';
	try {
		const service = await server.getPrimaryService(batteryServiceUUID);
		const characteristic = await service.getCharacteristic(batteryLevelUUID);
		await characteristic.startNotifications();

		setInterval(async () => {
			try {
				const value = await characteristic.readValue();
				const batteryLevel = new Uint8Array(value.buffer)[0];
				if (!previousBatteryLevel || !!batteryLevel && batteryLevel < previousBatteryLevel) {
					previousBatteryLevel = batteryLevel;
					console.log("Updated Battery Level:", batteryLevel);
					store.dispatch(ConfigsActions.set_dino_battery(batteryLevel));
				}
			} catch (error) {
			}
		}, 8000);
	} catch (error) {
	}
};

//traduzir os valores de bytes
const hexToBytes = (hex) => {
  const bytes = [];
  for (let c = 0; c < hex.length; c += 2) {
    bytes.push(parseInt(hex.substring(c, c + 2), 16));
  }
  return bytes;
};

const hexStringToBytes = (hexStr, bytes) => {
  let completeHex = hexStr;
  while (completeHex.length < 2 * bytes) {
    completeHex = '0' + completeHex;
  }
  return completeHex;
};

const startSensorMonitoring = async (device, dispatch) => {
	const server = await device.gatt.connect();
	const primaryService = await server.getPrimaryService('00000100-0415-479b-9dfe-a1d9a6a2dc3c');

	store.dispatch(AvaliationActions.set_connect_flag_hand_grip(true));
	
	//set packet
	const setCommand0x105 = '00000105-0415-479b-9dfe-a1d9a6a2dc3c';
	const setCommandChar0x105 = await primaryService.getCharacteristic(setCommand0x105);
	if (setCommandChar0x105) {
		const data0x0105 = new Uint8Array([1]);
		await setCommandChar0x105.writeValue(data0x0105);
	} else {
		console.error('Start command characteristic not found');
	}

	//frequency-rate
	const setFrequency0x104 = '00000104-0415-479b-9dfe-a1d9a6a2dc3c';
	const setFrequencyCommand0x104 = await primaryService.getCharacteristic(setFrequency0x104)
	const frequencyHz = 25;
	const frequencyBuffer = new Uint8Array([frequencyHz & 0xFF, (frequencyHz >> 8) & 0xFF]);

	if (setFrequencyCommand0x104) {
		await setFrequencyCommand0x104.writeValue(frequencyBuffer);
	} else {
		console.error('Frequency command characteristic not found');
	}

	//force
	try {
		const dataCharUUID = '00000103-0415-479b-9dfe-a1d9a6a2dc3c';
		const dataCharUUIDCharac = await primaryService.getCharacteristic(dataCharUUID);
		await dataCharUUIDCharac.startNotifications();
		
		dataCharUUIDCharac.addEventListener(
			"characteristicvaluechanged",
			(event) => {
				if (event != null) {
					const value = event.target.value.buffer;
					if (value != null) {
							const rawBytes = new Uint8Array(value);
							const timestamp = (rawBytes[0] | (rawBytes[1] << 8) | (rawBytes[2] << 16) | (rawBytes[3] << 24)) >>> 0;
							const formatTime = Math.trunc(timestamp / 10000);
							const force = rawBytes[4] | (rawBytes[5] << 8) | (rawBytes[6] << 16)
							const grams = (force / 10) - 1000;
							let kg = (parseFloat(grams?.toFixed(1)) / 1000);
							collectedData.push(kg);
					}
			}
			}
		);
	} catch (error) {
		console.error('Error dataCharUUIDCharac:', error);
	}

	//start command
	const startCommandCharUUID = '00000101-0415-479b-9dfe-a1d9a6a2dc3c';
	const startCommandChar = await primaryService.getCharacteristic(startCommandCharUUID);

	if (startCommandChar) {
		const data = new Uint8Array(hexToBytes(hexStringToBytes('0', 8)).reverse());
		await startCommandChar.writeValue(data);
	} else {
		console.error('Start command characteristic not found');
	}
	
	device.addEventListener("gattserverdisconnected", () => {
		store.dispatch(AvaliationActions.set_connect_flag_hand_grip(false));
		ConexaoBluetooth();
	});

	return;
};

const averageCollectedData = () => {
	if (collectedData.length > 0) {
			let avgKg = collectedData.reduce((sum, value) => sum + value, 0) / collectedData.length;
			let digit = [];
			while (avgKg > 0) {
				digit.unshift(avgKg % 10);
				avgKg = Math.floor(avgKg / 10);
			}
			const numbersAsString = digit.length > 0 ? `${digit[1]}${digit[2]}` : null;
			const result = !!numbersAsString ? parseFloat(numbersAsString).toFixed(1) : parseFloat(('0.0')).toFixed(1);
			const formatResult = Number(result) <= Number(0.9) ? parseFloat(('0.0')).toFixed(1) : parseFloat(result).toFixed(1);
			collectedData = [];
			store.dispatch(AvaliationActions.set_valor_ble_hand_grip(formatResult));
			store.dispatch(ConfigsActions.set_bluetooth_result_hand_grip(true));
	}
}

setInterval(() => {
	averageCollectedData();
}, 70);
