import React from 'react';
import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import { Container, ContainerClose, Content } from './styles';

import {ReactComponent as Close} from '../../../assets/kinologyIcons/svg/close.svg';
import {ReactComponent as Warning } from "../../../assets/kinologyIcons/svg/WorningRed.svg";

import Text from '../../../components/Texts/Text';
import { Colors } from '../../../styles/colors';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);
interface ModalProps {
	visible: boolean;
	setVisible:(visible: boolean)=>void
};
const ModalInfoGravity:React.FC<ModalProps> = ({
	visible,
	setVisible
})=> {
	const classes = useStyles();

	const Phrases = [
		'Fadiga e cansaço ao realizar atividades;',
		'Sono não reparador;',
		'Sintomas cognitivos;',
		'Sintomas somáticos.'
	]
	 return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={visible}
			onClose={()=>setVisible(false)}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
				<Fade in={visible} >
					<Container>
						<ContainerClose onClick={()=>setVisible(false)}>
							<Close />
						</ContainerClose>
						<Content>
							<div style={{display: 'flex'}} >
								<Warning width={26} height={31} color={Colors.primary_blue_k_main}/>
								<Text 
									title={'Informação'}
									fontFamily="NotoSans-Regular"
									fontSize={1.5}
									fontWeight={700}
									textColor={Colors.primary_blue_k_main}
									textAlign="left"
									marginLeft={10}
									width='100%'
								/>
							</div>
							<Text 
								title={'Entenda a escala de gravidade'}
								fontFamily="NotoSans-Regular"
								fontSize={1.5}
								fontWeight={600}
								marginTop={18}
								textColor={Colors.primary_blue_k_main}
								width='100%'
							/>
							<Text 
								title={'Para podermos indicar se há risco de fibromialgia ou não, precisamos que responda 4 perguntas sobre a gravidade das dores das seguintes categorias:'}
								fontFamily="NotoSans-Regular"
								fontSize={1.25}
								fontWeight={500}
								marginTop={18}
								textColor={Colors.neutral_grey_k_70}
								width='100%'
							/>
							{
								Phrases?.map((item, index)=>(
									<Text 
										key={index}
										title={`· ${item}`}
										fontFamily="NotoSans-Regular"
										fontSize={1.25}
										fontWeight={500}
										marginTop={index === 0 ? 18 : 0}
										textColor={Colors.neutral_grey_k_70}
										width='100%'
									/>
								))
							}
							<Text 
								title={'Essas perguntas vão de 0 a 3 pontos.'}
								fontFamily="NotoSans-Regular"
								fontSize={1.25}
								fontWeight={500}
								marginTop={18}
								textColor={Colors.neutral_grey_k_70}
								width='100%'
							/>
						</Content>
					</Container>
				</Fade>
			</Modal>
	 )
};
export default ModalInfoGravity