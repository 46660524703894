import React, {  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { ContainerDash, Icon } from "../../../styles/global";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import { TextsProps } from "../../../database/txt.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { S2Desk, P3Desk, S1Desk } from "../../../styles/styles_text";
import ClickToOpen from "../../../assets/kinologyIcons/svg/click_to_open.svg";
import { Colors } from "../../../styles/colors";

import CorpoFemininoDorsal from "../../../assets/kinologyIcons/PainMapImages/corpoFemininoDorsal.svg";
import CorpoFemininoFrontal from "../../../assets/kinologyIcons/PainMapImages/corpoFemininoFrontal.svg";
import CorpoMasculinoDorsal from "../../../assets/kinologyIcons/PainMapImages/corpoMasculinoDorsal.svg";
import CorpoMasculinoFrontal from "../../../assets/kinologyIcons/PainMapImages/corpoMasculinoFrontal.svg";
import {ReactComponent as Arrow} from "../../../assets/kinologyIcons/svg/arrow_downward_alt.svg";

import Text from '../../../components/Texts/Text';

import {     ButtonGo, ContainerImages, ContainerInfo, ContainerOptions, ContainerView, Content, P, ProfilePicture, Return, TextInfo, TextInfoComum, View, Vista, VistaText,  } from "./styles";
import ModalSelectDotPain from "../../../components/Modais/ModalSelectDotPain";
import { ActivityIndicator } from "../../../components/Buttons/ButtonActionModal/style";


const MapaDor: React.FC = () => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const dataQuestionnaireEVA = useSelector((state:any)=> state.relatorios.questionnaireEVA);

	const [side, setSide] = useState<'frontal'| 'dorsal'>('frontal');
	const [modalVisible, setModalVisible] = useState(false);

	return (
		<ContainerDash>
			<Content>
				
				<Return
					onClick={() =>
						dispatch(DashBoardActions.set_current_page("perfil_paciente"))
					}
				>
					<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
					<P3Desk style={{ color: Colors?.primary_blue_k_main }}>{current_language?.area_paciente}</P3Desk>
				</Return>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						marginTop: "1rem",
						width: "60%",
					}}
				>
					<S2Desk style={{ fontWeight: 600, fontSize: '1.625rem' }}>{current_language?.mapa_de_dor_e_indicativos_de_fibromialgia}</S2Desk>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						marginTop: "1rem",
						width: "60%",
					}}
				>
					<ProfilePicture
						src={patient?.USUARIO_FOTO ? patient?.USUARIO_FOTO : Perfil}
					/>
					<S1Desk style={{ marginLeft: "1rem", color: '#323D6E' }}>{patient.USUARIO_NOME}</S1Desk>
				</div>
				<div style={{display: 'flex', width: "60%", flexDirection: 'row', marginTop:12, }}>
					<P onClick={()=> {dispatch(DashBoardActions.set_current_page("historic_pain_map"))}}>{current_language?.ver_histórico}</P>  <div style={{marginLeft:5, marginRight:5}}>{"|"}</div> <P onClick={()=> {	dispatch(DashBoardActions.set_current_page("informacoes_paciente"))}}>{current_language?.ver_perfil}</P>
				</div>
				<div style={{display: 'flex', width: '55%', flexDirection: 'row', alignItems: 'center',  marginTop: '1.5rem',}}>
					<Icon src={ClickToOpen} style={{ marginRight: "0.5rem", height: '1.25rem', width: '1.25rem' }} />
					<div style={{display: 'flex', flexDirection: 'column', }}>
						<TextInfo bold>
							{current_language?.selecione_uma_vista}	<TextInfo bold={false}> {current_language?.frontal_ou_dorsal_para_iniciar} </TextInfo> <br/> 
						</TextInfo>
						<TextInfo bold={false}>{current_language?.indicacao_de_pontos_ou_areas_de_dores}</TextInfo>
					</div>
				</div>
				<ContainerImages>
					<ContainerOptions wd={50} style={{flexDirection: 'column',}}>
						{
							dataQuestionnaireEVA?.length > 0
							?
								<ContainerView >
									<View onClick={()=>{ setSide('frontal'), setModalVisible(true)}} >
										<Vista className="Vista">
											<VistaText>{current_language?.vista_frontal}</VistaText>
										</Vista>
										{ patient.USUARIO_SEXO === 'F'
											?
											<Icon src={CorpoFemininoFrontal}  style={{ height: '21rem', width: '9rem', marginTop:15}} />
											: 
											<Icon src={CorpoMasculinoFrontal}  style={{ height: '21rem', width: '9rem', marginTop:-15}}  />
										}
									</View>
									<View onClick={()=> {setSide('dorsal'), setModalVisible(true)}} >
										<Vista  className="Vista">
											<VistaText>{current_language?.vista_dorsal}</VistaText>
										</Vista>
											{ patient.USUARIO_SEXO === 'F'
												?
												<Icon src={CorpoFemininoDorsal}  style={{ height: '21rem', width: '9rem', marginTop:15}} />
												: 
												<Icon src={CorpoMasculinoDorsal} style={{ height: '21rem', width: '9rem', marginTop:-15}} />
											}
									</View>
								</ContainerView>
							: <ActivityIndicator style={{marginLeft: '30%', marginTop: '20%', }} activityIndicatorColor={Colors?.primary_blue_k_main} />
						}
					</ContainerOptions>

					<ContainerOptions wd={40}>
							<ContainerInfo>
								<div style={{display: 'flex',alignItems: 'flex-start', width: '100%', justifyContent: 'space-between',}}>
									<div style={{display: 'flex',flexDirection: 'row', alignItems: 'center'}} >
										<TextInfo bold={false}> {current_language?.areas_de_dor} </TextInfo> 
										<Arrow style={{ marginLeft:15 }} color={Colors.neutral_grey_k_50}/>
									</div>
								</div>
								<Text
									title={current_language?.areas_de_dor_selecionadas_aparecerao_aqui}
									fontSize={1}
									fontFamily='NotoSans-Regular'
									textAlign='center'
									width="100"
									marginTop={26}
									textColor={Colors?.neutral_grey_k_30}
								/>
							</ContainerInfo>
					</ContainerOptions>
				</ContainerImages>
			</Content>
			<ModalSelectDotPain visible={modalVisible} setVisible={(visible)=>setModalVisible(visible)} prevSide={side}/>
		</ContainerDash>
	);
					
};

export default MapaDor;

