import React from 'react';
import { ContainerInputPain } from '../styles';
import { Colors } from '../../../styles/colors';
import { ContainerInputProps, dataSelectedProps } from '../Models';
import { HandlerColors } from '../functions';





const InputPain: React.FC<Omit<ContainerInputProps, 'handlerColors'>> = ({
	item,
	sideBySide,
	dotFocus,
	side,
	extraData,
	arrayDataPain,
	isFibromialgyDot,
	dataEditing,
	setInputData,
	setSchemaPain,
	onClick
})=> {

	return (
		<ContainerInputPain
			onClick={()=> {
				onClick?.()
				setInputData?.([{ID: item?.LABEL + '_' + item?.SIDE, LEFT: item.LEFT, NOME: item.NOME, SIDE: item.SIDE, TOP: item.TOP, LABEL: item.LABEL, CURRENTSIDE: item?.CURRENTSIDE }]),
				setSchemaPain?.(true)
			}	}
			item={item}
			side={side}
			sideBySide={sideBySide}
			handlerColors={(index:number)=> HandlerColors(index)}
			dotFocus={dotFocus}
			arrayDataPain={arrayDataPain}
			extraData={extraData}
			isFibromialgyDot={isFibromialgyDot}
		/>
			
		
	)
};

export default InputPain;