import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Container, ContainerImage, Content } from './styles';

import {ReactComponent as CorpoFemininoDorsal} from "../../assets/kinologyIcons/PainMapImages/corpoFemininoDorsal.svg";
import {ReactComponent as CorpoFemininoFrontal} from "../../assets/kinologyIcons/PainMapImages/corpoFemininoFrontal.svg";
import {ReactComponent as CorpoMasculinoDorsal} from "../../assets/kinologyIcons/PainMapImages/corpoMasculinoDorsal.svg";
import {ReactComponent as CorpoMasculinoFrontal} from "../../assets/kinologyIcons/PainMapImages/corpoMasculinoFrontal.svg";
import { BodyPainPointProps, dataSelectedProps, mergedArrayProps } from './Models';
import { PacientesProps } from '../../interfaces/pacientes.interface';
import { useSelector } from 'react-redux';

import Text from "../Texts/Text";
import { Colors } from '../../styles/colors';
import { HenderDataPositionDots } from './dotsArrayPosition';
import InputPain from './ChildComponents/Dot';
import { EvaCorpoProps } from '../../store/ducks/Models';

type KeySideImages ={
	[key: string]: ReactElement
};

const BodyPainPointsComponent: React.FC<BodyPainPointProps> = ({
	side,
	sideBySide = true,
	dataPainFront,
	dataPainDors,
	dataEditing,
	reset,
	setChangeSide,
	selectedDotPainFront,
	selectedDotPainDors,
	setSchemaPain,
})=> {

	const patient: PacientesProps = useSelector((state: any) => state.app.is_open_paciente);
	const dataQuestionnaireEVA = useSelector((state:any)=> state.relatorios.questionnaireEVA);

	const [ selectDotPainFront, setSelectDotPainFront] = useState<mergedArrayProps[]|any[]>([])//{LABEL:'',LEFT: '',NOME: '', SIDE: '', TOP: '', ID: ''}

	const [ selectDotPainDors, setSelectDotPainDors] = useState<any[]>([]); 

	useEffect(()=>{
		selectedDotPainFront(selectDotPainFront)
	},[selectDotPainFront])

	useEffect(()=>{
		selectedDotPainDors(selectDotPainDors)
	},[selectDotPainDors])

	useEffect(()=>{
		if(reset === 'frontal'){setSelectDotPainFront([])}
		if(reset === 'dorsal'){setSelectDotPainDors([])}
		// if(reset === 'all'){ setSelectDotPainFront([]), setSelectDotPainDors([])}
	},[reset])

	




	const RenderImage = ()=> {


		const SideImages: KeySideImages = { 
			frontal: patient?.USUARIO_SEXO === 'F' 
				? <CorpoFemininoFrontal width={250} height={450} style={{marginTop:18}}/>
				: <CorpoMasculinoFrontal width={250} height={450} style={{marginTop:18}}/>,
			dorsal: patient?.USUARIO_SEXO === 'F' 
				? <CorpoFemininoDorsal width={250} height={450} style={{marginTop:18}}/>
				: <CorpoMasculinoDorsal width={250} height={450} style={{marginTop:18}}/>
		};

		const SideBySideImages = { 
			frontal: patient?.USUARIO_SEXO === 'F' 
				? <CorpoFemininoFrontal width={183} height={450} style={{marginTop:-18}}/>
				: <CorpoMasculinoFrontal width={183} height={410} style={{}}/>,
			dorsal: patient?.USUARIO_SEXO === 'F' 
			? <CorpoFemininoDorsal width={183} height={450} style={{marginTop:-18}}/>
			: <CorpoMasculinoDorsal width={183} height={410} style={{}}/>
		};

		return (
			<Content sideBySide={sideBySide}>
				{
					sideBySide
					? 
						<>
							<ContainerImage>
								<Text 
									title={'Vista Frontal'}
									fontSize={0.8}
									textColor={Colors.neutral_grey_k_40}
								/>
								{SideBySideImages['frontal']}
								{
									HenderDataPositionDots('DotsArrayFrontSideBySide', patient?.USUARIO_SEXO, dataQuestionnaireEVA )
									?.map((item, index)=>(
										<InputPain 
											onClick={()=> {setChangeSide('frontal')}}
											key={index}
											item={item}
											side={side}
											sideBySide={sideBySide}
											setInputData={(inputData)=> setSelectDotPainFront(inputData)}
											dotFocus={selectDotPainFront}
											arrayDataPain={dataPainFront}
											setSchemaPain={(schema)=>setSchemaPain(schema)}
										/>
									))
								}
							</ContainerImage>
							<ContainerImage >
								<Text 
									title={'Vista Dorsal'}
									fontSize={0.8}
									textColor={Colors.neutral_grey_k_40}
								/>
								{SideBySideImages['dorsal']}
								{
									HenderDataPositionDots('DotsArrayDorsSideBySide', patient?.USUARIO_SEXO, dataQuestionnaireEVA )
									?.map((item, index)=>(
										<InputPain 
											onClick={()=>{ setChangeSide('dorsal')}}
											key={index}
											item={item}
											side={side}
											sideBySide={sideBySide}
											setInputData={(inputData)=> setSelectDotPainDors(inputData)}
											dotFocus={selectDotPainDors}
											arrayDataPain={dataPainDors}
											setSchemaPain={(schema)=>setSchemaPain(schema)}
										/>
									))
								}
							</ContainerImage>
						</>
					: SideImages[side]
				}
				{!sideBySide &&  side === 'frontal' && HenderDataPositionDots('DotsArrayFront', patient?.USUARIO_SEXO, dataQuestionnaireEVA )
					?.map((item, index)=>(
						<InputPain 
							onClick={()=> setChangeSide('frontal')}
							key={index}
							item={item}
							side={side}
							sideBySide={sideBySide}
							setInputData={(inputData)=> setSelectDotPainFront(inputData)}
							dotFocus={selectDotPainFront}
							arrayDataPain={dataPainFront}
							setSchemaPain={(schema)=>setSchemaPain(schema)}
						/>
					))
				}
				{ !sideBySide && side === 'dorsal' && HenderDataPositionDots('DotsArrayDors', patient?.USUARIO_SEXO, dataQuestionnaireEVA )
					?.map((item, index)=>(
						<InputPain 
							onClick={()=> setChangeSide('dorsal')}
							key={index}
							item={item}
							side={side}
							sideBySide={sideBySide}
							setInputData={(inputData)=> setSelectDotPainDors(inputData)}
							dotFocus={selectDotPainDors}
							arrayDataPain={dataPainDors}
							setSchemaPain={(schema)=>setSchemaPain(schema)}
						/>
					))
				}
			</Content>

		)
	}

	return (
		<Container>
			{RenderImage()}
		</Container>
	)
};

export default BodyPainPointsComponent;