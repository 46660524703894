import React, { ReactElement, useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
	Backdrop,
	Fade,
	Modal,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import { TextsProps } from '../../../database/txt.interface';
import { DashBoardActions } from '../../../store/ducks/dashboard';
import { Colors } from '../../../styles/colors';
import Text from '../../../components/Texts/Text';
import Positivo from '../../../assets/kinologyIcons/svg/Positivo.svg';
import Negativo from '../../../assets/kinologyIcons/svg/Negativo.svg';
import { ReactComponent as UserWithoutPhoto } from "../../../assets/kinologyIcons/svg/patient.svg";
import { PacientesProps } from '../../../interfaces/pacientes.interface';
import { ContainerFirstStage, ContainerFooter, ContainerHeader, ContainerOptionsFirstStage, ContainerSecondStageSecond, ModalContainer, ModalContainerComponent } from './styles';
import { RelatorioActions } from '../../../store/ducks/relatorios';


type KeyStage =  {
	[key:number]: ReactElement
};
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalSatisfactionReport: React.FC = ()=> {
	const dispatch = useDispatch();
	const classes = useStyles();

	const current_language: TextsProps = useSelector(
		(state:any) => state.configs.current_language,
	);
	const data_last_emission_report = useSelector((state:any)=> state.relatorios.last_issued_report);
	const open_modal = useSelector((state: any)=> state.dashboard.modal_satisfaction_unified_report);
	const pacientes: PacientesProps[] = useSelector(
		(state: any) => state.dashboard.pacientes
	);

	const [stage, setStage] = useState(0);
	const [textValue, setTextValue] = useState('');
	const [visible, setVisible] = useState(false);

	
	useEffect(()=>{

		const filtros_paginacao = {
			inicio: 0,
			fim: 1,
			filtros: [
				{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
				{ tipo: "USUARIO_NIVEL", valor: [3] },
				{ tipo: "ID_USUARIO", valor: [data_last_emission_report?.ID_USUARIO] },
			],
		};

		dispatch(DashBoardActions.set_filtros_pacintes_request(filtros_paginacao));

	},[open_modal])


	const FirstStage =()=> {
		return (
			<>
			
				<ContainerFirstStage>
					<div className='ContainerAvatar'>
						{	pacientes?.[0]?.USUARIO_FOTO != "" &&
							pacientes?.[0]?.USUARIO_FOTO != null &&
							pacientes?.[0]?.USUARIO_FOTO != "img/photo.png" 
							? (
									<img
										src={pacientes[0]?.USUARIO_FOTO}
										className='UserImage'
									/>
								) 
							: (
									<UserWithoutPhoto color={Colors?.white} />
								)
						}
						</div>
						<p className='UserName'>
							{pacientes[0]?.USUARIO_NOME}
						</p>
				</ContainerFirstStage>

				<ContainerOptionsFirstStage >
					<div
						onClick={()=> {
							dispatch(DashBoardActions.set_salvar_questionario_satisfacao(
								moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString(),
								'RELATORIO_UNIFICADO',
								[1],
								[""],
								data_last_emission_report?.ID_LOG_RELATORIO,
								data_last_emission_report?.LOG_RELATORIO_SECOES,
							))
							dispatch(DashBoardActions.set_modal_satisfaction_unified_report(false))
							dispatch((RelatorioActions.set_data_last_report_issued({})))
						}}
					>
						<img 
							style={{
								cursor: 'pointer',
								height: 100,
								width: 100
							}}
							src={Positivo}	
						/>
						
					</div>
					<div 
							onClick={()=> {	setStage(1)	}}
					>
						<img	 		
							style={{
								cursor: 'pointer',
								height: 100,
								width: 100
							}}
							src={Negativo}
						/>
					</div>
				</ContainerOptionsFirstStage>
			</>
		)
	};
	const SecondStage =()=> {
		return (
			<ContainerSecondStageSecond>
				<textarea className='textArea'
					placeholder={current_language?.digite_aqui}
					value={textValue}
					onChange={(text) => { setTextValue(text.target.value); }}
				/>
			</ContainerSecondStageSecond>
		)
	};

	const SateComponents: KeyStage = {
		0: FirstStage() ,
		1: SecondStage(),
	};

	const HeaderComponent = ()=> {
		return (
			<ContainerHeader>
				<Text
						title={stage === 0 ? current_language?.relatorio_util : 	current_language?.deseja_algum }
						fontSize={1.5}
						fontWeight={700}
						fontFamily='NotoSans-Regular'
						textColor={Colors?.primary_blue_k_main}
				/>
		
				{
					stage === 1 &&
					<Text
							title={current_language?.comentario_sobre}
							fontSize={1.5}
							fontWeight={700}
							fontFamily='NotoSans-Regular'
							textColor={Colors?.primary_blue_k_main}
					/>
				}
			</ContainerHeader>
		)
	};

	const FooterComponent = ()=> {
		return (
			<ContainerFooter>
				<div
					className='ButtonEnvi'
					onClick={()=>	{
						setStage(0)
						dispatch(DashBoardActions.set_salvar_questionario_satisfacao(
							moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString(),
							'RELATORIO_UNIFICADO',
							[0], 
							[textValue],
							data_last_emission_report?.ID_LOG_RELATORIO,
							data_last_emission_report?.LOG_RELATORIO_SECOES,
						)),
						dispatch(DashBoardActions.set_modal_satisfaction_unified_report(false))
						dispatch((RelatorioActions.set_data_last_report_issued({})))
					}}
					style={{display: stage === 1 ? 'flex': 'none'}}
				>
					<Text
						title={current_language?.btn_enviar}
						fontSize={1.5}
						fontFamily='Kanit-Regular'
						fontWeight={600}
						textColor={Colors?.white}
						cursor='pointer'
					/>
				</div>
				<div className='ButtonJump'
					onClick={()=>	{
						stage === 1 
						? [
								dispatch(DashBoardActions.set_salvar_questionario_satisfacao(
									moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString(),
									'RELATORIO_UNIFICADO',
									[0], 
									[textValue],
									data_last_emission_report?.ID_LOG_RELATORIO,
									data_last_emission_report?.LOG_RELATORIO_SECOES,
								)),
								dispatch(DashBoardActions.set_modal_satisfaction_unified_report(false)),
								dispatch((RelatorioActions.set_data_last_report_issued({})))
							]
						:
							[ 
								dispatch(DashBoardActions.set_salvar_questionario_satisfacao(
									moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString(),
									'RELATORIO_UNIFICADO',
									["PULOU"],
									[textValue],
									data_last_emission_report?.ID_LOG_RELATORIO,
									data_last_emission_report?.LOG_RELATORIO_SECOES,
								)),
								dispatch(DashBoardActions.set_modal_satisfaction_unified_report(false)),
								dispatch((RelatorioActions.set_data_last_report_issued({})))
							]
					}}
				>
					<Text
						title={current_language?.jump}
						fontSize={1}
						fontFamily='NotoSans-Regular'
						textDecoration='underline'
						textAlign='center'
						textColor={Colors?.neutral_grey_k_40}
						marginTop={stage === 1 ? 20 : 0}
						cursor='pointer'
					/>
				</div>
			</ContainerFooter>
		)
	};
	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open_modal ?  true :  false}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			onClose={() => {setVisible(false)}}
		>
			<ModalContainer>
				{HeaderComponent()}
				<ModalContainerComponent>
					{SateComponents[stage]}
				</ModalContainerComponent>
				{FooterComponent()}
			</ModalContainer>
		</Modal>
	)
};

export default ModalSatisfactionReport;