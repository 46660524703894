import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { TextsProps } from "../../../database/txt.interface";
import { AnotacaoProps } from "../../../interfaces/anotacoes.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { getPermission } from "../../../utils/funcs";
import { TypesPermission } from "../../../utils/types";
import { AmplitudeLog } from "../../../utils/amplitude";
import { ContainerDash, Icon } from "../../../styles/global";
import { H2Desk, P2Desk, P3Desk, S1Desk } from "../../../styles/styles_text";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import AddNoteIcon from "../../../assets/icons/add_notes.svg";
import arrow_forward from "../../../assets/icons/arrow_forward.svg";
import { AnotationActions } from "../../../store/ducks/anotacoes";
import { Colors } from "../../../styles/colors";

const ListagemAnotacoes: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const avaliadores: PacientesProps[] = useSelector(
		(state: any) => state.dashboard.avaliadores
	);

	function permissionAddAnnotation() {
		const permissionAnotation: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_ANOTACOES
		);
		if (permissionAnotation) {
			dispatch(DashBoardActions.set_current_page("criar_anotacao"));
		} else {
			dispatch(AppActions.set_modal_upgrade_funcionalidade_indisponivel(true));
		}
	}

	const todasAnotacoes: any = useSelector(
		(state: any) => state.dashboard.anotacoes
	);

	useEffect(() => {
		dispatch(DashBoardActions.anotacoes_request());
	}, []);

	const data = todasAnotacoes.filter(
		(filterAnotacoes: AnotacaoProps) => {
			return filterAnotacoes.ID_USUARIO === paciente.ID_USUARIO
		}
	);

	const filterEvaluator = (note: any) => {
		const evaluator = avaliadores.filter((aval) => aval.ID_USUARIO === note.ID_USUARIO_AVALIADOR)
		return evaluator.length > 0 && evaluator[0]?.USUARIO_NOME;
	}

	return (
		<ContainerDash>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("perfil_paciente"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk style={{ color: Colors?.primary_blue_k_main }}>{current_language?.area_paciente}</P3Desk>
			</Return>

			<Header>
				<H2Desk>{current_language?.evolucao_paciente}</H2Desk>

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						marginTop: "1rem",
					}}
				>
					<ProfilePicture
						src={paciente?.USUARIO_FOTO ? paciente?.USUARIO_FOTO : Perfil}
					/>
					<S1Desk style={{ marginLeft: "1rem" }}>
						{paciente.USUARIO_NOME}
					</S1Desk>
				</div>

				<hr style={{ border: "1px solid #DEDFE3", marginTop: "1rem" }} />
			</Header>

			<TempoEstimado
				onClick={() => {
					permissionAddAnnotation();
					AmplitudeLog("adicionar_anotacoes");
				}}
			>
				<Icon src={AddNoteIcon} /> {current_language?.criar_anotacao}
			</TempoEstimado>

			<ContainerOptions>
				<H2Desk style={{ marginBottom: "1rem" }}>
					{current_language?.historico_evolucao_paciente}
				</H2Desk>
				<Scroll>
					{data.map((item: AnotacaoProps, index: number) => (
						<>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									marginRight: "1rem",
									justifyContent: "space-between",
								}}
								onClick={() => {
									dispatch(DashBoardActions.set_current_page("anotacao"));
									dispatch(AnotationActions.set_current_anotation(item));
								}}
							>
								<ContainerListAnotacoes key={index}>
									<P2Desk style={{ fontWeight: "600" }}>
										{" "}
										{item.ANOTACAO_CRIADA}
									</P2Desk>
									<P2Desk>{filterEvaluator(item)}</P2Desk>
									<P3Desk>{item.ANOTACAO_TXT}</P3Desk>
								</ContainerListAnotacoes>

								<Icon
									style={{ width: "1.5rem", height: "1.5rem" }}
									src={arrow_forward}
								/>
							</div>

							<hr
								style={{
									border: "1px solid #DEDFE3",
									marginTop: "1rem",
									marginBottom: "1rem",
								}}
							/>
						</>
					))}
				</Scroll>
			</ContainerOptions>
		</ContainerDash>
	);
};

export default ListagemAnotacoes;

const ContainerOptions = styled.div`
	display: flex;
	width: 60%;
	flex-direction: column;
	height: 75%;
`;

const Return = styled.div`
	display: flex;
	flex-direction: row;
	width: 60%;
	align-items: center;
	cursor: pointer;
`;

const ProfilePicture = styled.img`
	width: 2rem;
	height: 2rem;
	background-color: #ededed;
	border: 0;
	border-radius: 100%;
	box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
	object-fit: cover;
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: "flex-start";
	width: 60%;
	margin-top: 1rem;
`;

const TempoEstimado = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-family: "NotoSans-Regular";
	font-size: 0.9rem;
	font-weight: 500;
	line-height: 28.8px;
	width: 60%;
	color: #00a377;
	align-items: center;
	padding: 0.1rem;
	margin-bottom: 1.5rem;
	border-radius: 0.8rem;
	border: 2px solid var(--Neutras-Grey-K-10, #dedfe3);
	margin-top: 2rem;
`;

const ContainerListAnotacoes = styled.div`
	display: flex;
	flex-direction: column;
`;

const Scroll = styled.div`
	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;
